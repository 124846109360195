import React from 'react';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import colors from '../../../../constants/colors';
import CheckMarkFilled from '../../../../icons/CheckMarkFilled';
import ClearThick from '../../../../icons/ClearThick';

import styles from './ConfirmManualPlaidMicrodepositFlow.module.scss';

export default function ConfirmManualPlaidMicrodepositFlow({
  open,
  onConfirm,
  onCancel,
}) {
  const lists = {
    cons: [
      <p key={'pros1'}>
        Takes up to <b>2 business days</b> for microdeposits to show in bank
        account
      </p>,
      `Requires follow up to enter the microdeposit amounts`,
      `No validation - can result in errors`,
      `Payments take longer to reach your landlord`,
    ],
    pros: [
      'Takes a few minutes to securely log into your bank account',
      'Bank account connects in seconds',
      'Instant validation - less likelihood of errors',
      'Payments take less time to reach your landlord',
    ],
  };

  const consList = lists.cons.map((item, index) => (
    <div key={'cons' + index} className={styles.listItem}>
      <ClearThick width={20} height={20} />
      {typeof item === 'string' ? <p>{item}</p> : item}
    </div>
  ));

  const prosList = lists.pros.map((item, index) => (
    <div key={'pros' + index} className={styles.listItem}>
      <CheckMarkFilled width={20} height={20} color={colors.lightGreenAlt} />
      <p>{item}</p>
    </div>
  ));

  return (
    <Modal open={open} className={styles.modalWrapper}>
      <h2 className={styles.mainHeading}>
        Are you sure you want to add your bank account manually?
      </h2>
      <p className={styles.subHeading}>
        We recommend using the <b>Bank Account Login</b> method for the
        following reasons:
      </p>
      <div className={styles.listWrapper}>
        {/* Cons  */}
        <div className={styles.list}>
          <p className={styles.listHeader}>Manual Bank Account</p>
          {consList}
        </div>
        {/* Pros  */}
        <div className={styles.list}>
          <p className={styles.listHeader}>Bank Account Login</p>
          {prosList}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button secondary onClick={onConfirm}>
          ADD BANK MANUALLY
        </Button>
        <Button primary onClick={onCancel}>
          BACK
        </Button>
      </div>
    </Modal>
  );
}
