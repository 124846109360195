import React from 'react';
import { Field, FormSpy, useField } from 'react-final-form';
import PropTypes from 'prop-types';

import AddressAutoSuggestFields from '../../../../../components/AddressAutoSuggestFields';
import DateField from '../../../../../components/DateField';
import RadioGroup from '../../../../../components/RadioGroup';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../constants/lease_agreement/sub-division-names';
import getValidFromDate from '../../../../../helpers/get-valid-from-date';
import PropertyRolesEnum from '../../../../../helpers/propertyRolesEnum';
import CalendarIcon from '../../../../../icons/Calendar';
import PropertyThickIcon from '../../../../../icons/PropertyThick';
import composeValidators from '../../../../../validators/composeValidators';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import isDate from '../../../../../validators/isDate';
import SectionTitle from '../../../common/SectionTitle';
import PropertyDetailsPublicLA from '../../../standalone-wizard/PropertyDetailsPublicLA';
import LeaseEndContent from '../LeaseEndContent/LeaseEndContent';
import { LEASE_TERM_CHOICES } from '../LeaseTermChoices';
import RoomSpecificsFields from '../RoomSpecificsFields';
import LeaseRenewalsMD from '../state_specific/MD/LeaseRenewalsMD';
import RentStabilizationNoticeNY from '../state_specific/NY/RentStabilizationNoticeNY';
import LeaseNotarizationWA from '../state_specific/WA/LeaseNotarizationWA';
import RentIncreasesWA from '../state_specific/WA/RentIncreasesWA';

import styles from './SectionLeaseSpecificsFields.module.scss';

const noop = () => {};

const SectionLeaseSpecificsFields = ({
  setPristine,
  currentState,
  propertyRole,
  standAloneWizard,
  leaseAgreementSelection,
}) => {
  const {
    input: { value: leaseTerm },
  } = useField('lease_term');

  const {
    input: { value: leaseStartDate },
  } = useField('start_date');

  const fieldRequired = standAloneWizard ? noop : fieldRequiredStandard;

  return (
    <>
      <FormSpy
        subscription={{ pristine: true, values: true }}
        onChange={({ pristine }) => {
          setPristine(pristine);
        }}
      />
      {standAloneWizard ? (
        <PropertyDetailsPublicLA />
      ) : (
        <>
          <SectionTitle title="Property Address" icon={PropertyThickIcon} />
          <AddressAutoSuggestFields
            className={styles.address}
            fieldNames={{
              address: 'property_address',
              city: 'property_city',
              state: 'property_state',
              unit: 'property_unit',
              zip: 'property_zip',
            }}
            areFieldsRequired={true}
          />
        </>
      )}
      {propertyRole === PropertyRolesEnum.ROOM ? <RoomSpecificsFields /> : null}
      <SectionTitle
        title="Lease Term"
        icon={CalendarIcon}
        iconProps={{ className: styles.calendarIcon }}
      />
      <Field
        component={RadioGroup}
        vertical
        id="lease_term"
        name="lease_term"
        label="What is the term for this lease?"
        labelProps={{ className: styles.label }}
        className={styles.radioGroup}
        radioRowClassName={styles.radioItem}
        options={[
          {
            id: LEASE_TERM_CHOICES.fixed,
            value: LEASE_TERM_CHOICES.fixed,
            label: 'Fixed Term',
          },
          {
            id: LEASE_TERM_CHOICES.monthly,
            value: LEASE_TERM_CHOICES.monthly,
            label: 'Month-to-Month',
          },
        ]}
        validate={fieldRequired}
      />
      {leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.montgomery && (
        <LeaseRenewalsMD />
      )}
      {leaseTerm === LEASE_TERM_CHOICES.fixed && currentState === 'WA' && (
        <LeaseNotarizationWA />
      )}
      <div className={styles.datesWrapper}>
        <Field
          className={styles.date}
          label="Start Date"
          component={DateField}
          name="lease_start_date"
          id="lease_start_date"
          validate={composeValidators(fieldRequired, isDate)}
        />

        {leaseTerm === LEASE_TERM_CHOICES.fixed && (
          <Field
            className={styles.date}
            label="End Date"
            component={DateField}
            name="lease_end_date"
            id="lease_end_date"
            validate={composeValidators(fieldRequired, isDate)}
            fromDate={getValidFromDate(leaseStartDate)}
          />
        )}
      </div>
      <LeaseEndContent leaseTerm={leaseTerm} currentState={currentState} />
      {currentState === 'WA' &&
        leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.seattle && (
          <RentIncreasesWA />
        )}
      {currentState === 'NY' ? <RentStabilizationNoticeNY /> : null}
    </>
  );
};

SectionLeaseSpecificsFields.propTypes = {
  setPristine: PropTypes.func,
  currentState: PropTypes.string.isRequired,
  propertyRole: PropTypes.string,
  standAloneWizard: PropTypes.bool,
  leaseAgreementSelection: PropTypes.string,
};

SectionLeaseSpecificsFields.defaultProps = {
  setPristine: () => null,
  standAloneWizard: false,
};

export default SectionLeaseSpecificsFields;
