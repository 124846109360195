import React from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import queryString from 'query-string';

import AcademyCourseDescription from '../../../../components/AcademyCourseDescription';
import Button from '../../../../components/Button';
import DetailsList from '../../../../components/DetailsList';
import { H2, Paragraph } from '../../../../components/Typography';
import VideoAndDescriptionHolder from '../../../../components/VideoAndDescriptionHolder';
import colors from '../../../../constants/colors';
import AcademyLogo from '../../../../icons/AcademyLogo';
import ArrowRight from '../../../../icons/ArrowRight';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities';
import LoadSSOServiceModal, {
  useOpenSSOService,
} from '../../LoadSSOServiceModal';
import betterLandlordsLogo from '../assets/better-landlords-logo.svg';
import forumDesktopImg from '../assets/forum-desktop.svg';
import forumMobileImg from '../assets/forum-mobile.svg';

import FreeEducationSection from './FreeEducationSection/FreeEducationSection';
import Header from './Header';

import styles from './AcademyPage.module.scss';

const forumDetails = [
  'Connect with veteran landlords who have been there and done that.',
  'Learn about new laws and how they’ll affect your rental business.',
  'Stay up to date on the latest education from TurboTenant and its partners.',
];

const AcademyPage = () => {
  const { ASSET_BUCKET_URL } = useConfig();
  const history = useHistory();
  const [isAcademyLoadingForStudentHousing, openAcademyForStudentHousing] =
    useOpenSSOService('academy', '/course/invest-in-student-housing');
  const [isAcademyLoadingForTaxes, openAcademyForTaxes] = useOpenSSOService(
    'academy',
    '/course/10-expensive-tax-mistakes-to-avoid',
  );

  const [isForumLoading, openForum] = useOpenSSOService('forum');

  const [isAcademyLoadingForFairHousing, openAcademyFairHousing] =
    useOpenSSOService('academy', '/course/fair-housing-for-landlords');

  const [isTTOnboarding, openTTOnboarding] = useOpenSSOService(
    'academy',
    '/course/turbotenant-onboarding',
  );

  if (
    queryString.parse(history.location.search).skip === 'true' &&
    !(
      isAcademyLoadingForTaxes ||
      isAcademyLoadingForFairHousing ||
      isAcademyLoadingForStudentHousing
    )
  ) {
    history.replace(`/owners/load-academy${history.location.search}`);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={cx(styles.headerSection, styles.whiteBG)}>
          <div className={styles.centerHelper}>
            <Header />
          </div>
        </div>
        <div className={cx(styles.centerHelper, styles.forumSection)}>
          <div className={styles.forumSectionContent}>
            <img src={betterLandlordsLogo} className={styles.logo} />
            <Paragraph
              muted
              size="large"
              weight="bold"
              uppercase
              className={styles.subtitle}
            >
              Better Landlords Forum
            </Paragraph>
            <H2 className={styles.title}>
              A <b>community</b> built just for <b>real estate investors.</b>
            </H2>
            <DetailsList
              className={styles.forumDetails}
              list={forumDetails}
              textClassName={styles.listItemText}
              itemClassName={styles.listItem}
              iconColor={colors.info}
            />
            <Button
              className={styles.button}
              onClick={() => {
                openForum();
                segmentTracking({
                  event: 'forum clicked',
                  properties: {
                    location: 'Community Page',
                  },
                });
              }}
            >
              Visit Forum
            </Button>
          </div>
          <div className={cx(styles.forumSectionContent, styles.imageWrapper)}>
            <img
              src={forumDesktopImg}
              className={cx(styles.desktopImg, styles.mobileHidden)}
            />
            <img src={forumMobileImg} className={styles.mobileOnly} />
          </div>
        </div>
        <div className={styles.whiteBG}>
          <div className={cx(styles.centerHelper, styles.videoSection)}>
            <AcademyLogo className={styles.academyLogo} />
            <H2 className={styles.title}>TurboTenant Academy</H2>
            <div className={styles.descriptionWrapper}>
              <Paragraph
                className={styles.description}
                size="large"
                weight="semi"
              >
                Improve your landlord knowledge and take your rental business to
                the next level.
              </Paragraph>
              <Button
                className={styles.visitAcademyButton}
                onClick={() => {
                  openAcademyForStudentHousing();
                  segmentTracking({
                    event: 'academy clicked',
                    properties: {
                      location: 'Community Page',
                    },
                  });
                }}
                variant="ghost"
                icon={ArrowRight}
                iconAfter
              >
                Visit Academy
              </Button>
            </div>
            <VideoAndDescriptionHolder
              openAcademy={() => {
                openAcademyForStudentHousing();
                segmentTracking({
                  event: 'academy clicked',
                  properties: {
                    location: 'Community Page',
                  },
                });
              }}
            />
            <div className={styles.academyCourseWrapper}>
              <AcademyCourseDescription
                className={styles.courseDescription}
                openAcademy={() => {
                  openAcademyForTaxes();
                  segmentTracking({
                    event: 'academy clicked',
                    properties: {
                      location: 'Community Page',
                    },
                  });
                }}
                title="10 Expensive Tax Mistakes to Avoid"
                desc="Deltrease Hart-Anderson, property investor and rental tax strategist, teaches you how to avoid common mistakes and make the tax system work for you and your rental business."
                videoUrl="https://www.youtube-nocookie.com/embed/slkzdr1zOcM?rel=0"
                videoHolderStyles={{
                  backgroundImage: `url(${ASSET_BUCKET_URL}uploads/assets/tax-video-overlay.png)`,
                }}
                actionLabel="LEARN MORE "
              />
              <AcademyCourseDescription
                className={styles.courseDescription}
                openAcademy={() => {
                  openTTOnboarding();
                  segmentTracking({
                    event: 'academy clicked',
                    properties: {
                      location: 'Community Page',
                    },
                  });
                }}
                title="TurboTenant Onboarding Course"
                desc={`This free step-by-step guide walks you through all of
                  TurboTenant's robust features, from marketing vacancies and
                  screening to collecting rent and coordinating maintenance.`}
                videoUrl="https://www.youtube-nocookie.com/embed/7s4vPuV9qP0?rel=0"
                videoHolderStyles={{
                  backgroundImage: `url(${ASSET_BUCKET_URL}uploads/assets/onboarding-video-overlay.png)`,
                }}
                actionLabel="LEARN MORE "
              />
              <AcademyCourseDescription
                className={styles.courseDescription}
                openAcademy={() => {
                  openAcademyFairHousing();
                  segmentTracking({
                    event: 'academy clicked',
                    properties: {
                      location: 'Community Page',
                    },
                  });
                }}
                title="Fair Housing for Landlords"
                desc={`Many fair housing violations are due to inadvertent mistakes
                that can bankrupt your rental business. Protect yourself and
                better serve your tenants with this comprehensive course.`}
                videoUrl="https://www.youtube-nocookie.com/embed/gPr9B5PNy64?rel=0"
                videoHolderStyles={{
                  backgroundImage: `url(${ASSET_BUCKET_URL}uploads/assets/fair-housing-video-overlay.png)`,
                }}
                actionLabel="LEARN MORE "
              />
            </div>
          </div>
        </div>
        <div className={styles.centerHelper}>
          <FreeEducationSection />
        </div>
      </div>
      <LoadSSOServiceModal
        open={
          isAcademyLoadingForStudentHousing ||
          isAcademyLoadingForFairHousing ||
          isAcademyLoadingForTaxes ||
          isTTOnboarding ||
          isForumLoading
        }
        ssoService={isForumLoading ? 'forum' : 'academy'}
      />
    </>
  );
};

export default AcademyPage;
