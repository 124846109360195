import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';

const LateFeeInfo = ({ className, fullStateName }) => (
  <InlineAlert className={className} title={fullStateName}>
    <ul>
      <li>
        There is no state law on a mandatory grace period, but 5 days is common.
      </li>
      <li>Late fees must not exceed 10% of the late amount due.</li>
    </ul>
  </InlineAlert>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  fullStateName: PropTypes.string,
};

export default LateFeeInfo;
