export const routes = {
  dashboard: '/',
  logout: '/logout',

  payments: '/payments',
  paymentsOverview: '/payments/overview',
  charges: '/payments/charges',
  deposits: '/payments/deposits',
  accounting: '/payments/accounting',
  reports: '/payments/reports',
  reports_promo: '/payments/reports/promo',
  paymentsSetupLeaseStep: '/payments/setup/lease',
  paymentsSetupChargesStep: '/payments/setup/charges',
  offlineConversion:
    '/payments/setup/collection?offlineConversion=true&bankStep=true',
  bankAccount: '/payments/bank-account/replace-bank-account/:accountId',
  rentPayments: '/settings/rent-payments',
  companySettings: '/settings/account/company-settings',
  maintenanceRequest: '/maintenance/:maintenanceRequestId/:listingId',
  // renters
  tenantDashboard: '/dashboard/todo',
  tenantMessages: '/messages',

  tenantOnboardingStepPaymentMethod: '/onboarding/',
  tenantOnboardingStepPaymentMethodSuccess:
    '/onboarding/payment-method-success',
  tenantOnboardingStepPaymentMethodVerify: '/onboarding/payment-method-verify',
  tenantOnboardingStepRentersInsurance: '/onboarding/renters-insurance',
  tenantOnboardingStepRentersInsuranceUpload:
    '/onboarding/renters-insurance-upload',
  tenantOnboardingStepRentersInsuranceSuccess:
    '/onboarding/renters-insurance-success',
  tenantOnboardingStepDownloadApp: '/onboarding/download-app',
  tenantOnboardingStepSuccess: '/onboarding/done',

  tenantInsuranceSetupIntro: '/insurance/setup/intro',

  paymentsSetup: '/payments/setup',
  renterSetupRentReporting: '/payments/rent-reporting/setup',
  paymentMethod: '/payments/payment-method',
  PaymentRequestEdit:
    '/payments/charges/edit/:paymentRequestId/:isRule/:leaseId',
};
