import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { getStateFullName } from '../../../../../services/utilities';
import AlaskaLateFeeInfo from '../state_specific/AK/LateFeeInfoAK';
import AlabamaLateFeeInfo from '../state_specific/AL/LateFeeInfoAL';
import ArkansasLateFeeInfo from '../state_specific/AR/LateFeeInfo';
import LateFeeInfoAZ from '../state_specific/AZ/LateFeeInfoAZ';
import CaliforniaLateFeeInfo from '../state_specific/CA/LateFeeInfo';
import LateFeeInfoCO from '../state_specific/CO/LateFeeInfoCO';
import CTLateFeeInfo from '../state_specific/CT/LateFeeInfo';
import DisColumbiaLateFeeInfo from '../state_specific/DC/LateFeeInfo';
import DelawareLateFeeInfo from '../state_specific/DE/LateFeeInfo';
import FloridaLateFeeInfo from '../state_specific/FL/LateFeeInfo';
import LateFeeInfoGA from '../state_specific/GA/LateFeeInfoGA';
import HawaiiLateFeeInfo from '../state_specific/HI/LateFeeInfo';
import IALateFeeInfo from '../state_specific/IA/LateFeeInfo';
import IdahoLateFeeInfo from '../state_specific/ID/LateFeeInfo';
import IllinoisLateFeeInfo from '../state_specific/IL/LateFeeInfo';
import IndianaLateFeeInfo from '../state_specific/IN/LateFeeInfoIN';
import KansasLateFeeInfo from '../state_specific/KS/LateFeeInfo';
import KentuckyLateFeeInfo from '../state_specific/KY/LateFeeInfo';
import LateFeeInfoLA from '../state_specific/LA/LateFeeInfoLA';
import MassachusettsLateFeeInfo from '../state_specific/MA/LateFeeInfo';
import MarylandLateFeeInfo from '../state_specific/MD/LateFeeInfo';
import MaineLateFeeInfo from '../state_specific/ME/LateFeeInfo';
import MichiganLateFeeInfo from '../state_specific/MI/LateFeeInfo';
import MinnesotaLateFeeInfo from '../state_specific/MN/LateFeeInfo';
import MissouriLateFeeInfo from '../state_specific/MO/LateFeeInfo';
import MississippiLateFeeInfo from '../state_specific/MS/LateFeeInfo';
import MontanaLateFeeInfo from '../state_specific/MT/LateFeeInfoMT';
import NorthCarolinaLateFeeInfo from '../state_specific/NC/LateFeeInfo';
import NorthDakotaLateFeeInfo from '../state_specific/ND/LateFeeInfo';
import NebraskaLateFeeInfo from '../state_specific/NE/LateFeeInfo';
import NewHampshireLateFeeInfo from '../state_specific/NH/LateFeeInfo';
import NewJerseyLateFeeInfo from '../state_specific/NJ/LateFeeInfo';
import NewMexicoLateFeeInfo from '../state_specific/NM/LateFeeInfo';
import NevadaLateFeeInfo from '../state_specific/NV/LateFeeInfo';
import NewYorkLateFeeInfo from '../state_specific/NY/LateFeeInfo';
import LateFeeInfoOH from '../state_specific/OH/LateFeeInfoOH';
import OklahomaLateFeeInfo from '../state_specific/OK/LateFeeInfo';
import OregonLateFeeInfo from '../state_specific/OR/LateFeeInfo';
import PennsylvaniaLateFeeInfo from '../state_specific/PA/LateFeeInfo';
import RhodeIslandLateFeeInfo from '../state_specific/RI/LateFeeInfo';
import SouthCarolinaLateFeeInfo from '../state_specific/SC/LateFeeInfo';
import SouthDakotaLateFeeInfo from '../state_specific/SD/LateFeeInfo';
import TennesseeLateFeeInfo from '../state_specific/TN/LateFeeInfo';
import TexasLateFeeInfo from '../state_specific/TX/LateFeeInfo';
import UtahLateFeeInfo from '../state_specific/UT/LateFeeInfo';
import VirginiaLateFeeInfo from '../state_specific/VA/LateFeeInfo';
import VermontLateFeeInfo from '../state_specific/VT/LateFeeInfo';
import WashingtonLateFeeInfo from '../state_specific/WA/LateFeeInfo';
import WisconsinLateFeeInfo from '../state_specific/WI/LateFeeInfo';
import WestVirginiaLateFeeInfo from '../state_specific/WV/LateFeeInfo';
import WyomingLateFeeInfo from '../state_specific/WY/LateFeeInfo';

import styles from './StateLateFeePolicies.module.scss';

const StateLateFeePolicies = ({
  className,
  currentState,
  leaseAgreementSelection,
}) => {
  const fullStateName = getStateFullName(currentState, leaseAgreementSelection);

  return (
    <>
      {currentState === 'AK' && (
        <AlaskaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'AL' && (
        <AlabamaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'AR' && (
        <ArkansasLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'AZ' && (
        <LateFeeInfoAZ
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'CA' && (
        <CaliforniaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'CO' && (
        <LateFeeInfoCO
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'CT' && (
        <CTLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'DC' && (
        <DisColumbiaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'DE' && (
        <DelawareLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'FL' && (
        <FloridaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'GA' && (
        <LateFeeInfoGA
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'HI' && (
        <HawaiiLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'ID' && (
        <IdahoLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'IA' && (
        <IALateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'IL' && (
        <IllinoisLateFeeInfo
          className={cx(styles.infoBlock, className)}
          leaseAgreementSelection={leaseAgreementSelection}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'IN' && (
        <IndianaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'KS' && (
        <KansasLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'KY' && (
        <KentuckyLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'LA' && (
        <LateFeeInfoLA
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'MA' && (
        <MassachusettsLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'ME' && (
        <MaineLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'MD' && (
        <MarylandLateFeeInfo
          className={cx(styles.infoBlock, className)}
          leaseAgreementSelection={leaseAgreementSelection}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'MI' && (
        <MichiganLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'MN' && (
        <MinnesotaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'MO' && (
        <MissouriLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'MS' && (
        <MississippiLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'MT' && (
        <MontanaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'NE' && (
        <NebraskaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'NV' && (
        <NevadaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'NH' && (
        <NewHampshireLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'NJ' && (
        <NewJerseyLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'NM' && (
        <NewMexicoLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'NY' && (
        <NewYorkLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'NC' && (
        <NorthCarolinaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'ND' && (
        <NorthDakotaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'OH' && (
        <LateFeeInfoOH
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'OK' && (
        <OklahomaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'OR' && (
        <OregonLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'PA' && (
        <PennsylvaniaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'RI' && (
        <RhodeIslandLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'SC' && (
        <SouthCarolinaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'SD' && (
        <SouthDakotaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'TN' && (
        <TennesseeLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'TX' && (
        <TexasLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'UT' && (
        <UtahLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'VT' && (
        <VermontLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'VA' && (
        <VirginiaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'WA' && (
        <WashingtonLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'WI' && (
        <WisconsinLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'WV' && (
        <WestVirginiaLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
      {currentState === 'WY' && (
        <WyomingLateFeeInfo
          className={cx(styles.infoBlock, className)}
          fullStateName={fullStateName}
        />
      )}
    </>
  );
};

StateLateFeePolicies.propTypes = {
  className: PropTypes.string,
  currentState: PropTypes.string.isRequired,
  leaseAgreementSelection: PropTypes.string,
};

export default StateLateFeePolicies;
