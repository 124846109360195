import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import refreshBankAccountLoginMut from '../../../../../graphql/mutations/payments/refreshBankAccountLogin.graphql';
import bannerGraphlQuery from '../../../../../graphql/queries/globalBanner.graphql';
import { getLinkToken } from '../../../../../services/plaidService';
import Spinner from '../../../../Spinner';
import DismissAction from '../DismissAction';

import styles from '../../GlobalBanner.module.scss';
import secondaryStyles from './BankAccountLoginExpiring.module.scss';

const BankAccountLoginExpiring = ({ bannerId, expiresAt }) => {
  const expiresIn = moment(expiresAt).fromNow(true);
  const [plaidConfig, setPlaidConfig] = useState({});
  const [refreshBankAccountLogin, { loading: refreshing }] = useMutation(
    refreshBankAccountLoginMut,
    {
      refetchQueries: [{ query: bannerGraphlQuery }],
      awaitRefetchQueries: true,
    },
  );
  const { error, ready, open: openPlaidLink } = usePlaidLink(plaidConfig);

  const triggerPlaidLink = useCallback(() => {
    if (ready) openPlaidLink();
  }, [ready]);

  useEffect(() => {
    getLinkToken(null, true, true)
      .then((linkToken) => {
        setPlaidConfig({
          onSuccess: () => {
            refreshBankAccountLogin();
          },
          token: linkToken,
        });
      })
      .catch((error) => {
        if (window.atatus) {
          window.atatus.notify({
            message: 'Failed to get Plaid link token for global banner',
            error,
          });
        }
      });
  }, []);

  if (refreshing) {
    return (
      <div className={styles['gb-style-warning']}>
        <Spinner secondary />
      </div>
    );
  }

  return (
    <div className={styles['gb-style-warning']}>
      <div className={secondaryStyles.content}>
        Plaid connection with your bank account will expire in {expiresIn}.
        Please login in again to extend the connection.
        <Button
          variant="ghost"
          small
          className={secondaryStyles.button}
          disabled={!ready || error}
          onClick={triggerPlaidLink}
        >
          Log in
        </Button>
      </div>
      <DismissAction bannerId={bannerId} />
    </div>
  );
};

BankAccountLoginExpiring.propTypes = {
  bannerId: PropTypes.string.isRequired,
  expiresAt: PropTypes.string.isRequired,
};

export default BankAccountLoginExpiring;
