import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';

import Logo from './Logo';

const styles = {
  logoWrap: {
    marginBottom: '24px',
    textAlign: 'center',
  },
  redfinLogos: {
    width: '100%',
    overflow: 'hidden',
    display: 'inline-block',
    textAlign: 'center',
  },
  redfinLogosContainer: {
    maxWidth: '600px',
    margin: '0px auto 24px',
  },
  redfinLogo: {
    float: 'left',
    width: '50%',
    textAlign: 'left',
  },
  ttLogo: {
    float: 'right',
    width: '50%',
    textAlign: 'right',
  },
};

const LogoSection = ({ isFromRedfin, hideLogo, logo }) => {
  if (isFromRedfin) {
    return (
      <div style={styles.redfinLogosContainer}>
        <div style={styles.redfinLogos}>
          <Logo
            logo={`${config.assetsBaseUrl}/assets/redfin_logo.png`}
            style={styles.redfinLogo}
          />
          <Logo
            logo={`${config.assetsBaseUrl}/assets/powered_by_tt_logo.png`}
            style={styles.ttLogo}
          />
        </div>
      </div>
    );
  }
  if (!hideLogo) {
    return (
      <div style={styles.logoWrap}>
        <Logo logo={logo} />
      </div>
    );
  }
  return null;
};

LogoSection.propTypes = {
  isFromRedfin: PropTypes.bool,
  hideLogo: PropTypes.bool,
  logo: PropTypes.string,
};

export default LogoSection;
