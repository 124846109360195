import React, { memo } from 'react';
import PropTypes from 'prop-types';

const AccountFindColor = (props) => (
  <svg {...props} width="48" height="48" fill="none">
    <path
      fill="#7FE3FF"
      fillRule="evenodd"
      d="M14.4 18c0 1.467 1.66 4.832 2.4 7.2.351 1.122-.6 2.122 0 2.4 1.501.695 4.8 1.2 6 0 1.2-1.2-.52-.703 0-2.4.743-2.429 3.2-6.36 3.2-8.658 0-3.904-1.913-6.942-4-6.942-1.278 0-5.805 1.397-7.6 3.6-1.137 1.395 0 3.286 0 4.8Z"
      clipRule="evenodd"
    />
    <path
      fill="#042238"
      fillRule="evenodd"
      d="M19.833 4c8.745 0 15.833 7.088 15.833 15.833 0 4.073-1.537 7.787-4.063 10.592l12.153 12.152a.833.833 0 0 1-1.178 1.179L30.424 31.604a15.775 15.775 0 0 1-10.59 4.062C11.087 35.666 4 28.578 4 19.833 4 11.088 11.088 4 19.833 4Zm0 1.667c-7.824 0-14.166 6.341-14.166 14.166S12.008 34 19.833 34 34 27.658 34 19.833c0-7.824-6.342-14.166-14.167-14.166Zm-4.7 5.76c1.878-3.456 10.36-3.221 11.342.704.295 1.182.118 2.325-.366 3.553-.04.102-.088.218-.135.33.582.452.834 1.294.756 2.222-.079.932-.456 1.787-1.078 2.19-.074 1.435-.418 2.527-.97 3.313a3.365 3.365 0 0 1-.682.732v2.3a73.812 73.812 0 0 1 3.688 1.631l-.717 1.505a71.923 71.923 0 0 0-4.114-1.8.833.833 0 0 1-.524-.774v-3.334c0-.354.224-.67.559-.786.051-.018.22-.137.426-.431.416-.593.682-1.544.682-2.95 0-.46.373-.832.833-.832-.03 0 .19-.372.236-.904.045-.541-.07-.763-.183-.763a.833.833 0 0 1-.833-.833c0-.3.595-1.653.505-1.427l.095-.252c.294-.83.383-1.573.205-2.286-.623-2.492-7.76-2.492-8.383 0a.833.833 0 0 1-.975.614c-.597-.121-.758-.075-.78-.03-.064.129-.036.506.124 1.04.07.234.16.486.275.773l.118.285.073.177.135.32.005.011c.166.4.216.545.216.775 0 .46-.373.833-.833.833-.148 0-.27.226-.228.77.04.527.258.896.228.896.46 0 .833.374.833.834 0 1.405.266 2.356.683 2.949.206.294.374.413.425.43a.833.833 0 0 1 .56.787v3.334c0 .34-.208.647-.525.774a71.999 71.999 0 0 0-4.118 1.802l-.716-1.505a74.3 74.3 0 0 1 3.691-1.633v-2.3a3.365 3.365 0 0 1-.681-.732c-.552-.786-.897-1.878-.97-3.314-.625-.404-1.001-1.264-1.072-2.195-.072-.942.201-1.79.812-2.236-.074-.174-.146-.349-.184-.444a10.86 10.86 0 0 1-.324-.913c-.271-.906-.325-1.64-.023-2.255.34-.688 1.007-.995 1.909-.955Z"
      clipRule="evenodd"
    />
  </svg>
);

AccountFindColor.defaultProps = {
  width: 56,
  height: 56,
};

AccountFindColor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(AccountFindColor);
