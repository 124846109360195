import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import createTemplateMutation from '../../../../../../graphql/mutations/messages_templates/createMessagingTemplate.graphql';
import deleteTemplateMutation from '../../../../../../graphql/mutations/messages_templates/deleteMessagingTemplate.graphql';
import editTemplateMutation from '../../../../../../graphql/mutations/messages_templates/editMessagingTemplate.graphql';
import translateTemplateMutation from '../../../../../../graphql/mutations/messages_templates/translateMessagingTemplate.graphql';
import templatesQuery from '../../../../../../graphql/queries/ownerMessagesTemplates.graphql';

import toGlobalId from '../../../../../../helpers/to-global-id';
import { segmentTracking } from '../../../../../../services/utilities';
import { useErrorToast, useSuccessToast } from '../../../../../Toast';
import Modal from '../../../../../Modal';

import TemplateList from './TemplateList';
import CreateTemplateForm from './CreateTemplateForm';
import ConfirmDelete from './ConfirmDelete';

import styles from './QuickReplyTemplatesModal.module.scss';

const segmentParams = { location: 'Quick Reply Template' };

const regexForHandlebars = /{{\s*([^}]+)\s*}}/g; // Test the pattern {{something}}

const QuickReplyTemplatesModal = ({
  open,
  shouldLoadTemplates,
  onClose,
  onChoose,
  relation,
  uid,
}) => {
  const [showCreate, setShowCreate] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState();
  const [templateToEdit, setTemplateToEdit] = useState();
  const showErrorToast = useErrorToast();
  const showSuccessToast = useSuccessToast();
  const { loading: loadingQuery, data } = useQuery(templatesQuery, {
    variables: { relation },
    skip: !open && !shouldLoadTemplates,
    fetchPolicy: 'network-only',
  });
  const [createTemplate, { loading: loadingCreateMutation }] = useMutation(
    createTemplateMutation,
  );
  const [editTemplate, { loading: loadingEditMutation }] =
    useMutation(editTemplateMutation);

  const [deleteTemplate, { loading: loadingDeleteMutation }] = useMutation(
    deleteTemplateMutation,
  );

  const [translateTemplate, { loading: loadingTranslateMutation }] =
    useMutation(translateTemplateMutation);

  const handlePickTemplate = async (message) => {
    segmentTracking('use_this_template clicked', segmentParams);

    if (!regexForHandlebars.test(message)) {
      onChoose(message);
      onClose();
      return;
    }

    const globalId = toGlobalId(uid, 'Renter');
    const response = await translateTemplate({
      variables: {
        renterId: globalId,
        renterType: relation,
        templateBody: message,
      },
    });
    const translatedMessage =
      response?.data?.translateMessagingTemplate?.translatedMessage;
    if (translatedMessage) {
      onChoose(translatedMessage);
      onClose();
    } else {
      showErrorToast('Failed to use template. Try again later.');
    }
  };

  const showCreateForm = () => {
    segmentTracking('create_new_template clicked', segmentParams);
    setShowCreate(true);
  };

  const showEditForm = (template) => {
    segmentTracking('pencil_icon clicked', segmentParams);
    setTemplateToEdit(template);
    setShowCreate(true);
  };

  const handleCreate = async ({ template_name, message_body }) => {
    try {
      await createTemplate({
        variables: {
          templateName: template_name,
          templateBody: message_body,
        },
        refetchQueries: [{ query: templatesQuery, variables: { relation } }],
        awaitRefetchQueries: true,
      });
      setShowCreate(false);
      showSuccessToast('Template Saved');
    } catch (_) {
      showErrorToast('Failed to create template. Try again later.');
    }
  };

  const handleEdit = async (data) => {
    const { template_name, message_body, id, is_predefined } = data;
    try {
      await editTemplate({
        variables: {
          templateId: id,
          defaultTemplateId: is_predefined ? id : null,
          templateName: template_name,
          templateBody: message_body,
        },
        refetchQueries: [{ query: templatesQuery, variables: { relation } }],
        awaitRefetchQueries: true,
      });
      setShowCreate(false);
      setTemplateToEdit(null);
      showSuccessToast('Template Saved');
    } catch (_) {
      showErrorToast('Failed to edit template. Try again later.');
    }
  };

  const handleCancelForm = () => {
    setShowCreate(false);
    setTemplateToEdit(null);
  };

  const openDelete = (id) => {
    segmentTracking('delete_template clicked', segmentParams);
    setTemplateToDelete(id);
  };

  const handleDelete = async () => {
    if (templateToDelete) {
      try {
        await deleteTemplate({
          variables: {
            templateId: templateToDelete,
          },
          refetchQueries: [{ query: templatesQuery, variables: { relation } }],
          awaitRefetchQueries: true,
        });
        setShowCreate(false);
        setTemplateToDelete(null);
        showSuccessToast('Template Deleted');
      } catch (_) {
        showErrorToast('Failed to delete template. Try again later.');
      }
    }
  };

  const handleClose = () => {
    setShowCreate(false);
    setTemplateToEdit(null);
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      showCloseIcon={!showCreate && !templateToDelete}
      className={styles.modal}
      mobileFullScreen
    >
      {!showCreate && !templateToDelete ? (
        <TemplateList
          data={data}
          loading={loadingQuery || loadingTranslateMutation}
          onChoose={handlePickTemplate}
          onCreate={showCreateForm}
          onEdit={showEditForm}
        />
      ) : null}

      {showCreate && !templateToDelete ? (
        <CreateTemplateForm
          onSubmit={handleCreate}
          onClose={handleCancelForm}
          onEdit={handleEdit}
          onDelete={openDelete}
          templateToEdit={templateToEdit}
          loading={
            loadingCreateMutation ||
            loadingEditMutation ||
            loadingDeleteMutation
          }
        />
      ) : null}

      {templateToDelete ? (
        <ConfirmDelete
          onClose={() => setTemplateToDelete(null)}
          onDelete={handleDelete}
          loading={loadingDeleteMutation}
        />
      ) : null}
    </Modal>
  );
};

QuickReplyTemplatesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  shouldLoadTemplates: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChoose: PropTypes.func.isRequired,
  relation: PropTypes.string,
  uid: PropTypes.string,
};

export default QuickReplyTemplatesModal;
