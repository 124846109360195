import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from '../../../../components/ProgressBar';
import SlimProgressBar from '../../../../components/ProgressBar/Slim';

import styles from './OnboardingProgress.module.scss';

const steps = ['Rent Payments', 'Renters Insurance', 'Mobile App'];

const OnboardingProgress = ({ step, progress, isCurrentStepCompleted }) => (
  <div className={styles.container}>
    <ProgressBar
      className={styles.progressBar}
      options={steps}
      active={step}
      isCurrentStepCompleted={isCurrentStepCompleted}
    />
    <SlimProgressBar
      rounded
      progress={progress}
      className={styles.slimProgressBar}
    />
  </div>
);

OnboardingProgress.defaultProps = {
  step: 'Payment Method',
  progress: 0,
};

OnboardingProgress.propTypes = {
  step: PropTypes.oneOf(steps),
  progress: PropTypes.number,
  isCurrentStepCompleted: PropTypes.bool,
};

export default OnboardingProgress;
