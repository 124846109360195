import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Card = ({ color, ...rest }) => (
  <svg viewBox="0 0 48 48" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13h34a2 2 0 0 1 2 2v4H5v-4a2 2 0 0 1 2-2Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.833 12c2.3 0 4.167 1.866 4.167 4.167v16.666c0 2.3-1.866 4.167-4.167 4.167H8.167A4.168 4.168 0 0 1 4 32.833V16.167C4 13.867 5.866 12 8.167 12h31.666Zm2.5 8.333H5.666v12.5a2.501 2.501 0 0 0 2.336 2.495l.165.005h31.666c1.38 0 2.5-1.12 2.5-2.5v-12.5ZM14.833 27a.833.833 0 0 1 .15 1.653l-.15.014h-5a.833.833 0 0 1-.15-1.654l.15-.013h5Zm23.334-3.333a.833.833 0 0 1 .15 1.653l-.15.013h-5a.833.833 0 0 1-.15-1.653l.15-.013h5Zm-15 0a.833.833 0 0 1 .15 1.653l-.15.013H9.833a.833.833 0 0 1-.15-1.653l.15-.013h13.334Zm16.666-10H8.167c-1.38 0-2.5 1.12-2.5 2.5l-.001 2.499h36.667v-2.5a2.501 2.501 0 0 0-2.335-2.494l-.165-.005Z"
      fill={color}
    />
  </svg>
);

Card.defaultProps = {
  width: 48,
  height: 48,
  color: colors.text,
};

Card.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Card);
