import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import OnboardingLogo from './components/OnboardingLogo';
import DownloadApp from './steps/DownloadApp';
import PaymentMethod from './steps/PaymentMethod';
import PaymentMethodSuccess from './steps/PaymentMethodSuccess';
import PaymentMethodVerify from './steps/PaymentMethodVerify';
import RentersInsurance from './steps/RentersInsurance';
import RentersInsuranceSuccess from './steps/RentersInsuranceSuccess';
import RentersInsuranceUpload from './steps/RentersInsuranceUpload';
import Success from './steps/Success';

import styles from './TenantOnboarding.module.scss';

const TenantOnboarding = ({ match }) => {
  const { url: baseUrl } = match;
  return (
    <div className={styles.container}>
      <OnboardingLogo />

      <Switch>
        <Route
          path={`${baseUrl}/payment-method-success`}
          component={PaymentMethodSuccess}
        />
        <Route
          path={`${baseUrl}/payment-method-verify`}
          component={PaymentMethodVerify}
        />
        <Route
          path={`${baseUrl}/renters-insurance`}
          component={RentersInsurance}
        />
        <Route
          path={`${baseUrl}/renters-insurance-upload`}
          component={RentersInsuranceUpload}
        />
        <Route
          path={`${baseUrl}/renters-insurance-success`}
          component={RentersInsuranceSuccess}
        />

        <Route path={`${baseUrl}/download-app`} component={DownloadApp} />

        <Route path={`${baseUrl}/done`} component={Success} />

        <Route component={PaymentMethod} />
      </Switch>
    </div>
  );
};

TenantOnboarding.propTypes = {
  match: PropTypes.node,
};

export default TenantOnboarding;
