import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';

const LateFeeInfo = ({ className, fullStateName }) => (
  <InlineAlert className={className} title={fullStateName}>
    <ul>
      <li>
        There is no state law on a mandatory grace period, but 5 days is common.
      </li>
      <li>
        If the rent does not exceed $700/month, the late fee cannot exceed more
        than $12/day per day or $60/month. If the rent is greater than
        $700/month, the late fee cannot exceed more than $20/day or $100/month.
      </li>
    </ul>
  </InlineAlert>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  fullStateName: PropTypes.string,
};

export default LateFeeInfo;
