import React from 'react';

import SuccessAnimation from '../../../../components/Animations/SuccessAnimation';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import { H2, Paragraph } from '../../../../components/Typography';
import { linkTo, routes } from '../../../../helpers/routes';
import { segmentTracking } from '../../../../services/utilities';
import OnboardingProgress from '../../components/OnboardingProgress';

import styles from './RentersInsuranceSuccess.module.scss';

const RentersInsuranceSuccess = () => (
  <>
    <OnboardingProgress
      step="Renters Insurance"
      progress={60}
      isCurrentStepCompleted
    />

    <Card padded className={styles.container}>
      <Card.Content className={styles.content}>
        <SuccessAnimation style={{ width: 96, height: 96 }} />
        <H2 align="center" className="mb-4 mt-24">
          Insurance policy uploaded!
        </H2>
        <Paragraph align="center" size="large" className="mb-24">
          Thanks for providing your proof of renters insurance.
        </Paragraph>
      </Card.Content>
      <Button
        variant="primary"
        className={styles.button}
        to={linkTo(routes.tenantOnboardingStepDownloadApp)}
        onClick={() => {
          segmentTracking('continue clicked', {
            location: 'Tenant Onboarding Insurance Upload Success Page',
          });
        }}
      >
        Continue
      </Button>
    </Card>
  </>
);
export default RentersInsuranceSuccess;
