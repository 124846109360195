import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import {
  getCurrentToken,
  isUserProfileToken,
} from '../../core/auth/authService';
import { useUserProfile } from '../../core/TTgraphql';

import { ActiveExperimentsContext } from './context';

const ActiveExperimentsProvider = ({ children }) => {
  const shouldFetchProfile = isUserProfileToken(getCurrentToken());
  const { user, loadingUser } = useUserProfile({
    skip: !shouldFetchProfile,
  });

  const experiments = useMemo(() => {
    const userExperiments =
      user?.active_experiments?.reduce((acc, experiment) => {
        acc[experiment.name] = experiment.value;
        return acc;
      }, {}) || {};

    const ownerExperiments =
      user?.owner_active_experiments?.reduce((acc, experiment) => {
        acc[experiment.name] = experiment.value;
        return acc;
      }, {}) || {};

    return {
      userExperiments,
      ownerExperiments,
    };
  }, [user]);

  if (loadingUser) {
    return <LoadingScreen loading />;
  }

  return (
    <ActiveExperimentsContext.Provider value={experiments}>
      {children}
    </ActiveExperimentsContext.Provider>
  );
};

ActiveExperimentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ActiveExperimentsProvider;
