import React, { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import useOutsideClick from '@rooks/use-outside-click';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import CalendarIcon from '../../icons/Calendar';
import getFieldError from '../../validators/get-field-error';
import Label from '../Label';
import Modal from '../Modal';
import PlainInputWithIcon from '../PlainInputWithIcon';

import DayMonthPicker from './DayMonthPicker';

import styles from './DueDateField.module.scss';

const DueDateField = ({
  id,
  className,
  value,
  onChange,
  disabled,
  label,
  hint,
  meta,
  input,
  onClick,
  dataQa,
  ...otherProps
}) => {
  const error = getFieldError(meta);
  const [calendar, setCalendar] = useState();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const containerRef = useRef();

  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 15],
        },
      },
    ],
  });

  const { innerWidth } = useWindowSize();

  const placement = get(popper, 'state.placement');

  let picker;

  const outsideClickHandler = useCallback(() => {
    setCalendar(false);
  }, [calendar]);

  useOutsideClick(containerRef, outsideClickHandler);

  if (calendar) {
    const datePicker = (
      <DayMonthPicker
        value={input.value}
        onChange={(value) => {
          input.onChange(value);
          setCalendar(false);
        }}
        onClose={() => {
          setCalendar(false);
        }}
      />
    );
    if (innerWidth >= 768) {
      picker = (
        <div
          style={popper.styles.popper}
          {...popper.attributes.popper}
          ref={setPopperElement}
          className={styles.popper}
        >
          {' '}
          {datePicker}
          <div
            ref={setArrowElement}
            style={popper.styles.arrow}
            className={cx(styles.arrow, {
              [styles[placement]]: true,
            })}
          />
        </div>
      );
    } else {
      picker = (
        <Modal open onClose={() => setCalendar(false)} className={styles.modal}>
          {datePicker}
        </Modal>
      );
    }
  }

  return (
    <div
      ref={containerRef}
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
    >
      <div>
        {label ? (
          <Label
            htmlFor={id}
            hint={hint}
            onClick={(e) => {
              if (e.target !== e.currentTarget) {
                e.stopPropagation();
              }
            }}
          >
            {label}
          </Label>
        ) : null}
        <PlainInputWithIcon
          value={value}
          {...otherProps}
          {...input}
          ref={setReferenceElement}
          iconProps={{
            onClick: () => setCalendar(!calendar),
            className: styles.icon,
          }}
          icon={CalendarIcon}
          invalid={!!error}
          id={id}
          type="text"
          disabled={disabled}
          onFocus={(e) => {
            otherProps.onFocus && otherProps.onFocus(e);
          }}
          readOnly
          onClick={() => {
            setCalendar(!calendar);
            onClick && onClick();
          }}
          data-qa={dataQa}
        />
        {picker}
      </div>
      {!!error && (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      )}
    </div>
  );
};

DueDateField.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataQa: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

DueDateField.defaultProps = {
  disabled: false,
};

export default DueDateField;
