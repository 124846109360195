import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import colors from '../../../constants/colors';
import AccountAccessRoleEnum from '../../../constants/enums/AccountAccessRoleEnum';
import SUBSCRIPTION_STATUS from '../../../constants/stripe_subscriptions/subscription_status';
import { useUserProfile } from '../../../core/TTgraphql';
import ownerSettingsQueryGQL from '../../../graphql/queries/ownerSettingsQuery.graphql';
import { linkTo, routes } from '../../../helpers/routes';
import Bullet from '../../../icons/Bullet';
import AccountingIcon from '../../../icons/streamline/Accounting';
import ApplicantsIcon from '../../../icons/streamline/Applicants';
import DashboardIcon from '../../../icons/streamline/Dashboard';
import ExpensesIcon from '../../../icons/streamline/Expense';
import FormsIcon from '../../../icons/streamline/Forms';
import HelpIcon from '../../../icons/streamline/Help';
import LeadsIcon from '../../../icons/streamline/Leads';
import LeasesIcon from '../../../icons/streamline/Leases';
import LogoutIcon from '../../../icons/streamline/Logout';
import MaintenanceIcon from '../../../icons/streamline/Maintenance';
import MessagesIcon from '../../../icons/streamline/Messages';
import PaymentsIcon from '../../../icons/streamline/Payments';
import ProfileIcon from '../../../icons/streamline/Profile';
import PropertiesIcon from '../../../icons/streamline/Property';
import ReferralIcon from '../../../icons/streamline/Referral';
import RentersIcon from '../../../icons/streamline/Renters';
import ReportingIcon from '../../../icons/streamline/Reporting';
import SettingsIcon from '../../../icons/streamline/Settings';
import TenantsIcon from '../../../icons/streamline/Tenants';
import ToolboxNav from '../../../icons/streamline/Toolbox';
import { useConfig } from '../../../providers/ConfigProvider';
import { useTTAccountId } from '../../../providers/TTAccountProvider';
import { segmentTracking } from '../../../services/utilities';
import GlobalBanner from '../../Banners/GlobalBanner';
import ProfilePicture from '../../ProfilePicture';
import ReiHubLoadingModal, {
  useOpenREIHub,
} from '../../ReiHubSubscription/ReiHubLoadingModal';
import useMessagingNotifications from '../useMessagingNotifications';

import DesktopNavigation from './DesktopNavigation';

const DesktopNavigationWrapper = () => {
  const location = useLocation();
  const accountSubItemsRef = useRef(null);
  const accountItemRef = useRef(null);
  const config = useConfig();
  const [redirectPage, setRedirectPage] = useState('');

  const { setTTAccountId } = useTTAccountId();
  const { user, muaUser, loading: userLoading } = useUserProfile({}, false);
  const { data: ownerSettingsData } = useQuery(ownerSettingsQueryGQL, {
    skip: !config.IS_OWNER,
  });

  const usersAccounts = muaUser?.users_accounts || [];

  // We will only be able to access users_accounts where the account is confirmed or the role is MAIN
  const accessibleAccounts = usersAccounts.filter(
    (account) =>
      account.confirmed_at || account.user_role === AccountAccessRoleEnum.MAIN,
  );

  const selectedOwner =
    usersAccounts.find((account) => account.is_selected)?.owner ||
    usersAccounts[0]?.owner ||
    {};

  const { unreadMessagesCount: newMessagingCount } = useMessagingNotifications(
    user?.id,
  );

  const { premiumPlusSubscriptionInfo, reiHubSubscriptionInfo } =
    ownerSettingsData?.userData?.settingsAndBilling?.data || {};
  const premiumStatus = premiumPlusSubscriptionInfo?.status || null;
  const reiHubStatus = reiHubSubscriptionInfo?.status || null;
  const showSubscriptionAlert =
    premiumStatus === SUBSCRIPTION_STATUS.PAST_DUE ||
    reiHubStatus === SUBSCRIPTION_STATUS.PAST_DUE;

  const isPremiumUser = user?.premium_subscription_subscribed;

  const [isModalOpen, setOpenREIHub] = useOpenREIHub({
    isDemoMode: !user.reihub_subscription_subscribed,
    redirectionPage: redirectPage,
  });

  const loginToReiHub = () => {
    if (user.reihub_subscription_subscribed) {
      return setOpenREIHub();
    }

    return null;
  };

  const OWNER_NAV_ITEMS = {
    '': [
      {
        label: 'Dashboard',
        id: 'dashboard',
        isActiveFilter: 'owners/dashboard',
        to: `${config.PRIVATE_BASE_PATH}dashboard`,
        Icon: DashboardIcon,
        onClick: () => {
          segmentTracking('dashboard clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Messages',
        id: 'messages',
        isActiveFilter: 'owners/messages',
        to: `${config.PRIVATE_BASE_PATH}messages`,
        Icon: MessagesIcon,
        onClick: () => {
          segmentTracking('messages clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Properties',
        id: 'properties',
        isActiveFilter: 'owners/properties',
        to: `${config.PRIVATE_BASE_PATH}properties`,
        Icon: PropertiesIcon,
        onClick: () => {
          segmentTracking('properties clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Maintenance',
        id: 'maintenance',
        isActiveFilter: 'owners/maintenance',
        to: `${config.PRIVATE_BASE_PATH}maintenance/maintenance-requests`,
        Icon: MaintenanceIcon,
        onClick: () => {
          segmentTracking('maintenance clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Leases',
        id: 'leases',
        isActiveFilter: 'owners/leases',
        to: `${config.PRIVATE_BASE_PATH}leases`,
        Icon: LeasesIcon,
        onClick: () => {
          segmentTracking('leases clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Forms',
        id: 'forms',
        isActiveFilter: 'owners/forms',
        to: `${config.PRIVATE_BASE_PATH}forms`,
        Icon: FormsIcon,
        onClick: () => {
          segmentTracking('forms clicked', {
            location: 'Nav Bar',
          });
        },
      },
    ],
    'renters': [
      {
        label: 'Leads',
        id: 'leads',
        isActiveFilter: 'owners/renters/leads',
        to: `${config.PRIVATE_BASE_PATH}renters/leads`,
        Icon: LeadsIcon,
        onClick: () => {
          segmentTracking('leads clicked', {
            location: 'Nav Bar',
          });
        },
      },

      {
        label: 'Applicants',
        id: 'applicants',
        isActiveFilter: 'owners/renters/applicants',
        to: `${config.PRIVATE_BASE_PATH}renters/applicants`,
        Icon: ApplicantsIcon,
        onClick: () => {
          segmentTracking('applicants clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Tenants',
        id: 'tenants',
        isActiveFilter: 'owners/renters/tenants',
        to: `${config.PRIVATE_BASE_PATH}renters/tenants`,
        Icon: TenantsIcon,
        onClick: () => {
          segmentTracking('tenants clicked', {
            location: 'Nav Bar',
          });
        },
      },
    ],
    'financial': [
      {
        label: 'Payments',
        id: 'payments',
        isActiveFilter: 'owners/payments',
        isInactiveFilter:
          'payments/expenses,payments/accounting,payments/reports',
        to: `${config.PRIVATE_BASE_PATH}payments`,
        Icon: PaymentsIcon,
        IconProps: { strokeWidth: 0.5 },
        onClick: () => {
          segmentTracking('payments clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Expenses',
        id: 'expenses',
        isActiveFilter: 'owners/payments/expenses',
        to: `${config.PRIVATE_BASE_PATH}payments/expenses`,
        Icon: ExpensesIcon,
        onClick: () => {
          segmentTracking('expenses clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Accounting',
        id: 'accounting',
        Icon: AccountingIcon,
        isActiveFilter: 'owners/payments/accounting',
        ...(user.reihub_subscription_subscribed
          ? {}
          : {
              to: linkTo(routes.accounting),
            }),
        onClick: () => {
          setRedirectPage('');
          segmentTracking('accounting clicked', {
            location: 'Nav Bar',
          });
          loginToReiHub();
        },
      },
      {
        label: 'Reports',
        id: 'reports',
        Icon: ReportingIcon,
        isActiveFilter: 'owners/payments/reports',
        ...(user.reihub_subscription_subscribed
          ? {}
          : {
              to: linkTo(routes.reports),
            }),
        onClick: () => {
          setRedirectPage('reports');
          segmentTracking('reports clicked', {
            location: 'Nav Bar',
          });
          loginToReiHub();
        },
      },
    ],
    'RESOURCES': [
      {
        label: 'Toolbox',
        id: 'toolbox',
        isActiveFilter: 'toolbox',
        to: `${config.PRIVATE_BASE_PATH}toolbox`,
        Icon: ToolboxNav,
        onClick: () => {
          segmentTracking('toolbox clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Community',
        id: 'academy',
        isActiveFilter: 'academy',
        to: `${config.PRIVATE_BASE_PATH}academy`,
        Icon: RentersIcon,
        onClick: () => {
          segmentTracking('education clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Need Help?',
        id: 'help',
        isActiveFilter: 'owners/help_center',
        to: `${config.PRIVATE_BASE_PATH}help_center`,
        Icon: HelpIcon,
        onClick: () => {
          segmentTracking('need help clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: isPremiumUser ? 'Referrals' : 'Give $25. Get $25.',
        id: 'referrals',
        isActiveFilter: 'owners/referrals',
        to: `${config.PRIVATE_BASE_PATH}referrals`,
        Icon: ReferralIcon,
        IconProps: { strokeWidth: 1 },
        onClick: () => {
          segmentTracking('referrals clicked', {
            location: 'Nav Bar',
          });
        },
      },
    ],
  };

  const OWNER_BOTTOM_NAV_ITEMS = [
    {
      label: 'Account',
      id: 'account',
      isActiveFilter: 'owners/settings',
      subMenuRef: {
        ref: accountSubItemsRef,
        itemRef: accountItemRef,
      },
      Icon: ProfileIcon,
      SecondaryIcon: Bullet,
      SecondaryIconProps: {
        color: colors.white,
      },
      subItems: [
        {
          label: 'Settings',
          id: 'settings',
          to: `${config.PRIVATE_BASE_PATH}settings`,
          Icon: SettingsIcon,
          onClick: () => {
            segmentTracking('settings clicked', {
              location: 'Nav Bar',
            });
          },
        },
        {
          label: 'Logout',
          id: 'logout',
          Icon: LogoutIcon,
          to: '/logout',
          onClick: () => {
            segmentTracking('logout clicked', {
              location: 'Nav Bar',
            });
          },
        },
      ],
    },
  ];

  const MUA_OWNER_BOTTOM_NAV_ITEMS = [
    {
      label: selectedOwner.company
        ? selectedOwner.company
        : `${selectedOwner.first_name} ${selectedOwner.last_name}`,
      id: 'mua-account',
      subMenuRef: {
        ref: accountSubItemsRef,
        itemRef: accountItemRef,
      },
      Icon: ProfilePicture,
      IconProps: {
        squircle: true,
        size: 'small',
        ...(selectedOwner.company
          ? {
              firstName: selectedOwner.company,
              profilePictureURL: selectedOwner.custom_logo,
            }
          : {
              firstName: selectedOwner.first_name,
              lastName: selectedOwner.last_name,
            }),
      },
      SecondaryIcon: Bullet,
      SecondaryIconProps: {
        color: colors.white,
      },
      subItems: [
        ...(accessibleAccounts.length > 1
          ? [
              {
                label: 'Switch Account',
                id: 'switch-account',
                subItems: accessibleAccounts.map((s) => {
                  const { owner } = s;
                  return {
                    label: owner.company
                      ? owner.company
                      : `${owner.first_name} ${owner.last_name}`,
                    id: `account-${owner.id}`,
                    isActive: s.is_selected,
                    onClick: () => {
                      setTTAccountId(owner.id);
                    },
                    Icon: ProfilePicture,
                    IconProps: {
                      squircle: true,
                      size: 'xsmall',
                      ...(owner.company
                        ? {
                            firstName: owner.company,
                            profilePictureURL: owner.custom_logo,
                          }
                        : {
                            firstName: owner.first_name,
                            lastName: owner.last_name,
                          }),
                    },
                  };
                }),
              },
            ]
          : []),
        {
          label: 'Settings',
          id: 'settings',
          to: `${config.PRIVATE_BASE_PATH}settings`,
          Icon: SettingsIcon,
          onClick: () => {
            segmentTracking('settings clicked', {
              location: 'Nav Bar',
            });
          },
        },
        {
          label: 'Logout',
          id: 'logout',
          Icon: LogoutIcon,
          to: '/logout',
          onClick: () => {
            segmentTracking('logout clicked', {
              location: 'Nav Bar',
            });
          },
        },
      ],
    },
  ];

  const RENTER_NAV_ITEMS = {
    '': [
      {
        label: 'Dashboard',
        id: 'dashboard',
        isActiveFilter: 'renters/dashboard',
        to: `${config.PRIVATE_BASE_PATH}dashboard`,
        Icon: DashboardIcon,
      },
      {
        label: 'Messages',
        id: 'messages',
        isActiveFilter: 'renters/messages',
        to: `${config.PRIVATE_BASE_PATH}messages`,
        Icon: MessagesIcon,
        onClick: () => {
          segmentTracking('messages clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Toolbox',
        id: 'toolbox',
        href: 'https://www.turbotenant.com/renter-toolbox/',
        Icon: ToolboxNav,
        onClick: () => {
          segmentTracking('toolbox clicked', {
            location: 'Tenants Nav Bar',
          });
        },
      },
    ],
  };

  const RENTER_BOTTOM_NAV_ITEMS = [
    {
      label: 'Settings',
      id: 'settings',
      isActiveFilter: 'renters/settings',
      to: `${config.PRIVATE_BASE_PATH}settings`,
      Icon: SettingsIcon,
    },
    {
      label: 'Need Help?',
      id: 'help',
      isActiveFilter: 'renters/help_center',
      to: `${config.PRIVATE_BASE_PATH}help_center`,
      Icon: HelpIcon,
    },
    {
      label: 'Logout',
      id: 'logout',
      Icon: LogoutIcon,
      to: '/logout',
    },
  ];

  let navItems;
  let navBottomItems;

  if (config.IS_OWNER) {
    const usersAccounts = muaUser?.users_accounts || [];
    const isAccountAdmin =
      usersAccounts.length === 1 &&
      usersAccounts[0].user_role !== AccountAccessRoleEnum.MAIN;
    navItems = OWNER_NAV_ITEMS;
    navBottomItems =
      (usersAccounts.length > 1 ||
        isAccountAdmin ||
        user?.has_additional_users) &&
      selectedOwner.first_name
        ? MUA_OWNER_BOTTOM_NAV_ITEMS
        : OWNER_BOTTOM_NAV_ITEMS;
  } else {
    navItems = RENTER_NAV_ITEMS;
    navBottomItems = RENTER_BOTTOM_NAV_ITEMS;
  }

  return (
    <>
      <DesktopNavigation
        user={user}
        userDataLoaded={userLoading}
        location={location}
        isOwner={config.IS_OWNER}
        navItems={navItems}
        navBottomItems={navBottomItems}
        unreadMessagesCount={newMessagingCount}
        isUserSubscribedToREIHub={user.reihub_subscription_subscribed}
        showSubscriptionAlert={showSubscriptionAlert}
        isPremiumUser={isPremiumUser}
      />
      <ReiHubLoadingModal open={isModalOpen} />
      {!config.IS_ADMIN && <GlobalBanner />}
    </>
  );
};

export default DesktopNavigationWrapper;
