import React, { useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import validateEmailSendgrid from '../../helpers/validateEmailSendgrid';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import validEmail from '../../validators/validEmail';
import validName from '../../validators/validName';
import validPasswordStrength from '../../validators/validPasswordStrength';
import Button from '../Button';
import { CalloutWarning } from '../Callout/Callout.stories';
import Divider from '../Divider';
import FinalFormError from '../FinalFormError';
import FlatButton from '../FlatButton';
import Input from '../Input';
import trimEmail from '../Input/trimEmail';
import PasswordInput from '../PasswordInput';

import styles from './SignupFormExperiment.module.scss';

const SignupFormExperiment = ({ onSubmit, className }) => {
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [emailSuggestion, setEmailSuggestion] = useState('');
  const emailChangeApplied = useRef(false);
  const emailRef = useRef();
  const emailsChecked = useRef({});

  const handleEmailValidation = async (email, submitValues) => {
    let result;
    if (emailsChecked.current[email]) {
      result = emailsChecked.current[email];
    } else {
      result = await validateEmailSendgrid(email);
      emailsChecked.current[email] = result;
    }
    if (result.suggestion) {
      setEmailSuggestion(result.suggestion);
    }

    setShowSuggestion(!!result.suggestion);

    if (submitValues && !result.suggestion) {
      return await onSubmit(submitValues);
    }
  };

  const applySuggestion = (form, currentEmail) => {
    const userPart = currentEmail.split('@')[0];

    const newEmail = `${userPart}@${emailSuggestion}`;
    form.change('email', newEmail);
    setShowSuggestion(false);
    emailChangeApplied.current = newEmail;
  };
  return (
    <Form
      onSubmit={async (values) => {
        if (showSuggestion || emailChangeApplied?.current) {
          return await onSubmit(values);
        }
        return await handleEmailValidation(values.email, values);
      }}
      subscription={{
        submitting: true,
        values: true,
        active: true,
      }}
    >
      {({ handleSubmit, submitting, values, form }) => (
        <form
          onSubmit={handleSubmit}
          className={cx(styles.signUpContainer, className)}
        >
          <FinalFormError />
          <div className={styles.row}>
            <Field
              label="First Name"
              component={Input}
              name="first_name"
              id="firstname"
              className={styles.name}
              validate={composeValidators(fieldRequired, validName)}
            />
            <Field
              label="Last Name"
              component={Input}
              name="last_name"
              id="lastname"
              className={styles.name}
              validate={composeValidators(fieldRequired, validName)}
            />
          </div>
          <div className={styles.row}>
            <Field
              label="Email"
              component={Input}
              type="email"
              name="email"
              id="email"
              className={styles.email}
              parse={trimEmail}
              validate={composeValidators(validEmail, fieldRequired)}
              inputProps={{
                autoComplete: 'username',
              }}
              inputRef={emailRef}
            />
          </div>
          <OnChange name="email">
            {(value) => {
              if (value) {
                setShowSuggestion(false);
                if (emailChangeApplied.current !== value) {
                  emailChangeApplied.current = null;
                }
              }
            }}
          </OnChange>
          <OnBlur name="email">
            {() => {
              const value = emailRef?.current?.value;
              const emailFieldState = form.getFieldState('email');
              if (value && emailFieldState?.valid) {
                handleEmailValidation(value);
              }
            }}
          </OnBlur>

          <div>
            {showSuggestion && (
              <CalloutWarning type="warning" className={styles.suggestedEmail}>
                <span>
                  Did you mean{' '}
                  {`${values?.email?.split('@')[0]}@${emailSuggestion}`}?{' '}
                  <FlatButton
                    onClick={() => applySuggestion(form, values.email)}
                  >
                    Use Suggested Email
                  </FlatButton>
                </span>
              </CalloutWarning>
            )}
          </div>
          <div className={styles.column}>
            <Field
              label="Password"
              component={PasswordInput}
              name="password"
              id="password"
              validate={composeValidators(fieldRequired, validPasswordStrength)}
              className={styles.password}
              inputProps={{
                autoComplete: 'new-password',
              }}
              showPasswordStrengthChecker
            />
          </div>

          <Button
            className={styles.submit}
            type="submit"
            loading={submitting}
            id="signup_submit"
            data-qa="signup-form-submit"
          >
            Sign up
          </Button>
          <Divider text="OR" className={styles.divider} />
          <Button className={styles.mobileLogin} secondary to="/auth/login">
            Log In
          </Button>

          <div className={cx(styles.row, styles.terms)}>
            By clicking the button above you are agreeing to our{' '}
            <a
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.turbotenant.com/terms-of-use/"
            >
              Terms of Use
            </a>
            {' & '}
            <a
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.turbotenant.com/privacy-policy/"
            >
              Privacy Policy
            </a>
          </div>
        </form>
      )}
    </Form>
  );
};

SignupFormExperiment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default SignupFormExperiment;
