import React from 'react';
import { useLottie } from 'lottie-react';

import animationPathJson from '../../../assets/messaging/messagesLoading.animation.json';
import IframeAnimation from '../../../components/Animations/iOSIframeAnimation';
import isiOSDevice from '../../../helpers/isIosDevice';

const MessagesLoadingAnimation = () => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation src="https://lottie.host/?file=e5081289-cad7-45bc-bb37-4fb4c4da0565/sugsSQwQWy.json" />
    );
  }

  const style = {
    height: 100,
  };

  const options = {
    path: animationPathJson,
    loop: true,
    autoplay: true,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

export default MessagesLoadingAnimation;
