import React from 'react';

import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import { H2, Paragraph } from '../../../../components/Typography';
import { linkTo, routes } from '../../../../helpers/routes';
import HourGlass from '../../../../icons/twoTone/HourGlass';
import { segmentTracking } from '../../../../services/utilities';
import OnboardingProgress from '../../components/OnboardingProgress';

import styles from './PaymentMethodVerify.module.scss';

const PaymentMethodVerify = () => (
  <>
    <OnboardingProgress
      step="Rent Payments"
      progress={30}
      isCurrentStepCompleted
    />

    <Card padded className={styles.container}>
      <Card.Content className={styles.content}>
        <Avatar size="larger" icon={<HourGlass />} />
        <H2 align="center" className="mb-4 mt-24">
          <strong>One more step</strong> before you can make a payment
        </H2>
        <Paragraph align="center" size="large" className="mb-24">
          You’ll get a $0.01 deposit in 1-2 days with a 3-letter code in the
          transaction’s description. You must return to TurboTenant and enter
          this code to verify your account.
        </Paragraph>
      </Card.Content>
      <Button
        variant="primary"
        className={styles.button}
        to={linkTo(routes.tenantOnboardingStepRentersInsurance)}
        onClick={() => {
          segmentTracking('i_understand clicked', {
            location: 'Tenant Onboarding Microdeposit Required',
          });
        }}
      >
        I Understand
      </Button>
    </Card>
  </>
);
export default PaymentMethodVerify;
