import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const ClearThick = (props) => (
  <svg {...props} viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <path d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18Z" fill={props.color} />
      <path
        d="m10.478 9 2.216 2.216a1.045 1.045 0 1 1-1.478 1.478L9 10.478l-2.216 2.216a1.045 1.045 0 1 1-1.478-1.478L7.522 9 5.306 6.784a1.045 1.045 0 0 1 1.478-1.478L9 7.522l2.216-2.216a1.045 1.045 0 1 1 1.478 1.478L10.478 9Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

ClearThick.defaultProps = {
  width: 14,
  height: 14,
  color: colors.redLight,
};

ClearThick.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ClearThick);
