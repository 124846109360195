import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Bank from '../../../../icons/streamline/Bank';
import PaymentCardIcon from '../PaymentCardIcon';

import getBankFeeDescription from './AddPaymentMethods/getBankFeeDescription';

import styles from './ExistingPaymentMethod.module.scss';

const getBrand = (brand) => {
  if (brand === 'American Express') {
    return 'Amex';
  }
  if (brand === 'MasterCard') {
    return 'Mastercard';
  }
  if (!brand) {
    return 'Card';
  }
  return brand;
};

const getStatus = (status) => {
  switch (status) {
    case 'verified':
      return 'Verified';
    case 'verification_failed':
      return 'Verification Failed';
    case 'errored':
      return 'Error';
    case 'login_expired':
      return 'Login Expired';
    default:
      return 'Unverified';
  }
};

const ExistingPaymentMethod = ({
  user,
  userLoading,
  paymentMethod,
  to,
  onClickAction,
}) => {
  const isCard = paymentMethod.type === 'CARD';
  if (isCard) {
    const buttonProps = paymentMethod.used_for_rent_payments
      ? { to }
      : {
          onClick: onClickAction,
        };
    return (
      <Card className={styles.container}>
        <div className={styles.infoWrap}>
          <p className={styles.title}>card on file</p>
          <div className={styles.creditCardWrap}>
            <PaymentCardIcon
              brand={paymentMethod.brand}
              className={styles.icon}
            />
            <p className={styles.cardInfo}>{`${getBrand(
              paymentMethod.brand,
            )} ending in ${paymentMethod.last4}`}</p>
          </div>
        </div>
        <div className={cx(styles.buttonWrap)}>
          <Button small className={cx(styles.button)} {...buttonProps}>
            use this card
          </Button>
          <div className={styles.description}>3.49% convenience fee</div>
        </div>
      </Card>
    );
  }
  return (
    <Card className={styles.container}>
      <div className={styles.infoWrap}>
        <p className={styles.title}>account on file</p>
        <div className={styles.creditCardWrap}>
          <Bank width={24} height={24} className={styles.bankIcon} />
          <p className={styles.cardInfo}>
            Bank Account ending in {paymentMethod.last4} -{' '}
            {getStatus(paymentMethod.source_status)}
          </p>
        </div>
      </div>
      <div className={cx(styles.buttonWrap)}>
        <Button
          small
          to={to}
          onClick={onClickAction}
          className={cx(styles.button)}
        >
          use this account
        </Button>
        <div className={styles.description}>
          {getBankFeeDescription(user, userLoading)}
        </div>
      </div>
    </Card>
  );
};

ExistingPaymentMethod.propTypes = {
  onClickAction: PropTypes.func,
  to: PropTypes.string,
  paymentMethod: PropTypes.shape({
    type: PropTypes.oneOf(['CARD', 'BANK_ACCOUNT']),
    brand: PropTypes.string,
    last4: PropTypes.string,
    is_default: PropTypes.string,
    used_for_rent_payments: PropTypes.bool,
    source_status: PropTypes.oneOf([
      'new',
      'validated',
      'verified',
      'verification_failed',
      'errored',
      'login_expired',
    ]),
    source_creation: PropTypes.string,
    expiration: PropTypes.string,
  }),
  user: PropTypes.object,
  userLoading: PropTypes.bool,
};

export default ExistingPaymentMethod;
