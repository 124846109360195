import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Fireworks from '../../../../components/Animations/FireworksAnimation';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Spinner from '../../../../components/Spinner';
import { H2, Paragraph } from '../../../../components/Typography';
import basicUserProfile from '../../../../graphql/basicUserProfile.graphql';
import completeOnboardingGQL from '../../../../graphql/mutations/completeRenterOnboarding.graphql';
import { linkTo, routes } from '../../../../helpers/routes';
import { useRentersProfile } from '../../../../providers/RentersProfileProvider/context';
import { segmentTracking } from '../../../../services/utilities';

import styles from './Success.module.scss';

const Success = () => {
  const history = useHistory();
  const { currentLease } = useRentersProfile();
  const [completeOnboardingMutation, { loading: completingOnboarding }] =
    useMutation(completeOnboardingGQL);

  const onComplete = useCallback(
    async (navigateTo) => {
      await completeOnboardingMutation({
        variables: { leaseId: currentLease.id },
        refetchQueries: [{ query: basicUserProfile }],
        awaitRefetchQueries: true,
      });
      history.push(navigateTo);
    },
    [currentLease.id],
  );

  const goToMessages = useCallback(async () => {
    segmentTracking('onboarding_complete submit', {
      location: 'Tenant Onboarding All Set Page - Message LL',
    });
    await onComplete(linkTo(routes.tenantMessages));
  }, [onComplete]);

  const goToDashboard = useCallback(async () => {
    segmentTracking('onboarding_complete submit', {
      location: 'Tenant Onboarding All Set Page - Dashboard',
    });
    await onComplete(linkTo(routes.tenantDashboard));
  }, [onComplete]);

  return (
    <Card padded className={styles.container}>
      <Card.Content className={styles.content}>
        <div className={styles.animationWrapper}>
          <Fireworks loop />
        </div>
        <H2 align="center" className="mb-4">
          You’re all set!
        </H2>
        <Paragraph align="center" size="large" className="mb-24">
          You are now ready to use your tenant portal for rent payments,
          maintenance, messages, and more!
        </Paragraph>
      </Card.Content>
      <Card.Footer>
        {completingOnboarding ? (
          <Spinner secondary />
        ) : (
          <>
            <Button
              variant="primary"
              className={styles.button}
              loading={completingOnboarding}
              disabled={completingOnboarding}
              onClick={goToMessages}
            >
              let your landlord know
            </Button>
            <Button
              variant="secondary"
              className={styles.button}
              loading={completingOnboarding}
              disabled={completingOnboarding}
              onClick={goToDashboard}
            >
              go to dashboard
            </Button>
          </>
        )}
      </Card.Footer>
    </Card>
  );
};

export default Success;
