import React from 'react';
import PropTypes from 'prop-types';

import BrokenPage from '../../icons/BrokenPage';
import Button from '../Button';

import styles from './PageNotFound.module.scss';

const PageNotFound = ({ actionTitle }) => {
  return (
    <div className={styles.container}>
      <BrokenPage className={styles.icon} />
      <p className={styles.title}>
        Uh oh! That page doesn't exist. We'll call in someone handy to come fix
        it.
      </p>
      <div className={styles.description}>
        Sorry about that. Here is a quick way to get back to your account.
      </div>
      <Button
        className={styles.button}
        onClick={() => {
          window.location = window.location.origin;
        }}
      >
        {actionTitle}
      </Button>
    </div>
  );
};

PageNotFound.propTypes = {
  actionTitle: PropTypes.string,
};

PageNotFound.defaultProps = {
  actionTitle: 'Back to Dashboard',
};

export default PageNotFound;
