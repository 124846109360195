import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '../../../../components/Button';
import { loginCometChatUser } from '../../../../components/CometChatWorkspace/utils';
import FinalFormError from '../../../../components/FinalFormError';
import Input from '../../../../components/Input';
import PasswordInput from '../../../../components/PasswordInput';
import { useInfoToast } from '../../../../components/Toast';
import { login, updateUserData } from '../../../../core/auth/authService';
import { useConfig } from '../../../../providers/ConfigProvider';
import comparePassword from '../../../../validators/comparePassword';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validEmail from '../../../../validators/validEmail';
import validPasswordStrength from '../../../../validators/validPasswordStrength';

import ApplicantSetPasswordSuccess from './ApplicantSetPasswordSuccess';

import styles from './ApplicantSetPassword.module.scss';

const ApplicantSetPassword = ({
  location,
  history,
  updatePassword,
  resetRenterPassword,
  confirmAccount,
  renterSetPassword,
  applicationId,
  hasEmail,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const showInfoToast = useInfoToast();
  const { IS_RENTER, IS_OWNER } = useConfig();
  const queryParameters = queryString.parse(location.search);
  const fromSMS = !!queryParameters?.sms;
  const actionType = queryParameters?.action || null;
  const isResetPassword = actionType === 'reset';
  const token =
    queryParameters?.confirmationToken || queryParameters?.token || null;
  const redirectTo = queryParameters?.redirectTo || null;
  const redirectUrl = queryParameters?.redirectUrl || null;

  let ssoService;

  if ((redirectUrl || '').match(/(learnworlds|academy)/g)) {
    ssoService = 'academy';
  }

  if ((redirectUrl || '').match(/(community)/g)) {
    ssoService = 'forum';
  }

  const getRedirectPath = () => {
    if (ssoService === 'academy') {
      return (redirectUrl.match(/https?:\/\/[^/]+(.+)/) || [])[1];
    }

    if (ssoService === 'forum') {
      return redirectUrl;
    }

    return null;
  };
  const redirectPath = getRedirectPath();

  const getTitle = () => {
    switch (actionType) {
      case 'reset':
        return 'Change your password.';
      case 'external_applicant':
        if (fromSMS && !hasEmail) {
          return 'Add an email and password to create your account.';
        }
        return 'First you need to set a password to secure your account.';
      case 'messages':
        if (!hasEmail) {
          return 'Create Your Account to See Your Messages';
        }
        return 'Set a Password to See Your Messages';
      case 'create_account':
        return 'Sign Up to Access Your Tenant Portal';
      default:
        return 'Set Account Password';
    }
  };

  let description;
  const externalCondition =
    actionType === 'external_applicant' && (!fromSMS || hasEmail);
  const messagesNoEmailCondition = actionType === 'messages' && !hasEmail;
  const messagesCondition = actionType === 'messages' && hasEmail;

  if (externalCondition) {
    description =
      'Before you can verify your identity, you will need to set a password to keep your sensitive information secure.';
  } else if (messagesNoEmailCondition) {
    description =
      'Set an email and password so you can read your messages and reply to the landlord.';
  } else if (messagesCondition) {
    description =
      'Set a password so you can read your messages and reply to the landlord.';
  }

  let buttonLabel = 'Set password';
  if (isResetPassword) {
    buttonLabel = 'Change my password';
  } else if (
    (actionType === 'messages' || actionType === 'create_account') &&
    fromSMS &&
    !hasEmail
  ) {
    buttonLabel = 'Create account';
  }

  const redirectOnSuccess = (redirectUrl) => {
    if (redirectUrl && typeof redirectUrl === 'string') {
      window.location.href = redirectUrl;
      return;
    }

    if (redirectTo) {
      history.replace(redirectTo);
    } else {
      history.replace('/');
    }
  };

  const resetPassword = async (pass) => {
    if (token && pass) {
      try {
        const res = await resetRenterPassword(
          token,
          pass,
          ssoService,
          getRedirectPath(),
        );
        if (res?.messagingToken && res?.hasUnreadMessages) {
          loginCometChatUser(res.messagingToken);
        }
        if (res?.token) {
          login(res.token);
          if (redirectUrl) {
            redirectOnSuccess(redirectUrl);
          } else {
            setShowSuccess(true);
          }
        }
      } catch (error) {
        const errorMessage = error?.message || 'Something went wrong';

        return {
          [FORM_ERROR]: errorMessage,
        };
      }
    }
  };

  const setPassword = async (pass, email) => {
    if (token && pass) {
      try {
        const useToken = ['maintenance', 'insurance', 'payments'].includes(
          actionType,
        )
          ? null
          : token;

        const res = await confirmAccount({
          token: useToken,
          password: pass,
          email,
          ssoService: ssoService,
          redirectPath,
        });

        if (IS_RENTER) {
          login(res.token);
          if (res?.messagingToken && res?.hasUnreadMessages) {
            loginCometChatUser(res.messagingToken);
          }
          setShowSuccess(true);
          return;
        }

        showInfoToast('Password Set');
        if (res?.messagingToken && res?.hasUnreadMessages) {
          loginCometChatUser(res.messagingToken);
        }
        login(res.token);
        redirectOnSuccess(res.redirectUrl);
      } catch (error) {
        const myError =
          (error && error.graphQLErrors && error.graphQLErrors[0]) || null;
        let message = 'Something went wrong';
        if (myError && myError.code === 471) {
          //email in use
          message = 'Email already in use';
        }

        return {
          [FORM_ERROR]: message,
        };
      }
    } else {
      try {
        const res = await renterSetPassword(pass, applicationId);
        showInfoToast('Password Set');
        if (res?.messagingToken && res?.hasUnreadMessages) {
          loginCometChatUser(res.messagingToken);
        }
        login(res.token);
        redirectOnSuccess();
      } catch (error) {
        const errorMessage = error?.message || 'Something went wrong';

        return {
          [FORM_ERROR]: errorMessage,
        };
      }
    }
  };

  const onSubmit = async (data) => {
    if (actionType === 'signature_request') {
      try {
        const res = await updatePassword('', data.password);

        if (res?.ok) {
          await updateUserData();
          setShowSuccess(true);
        }
      } catch (error) {
        const errorMessage = error?.message || 'Something went wrong';

        return {
          [FORM_ERROR]: errorMessage,
        };
      }
    }

    if (isResetPassword) {
      return resetPassword(data.password);
    }
    return setPassword(data.password, data.email);
  };

  let successModalDescription = 'You can now access your TurboTenant account.';
  if (IS_OWNER) {
    successModalDescription =
      'Your password has successfully been changed. Login to access your TurboTenant account.';
  }
  if (actionType === 'external_applicant') {
    successModalDescription =
      'Now you can verify your identity so the landlord can view your screening report.';
  }

  const firstPassFieldLabel = [
    'external_applicant',
    'signature_request',
    'create_account',
    'messages',
  ].includes(actionType)
    ? 'Password'
    : 'New Password';

  const isNewPasswordField = firstPassFieldLabel === 'New Password';

  if (showSuccess) {
    return (
      <ApplicantSetPasswordSuccess
        title={IS_RENTER ? 'Your password is set!' : 'Password Changed!'}
        description={successModalDescription}
        btnLabel={IS_RENTER ? 'Get Started' : 'Continue'}
        onGetStarted={redirectOnSuccess}
      />
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form className={styles.container} onSubmit={handleSubmit}>
          <h1
            className={cx(styles.title, {
              [styles.hasText]: description,
            })}
          >
            {getTitle()}
          </h1>
          {description ? (
            <p className={styles.description}>{description}</p>
          ) : null}
          <FinalFormError />
          {fromSMS && !hasEmail && (
            <Field
              label="Email Address"
              component={Input}
              type="email"
              name="email"
              id="email"
              className={styles.field}
              validate={composeValidators(validEmail, fieldRequired)}
              inputProps={{
                autoComplete: 'username',
              }}
            />
          )}
          <Field
            label={firstPassFieldLabel}
            component={PasswordInput}
            className={cx(styles.field, {
              [styles.mb16]: isNewPasswordField,
            })}
            name="password"
            id="password"
            inputProps={{
              autoComplete: 'new-password',
            }}
            passwordStrengthClassName={styles.mb16}
            {...(isNewPasswordField
              ? {
                  showPasswordStrengthChecker: true,
                  validate: validPasswordStrength,
                }
              : {})}
          />
          {actionType !== 'create_account' && (
            <Field
              label={
                actionType === 'external_applicant' ||
                actionType === 'signature_request' ||
                actionType === 'messages'
                  ? 'Confirm Password'
                  : 'Confirm New Password'
              }
              component={PasswordInput}
              className={styles.field}
              name="passwordConfirm"
              id="passwordConfirm"
              validate={composeValidators(
                fieldRequired,
                comparePassword('password'),
              )}
              hint={null}
              inputProps={{
                autoComplete: 'password',
              }}
            />
          )}
          <Button
            id={isResetPassword ? 'change_password' : 'set_password'}
            className={styles.btn}
            type="submit"
            loading={submitting}
          >
            {buttonLabel}
          </Button>
          <div className={styles.privacy}>
            <span>
              By clicking the button below you are agreeing to our <br />
            </span>
            <span>
              <a
                href="https://www.turbotenant.com/terms-of-use/"
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of use
              </a>
              {' & '}
              <a
                href="https://www.turbotenant.com/terms-of-use/"
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </span>
          </div>
        </form>
      )}
    </Form>
  );
};

ApplicantSetPassword.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  updatePassword: PropTypes.func,
  resetRenterPassword: PropTypes.func,
  confirmAccount: PropTypes.func,
  renterSetPassword: PropTypes.func,
  applicationId: PropTypes.string,
  hasEmail: PropTypes.bool,
};

export default withRouter(ApplicantSetPassword);
