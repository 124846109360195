import React from 'react';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import Banner from '../../../assets/Leases-Leads@2x.png';
import Button from '../../../components/Button';
import SidebarMarketing from '../../../components/SidebarMarketing';
// Owner layout
import MarketingLayout from '../../../layout/MarketingLayout';
import { useConfig } from '../../../providers/ConfigProvider';
import forumLogo from '../login/assets/forum-logo.svg';

import academyBuildingImg from './assets/building-illustration.svg';
import forumBuildingImg from './assets/forum-building-illustration.svg';
// Shared component
import ResetPassword from './ResetPassword';

import styles from './ResetPasswordContainer.module.scss';

const resetPasswordGQL = gql`
  mutation resetPasswordRequest(
    $accountType: UserType!
    $email: String!
    $isReset: Boolean!
    $redirectUrl: String
    $captchaToken: String
  ) {
    resetPasswordRequest(
      input: {
        accountType: $accountType
        email: $email
        isReset: $isReset
        redirectUrl: $redirectUrl
        captchaToken: $captchaToken
      }
    ) {
      ok
    }
  }
`;

const ResetPasswordContainer = ({ forum, academy, ...props }) => {
  const [sendResetEmail, { loading }] = useMutation(resetPasswordGQL);
  const { IS_OWNER } = useConfig();
  const isSSO = forum || academy;

  const getSignupUrl = () => {
    if (academy) {
      return `/auth/academy/signup${props.history?.location?.search || ''}`;
    }

    if (forum) {
      return `/auth/forum/signup${props.history?.location?.search || ''}`;
    }

    return `/signup${props.history?.location?.search || ''}`;
  };

  // Owner layout
  if (IS_OWNER) {
    return (
      <MarketingLayout
        {...(isSSO && { headerClassName: styles.forumLogin })}
        LogoToReplace={forum ? forumLogo : null}
        onLogoClick={() => {
          forum && window.open('https://www.turbotenant.com/community/');
        }}
        action={
          <Button
            className={styles.signupButton}
            to={getSignupUrl()}
            small={true}
            secondary={true}
          >
            SIGN UP
          </Button>
        }
        side={
          <div>
            {isSSO ? (
              <SidebarMarketing
                className={styles.academySidebar}
                title={academy ? 'GET BACK TO UP YOUR LANDLORD GAME' : null}
                subtitle={
                  academy ? (
                    'Where great landlords go to learn'
                  ) : (
                    <span className={styles.sidebarText}>
                      Join over 700k landlords who use TurboTenant to level up
                      their property management business
                    </span>
                  )
                }
                graphic={academy ? academyBuildingImg : forumBuildingImg}
                showLogo
              />
            ) : (
              <SidebarMarketing
                title="Manage The Rental Process"
                subtitle="Get Back to Finding & Managing Great Tenants"
                graphic={Banner}
              />
            )}
          </div>
        }
      >
        <ResetPassword
          type={props.match.params.type}
          sendResetEmail={sendResetEmail}
          loading={loading}
          history={props.history}
          academy={academy}
          forum={forum}
        />
      </MarketingLayout>
    );
  }

  // Renter uses default layout provided by parent components.
  return (
    <ResetPassword
      type={props.match.params.type}
      sendResetEmail={sendResetEmail}
      loading={loading}
      history={props.history}
      isRenter
    />
  );
};

ResetPasswordContainer.propTypes = {
  forum: PropTypes.bool,
  academy: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
};

export default ResetPasswordContainer;
