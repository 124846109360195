import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import LulaLogo from '../../icons/Lula';
import { useConfig } from '../../providers/ConfigProvider';
import Button from '../Button';

import styles from './LulaPromoCard.module.scss';

const LulaPromoCard = ({ className, onClick }) => {
  const { PRIVATE_BASE_PATH } = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.texts}>
        <h4 className={styles.title}>
          Let{' '}
          <span className={styles.logoWrapper}>
            <LulaLogo />
          </span>{' '}
          handle it
        </h4>
        <p className={styles.description}>
          Lula could reach out directly to your tenant for troubleshooting
          within 15 minutes.
        </p>
      </div>
      <Button
        small
        className={styles.button}
        to={`${PRIVATE_BASE_PATH}maintenance/onboarding`}
        onClick={() => {
          onClick && onClick();
        }}
        dataQa="lula-learn-more"
      >
        Learn More
      </Button>
    </div>
  );
};

LulaPromoCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default LulaPromoCard;
