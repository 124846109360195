import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import BannerOwner from '../../../assets/Leases-Leads@2x.png';
import BannerRenter from '../../../assets/tenant-portal-overview.png';
import LoadingScreen from '../../../components/LoadingScreen';
import SidebarMarketing from '../../../components/SidebarMarketing';
import * as AuthService from '../../../core/auth/authService';
import { login } from '../../../core/auth/authService';
import verifyConfirmationToken from '../../../core/services/tokenService';
import hideLeadEmailByDomainFromUI from '../../../helpers/hideLeadEmailByDomainFromUI';
import MarketingLayout from '../../../layout/MarketingLayout';
import { useConfig } from '../../../providers/ConfigProvider';
import { setUrlToRedirectAfterLogin } from '../../../services/utilities';
import ApplicantSetPasswordContainer from '../set_password/ApplicantSetPasswordContainer';
import ApplicantSetPasswordForOthers from '../set_password/ApplicantSetPasswordForOthers';

import styles from './SetPasswordPage.module.scss';

const SetPasswordPage = (props) => {
  const [checkingToken, setCheckingToken] = useState(true);
  const [hasEmail, setHasEmail] = useState(false);
  const { IS_OWNER } = useConfig();

  const location = get(props, 'location', '');
  const history = get(props, 'history', '');

  const checkConfirmationToken = async () => {
    const { token, action, redirectTo, confirmationToken } = queryString.parse(
      location.search,
    );

    if (!token && !confirmationToken) {
      return history.replace('/auth/login');
    }

    try {
      const response = await verifyConfirmationToken(
        `verify?accountType=${IS_OWNER ? '1' : '2'}&${
          token ? `jwtToken=${token}` : `confirmationToken=${confirmationToken}`
        }`,
      );

      if (!response) {
        setUrlToRedirectAfterLogin(redirectTo);
        if (
          action === 'external_applicant' ||
          action === 'messages' ||
          action === 'payments'
        ) {
          return history.replace('/auth/login');
        }
        throw new Error('Not valid');
      }

      if (response.has_password && redirectTo) {
        return history.push(redirectTo);
      }

      if (token && (action === 'messages' || action === 'payments')) {
        login(token);
      }

      setCheckingToken(false);
      setHasEmail(response && response.has_email);

      return;
    } catch (error) {
      console.log(error);
      return history.replace('/auth/login');
    }
  };

  useEffect(() => {
    checkConfirmationToken();
  }, []);

  const loadSetPasswordPage = () => {
    const { token } = queryString.parse(location.search);
    if (!token) return false;
    const decodedToken = AuthService.decodeToken(token) || {};
    const userEmail = get(decodedToken, 'email', '') || '';

    return hideLeadEmailByDomainFromUI(userEmail);
  };

  return (
    <LoadingScreen loading={checkingToken}>
      <MarketingLayout
        side={
          IS_OWNER ? (
            <SidebarMarketing
              title="Manage The Rental Process"
              subtitle="Get Back to Finding & Managing Great Tenants"
              graphic={BannerOwner}
            />
          ) : (
            <SidebarMarketing
              title="Tenant Portal"
              subtitle="Your one-stop-shop for interacting with your landlord."
              graphic={BannerRenter}
            />
          )
        }
      >
        <div className={styles.container}>
          {loadSetPasswordPage() ? (
            <ApplicantSetPasswordForOthers />
          ) : (
            // This is for OWNERS also. The name is misleading. TODO rename
            <ApplicantSetPasswordContainer {...props} hasEmail={hasEmail} />
          )}
        </div>
      </MarketingLayout>
    </LoadingScreen>
  );
};

SetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default SetPasswordPage;
