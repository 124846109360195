import React from 'react';

import Logo from '../../../../components/Logo';

import styles from './OnboardingLogo.module.scss';

const OnboardingLogo = () => (
  <div className={styles.logoWrapper}>
    <Logo width={139} height={40} />
  </div>
);

export default OnboardingLogo;
