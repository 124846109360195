import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import reihubTTLogo from '../../../assets/reihub/reihub-tt-logos.svg';
import reiHubSsoQuery from '../../../graphql/queries/partner_sso/getReiHubSSO.graphql';
import SpiningLoader from '../../../icons/SpiningLoader';
import Modal from '../../Modal';
import { useErrorToast } from '../../Toast';

import styles from './ReiHubLoadingModal.module.scss';

const ReiHubLoadingModal = ({ open, isDemoMode }) => {
  return (
    <Modal open={open} className={styles.modal}>
      <>
        <div className={styles.header}>
          <img src={reihubTTLogo} alt="reihub-turbotenant-logos" />
        </div>
        <SpiningLoader
          style={{ marginBottom: '16px', width: 50, height: 50 }}
        />
        {isDemoMode && (
          <>
            <h3 className={styles.modalTitle}>
              Creating a{' '}
              <span className={styles.modalTitleHighlight}>
                demo REI Hub account
              </span>{' '}
              for you to try.
            </h3>
            <p className={styles.modalMessage}>
              One moment! We will take you to REI Hub once the demo account is
              ready.
            </p>
          </>
        )}
        {isDemoMode || (
          <h3 className={styles.modalTitle}>
            Going to <span className={styles.modalTitleHighlight}>REI Hub</span>{' '}
            for accounting...
          </h3>
        )}
      </>
    </Modal>
  );
};

export const useOpenREIHub = ({
  show = false,
  isDemoMode,
  redirectionPage = '',
}) => {
  const DEFAULT_REI_HUB_URL = 'https://app.reihub.net/turbotenant';
  const [isOpen, setIsOpen] = useState(show);
  const showErrorMessage = useErrorToast();

  const openUrl = useCallback(
    (url) => {
      const newWindow = window.open(url, '_blank');
      if (!newWindow) {
        // A popup blocker prevented the window from opening
        window.location.href = url;
      }
      setIsOpen(false);
      setTimeout(() => document.activeElement.blur(), 1);
    },
    [isOpen, setIsOpen],
  );

  const [getSSOUrl] = useLazyQuery(reiHubSsoQuery, {
    //without this if you hit the query several times in a row it stucks and never returns the data
    variables: {
      ...(redirectionPage && { redirection_page: redirectionPage }),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: (e) => {
      try {
        const errorObj = JSON.parse(e.message);
        showErrorMessage(errorObj.message);
      } catch {
        showErrorMessage(e.message);
      }
      window.atatus &&
        window.atatus.notify({
          message: 'An error occurred while getting REI Hub SSO Url',
          data: { isDemoMode },
        });
      setIsOpen(false);
    },
  });

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const openREIHub = useCallback(async () => {
    setIsOpen(true);
    await sleep(2000);

    return new Promise((resolve) => {
      if (!isDemoMode) {
        getSSOUrl({
          onCompleted: (data) => {
            if (data?.reiHub?.rei_hub_sso) {
              openUrl(data.reiHub.rei_hub_sso);
            }
            resolve(); // Ensures Promise resolves only after URL is opened
            setIsOpen(false);
          },
          onError: () => {
            resolve();
            setIsOpen(false);
          },
        });
      } else {
        openUrl(DEFAULT_REI_HUB_URL);
        resolve(); // Resolves immediately after opening URL for demo mode
        setIsOpen(false);
      }
    });
  }, [isDemoMode, getSSOUrl, openUrl]);

  return [isOpen, openREIHub];
};

ReiHubLoadingModal.propTypes = {
  open: PropTypes.bool,
  isDemoMode: PropTypes.bool,
};

export default ReiHubLoadingModal;
