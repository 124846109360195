import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { isAdminLogin } from '../../core/auth/authService';
import { useUserProfile } from '../../core/TTgraphql';
import Intro from '../../layout/TenantOnboarding/steps/Intro';
import { getActiveLeaseForRenter } from '../../services/utilities/getActiveLeaseForRenter';

import { RentersProfileContext } from './context';

import styles from './RentersProfileProvider.module.scss';

const RentersProfileProvider = ({ children }) => {
  const { user, loading } = useUserProfile();
  const isAdmin = isAdminLogin();
  const currentLease = useMemo(() => {
    if (loading) return null;

    return getActiveLeaseForRenter(user);
  }, [user, loading]);

  const value = useMemo(() => ({ currentLease }), [currentLease]);

  if (loading) {
    return (
      <div className={styles.fullPageLoader}>
        <LoadingScreen loading />
      </div>
    );
  }

  const renterCompletedOnboarding =
    currentLease?.renter_completed_onboarding_flow ?? true;

  return (
    <RentersProfileContext.Provider value={value}>
      {!renterCompletedOnboarding && !isAdmin ? <Intro /> : children}
    </RentersProfileContext.Provider>
  );
};

RentersProfileProvider.propTypes = {
  children: PropTypes.node,
};

export default RentersProfileProvider;
