import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './common.module.scss';

const Line = ({ size, title, breakpoint, hideSmall, expandSmall }) => {
  const parsedSizes =
    size && Array.isArray(size)
      ? size?.map((s) => styles[`${s}`])
      : [styles[`${size}`]];
  return (
    <div
      className={cx(styles.skeletonLine, ...parsedSizes, {
        [styles.title]: title,
        [styles.breakpoint]: breakpoint,
        [styles.hideSmall]: hideSmall,
        [styles.expandSmall]: expandSmall,
      })}
    />
  );
};

Line.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.bool,
  breakpoint: PropTypes.bool,
  hideSmall: PropTypes.bool,
  expandSmall: PropTypes.bool,
};

export default Line;
