import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useConfig } from '../providers/ConfigProvider';

import OwnersRoutes from './owners/routes.owners';
import RentersRoutes from './renters/routes.renters';

const UserRoutes = (props) => {
  const { IS_RENTER } = useConfig();

  const { match } = props;
  const baseUrl = match.url;

  if (baseUrl.startsWith('/renters') && IS_RENTER) {
    return <RentersRoutes {...props} />;
  }
  return <OwnersRoutes {...props} />;
};

UserRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(UserRoutes);
