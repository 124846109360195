import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import BulletSquare from '../../icons/BulletSquare';

import styles from './DetailsList.module.scss';

const DetailsList = ({
  className,
  list,
  title,
  hideBullets,
  titleClassName,
  itemClassName,
  listClassName,
  textClassName,
  icon: Icon,
  iconColor,
  spacing = 'normal',
}) => {
  if (!list || list.length === 0) {
    return null;
  }
  return (
    <div className={cx(styles.container, className)}>
      {title && <h6 className={cx(styles.title, titleClassName)}>{title}</h6>}
      <div className={cx(styles.list, listClassName)}>
        {list.map((text, idx) => (
          <div
            key={idx}
            className={cx(
              styles.item,
              { [styles.smallSpacing]: spacing === 'small' },
              itemClassName,
            )}
          >
            {!hideBullets && (
              <Icon className={styles.bullet} color={iconColor} />
            )}
            <span className={cx(styles.text, textClassName)}>{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

DetailsList.propTypes = {
  className: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  title: PropTypes.string,
  hideBullets: PropTypes.bool,
  titleClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  listClassName: PropTypes.string,
  textClassName: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  iconColor: PropTypes.string,
  spacing: PropTypes.oneOf(['normal', 'small']),
};

DetailsList.defaultProps = {
  icon: BulletSquare,
};

export default DetailsList;
