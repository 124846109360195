import React from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import isiOSDevice from '../../../helpers/isIosDevice';
import IframeAnimation from '../iOSIframeAnimation';

import animationPathJson from './TenantFeatures.animation.json';

const TenantFeatures = ({ style, loop }) => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/?file=df6ce88b-c99a-439a-af28-75c197edf720/jlBacqR3tT.json"
      />
    );
  }

  const LoaderData = useLottie(
    {
      path: animationPathJson,
      autoplay: true,
      loop,
    },
    style,
  );

  return LoaderData.View;
};

TenantFeatures.propTypes = {
  style: PropTypes.object,
  loop: PropTypes.bool,
};

export default TenantFeatures;
