import React from 'react';
import PropTypes from 'prop-types';

const Calculator = ({ width, height, ...rest }) => (
  <svg viewBox="0 0 48 49" fill="none" width={width} height={height} {...rest}>
    <path d="M35 8.30347H13V15.3035H35V8.30347Z" fill="#7FE3FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.9474 4.30347C37.7266 4.30347 40 6.55236 40 9.30895V39.298C40 42.0546 37.7266 44.3035 34.9474 44.3035H13.0526C10.2734 44.3035 8 42.0546 8 39.298V9.30895C8 6.55236 10.2734 4.30347 13.0526 4.30347H34.9474ZM23.157 30.9745H9.684L9.68421 39.298C9.68421 41.0622 11.1009 42.5225 12.8686 42.6178L13.0526 42.6228L23.157 42.6225V30.9745ZM38.315 30.9745H24.842V42.6225L34.9474 42.6228C36.738 42.6228 38.2143 41.2214 38.3108 39.4794L38.3158 39.298L38.315 30.9745ZM14.3588 33.3982L16.435 35.4705L18.4857 33.4431L19.6714 34.6367L17.626 36.6595L19.6741 38.7046L18.483 39.8927L16.43 37.8435L14.3561 39.8948L13.1703 38.7012L15.239 36.6545L13.1676 34.5863L14.3588 33.3982ZM34.9474 37.6246V39.3053H28.2105V37.6246H34.9474ZM34.9474 34.2925V35.9732H28.2105V34.2925H34.9474ZM23.157 19.3125H9.684V29.2935H23.157V19.3125ZM38.315 19.3125H24.842V29.2935H38.315V19.3125ZM16.4211 20.9714L16.421 23.4625L18.9474 23.4631V25.1438L16.421 25.1435L16.4211 27.6356H14.7368L14.736 25.1435L12.2105 25.1438V23.4631L14.736 23.4625L14.7368 20.9714H16.4211ZM34.9474 24.2962V25.9768H28.2105V24.2962H34.9474ZM34.9474 5.98414H13.0526C11.2003 5.98414 9.68421 7.48387 9.68421 9.30895L9.684 17.6315H38.315L38.3158 9.30895C38.3158 7.54471 36.8991 6.08448 35.1314 5.98909L34.9474 5.98414ZM34.2821 7.63558C35.5759 7.63558 36.6316 8.67992 36.6316 9.96704V13.6507C36.6316 14.9366 35.5755 15.9805 34.2821 15.9805H13.7179C12.4245 15.9805 11.3684 14.9366 11.3684 13.6507V9.96704C11.3684 8.67992 12.4241 7.63558 13.7179 7.63558H34.2821ZM34.2821 9.31625H13.7179C13.351 9.31625 13.0526 9.61143 13.0526 9.96704V13.6507C13.0526 14.0048 13.3511 14.2998 13.7179 14.2998H34.2821C34.6489 14.2998 34.9474 14.0048 34.9474 13.6507V9.96704C34.9474 9.61143 34.649 9.31625 34.2821 9.31625Z"
      fill="#042238"
    />
  </svg>
);

Calculator.defaultProps = {
  width: 48,
  height: 48,
};

Calculator.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Calculator;
