import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Section.module.scss';

const Section = ({
  className,
  children,
  padding,
  column,
  justify,
  align,
  gap,
  margin,
  breakpoint,
  wrap,
  hideSmall,
}) => {
  const parsedPadding =
    padding && Array.isArray(padding)
      ? padding?.map((p) => styles[`padding-${p}`])
      : [styles[`padding-${padding}`]];

  const parsedMargin =
    margin && Array.isArray(margin)
      ? margin?.map((m) => styles[`margin-${m}`])
      : [styles[`margin-${margin}`]];

  return (
    <div
      className={cx(
        styles.skeletonSection,
        className,
        styles.section,
        ...parsedPadding,
        ...parsedMargin,
        styles[justify],
        styles[`align-${align}`],
        styles[`gap-${gap}`],
        {
          [styles.column]: column,
          [styles.breakpoint]: breakpoint,
          [styles.gap]: gap,
          [styles.wrap]: wrap,
          [styles.hideSmall]: hideSmall,
        },
      )}
    >
      {children}
    </div>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  column: PropTypes.bool,
  justify: PropTypes.string,
  align: PropTypes.string,
  gap: PropTypes.string,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  breakpoint: PropTypes.bool,
  wrap: PropTypes.bool,
  hideSmall: PropTypes.bool,
};

export default Section;
