import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';
import {
  APP_STORE_LANDLORD_URL,
  APP_STORE_RENTER_URL,
  PLAY_STORE_LANDLORD_URL,
  PLAY_STORE_RENTER_URL,
} from '../constants';

import { Link } from './EmailTypography';
import LandlordsFooterEmailComponent from './LandlordsFooterEmailComponent';
import RedfinFooter from './RedfinFooter';

const FooterSection = ({
  isFromRedfin,
  footerToUse,
  recipientIsRenter,
  isPreview,
}) => {
  if (isFromRedfin) {
    return <RedfinFooter />;
  }

  if (footerToUse) {
    return footerToUse;
  }

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Link
          href={
            recipientIsRenter ? APP_STORE_RENTER_URL : APP_STORE_LANDLORD_URL
          }
        >
          <img
            style={{ marginRight: 12, width: 118 }}
            src={`${config.assetsBaseUrl}/emails/ios2.png`}
          />
        </Link>
        <Link
          href={
            recipientIsRenter ? PLAY_STORE_RENTER_URL : PLAY_STORE_LANDLORD_URL
          }
        >
          <img
            style={{ width: 132 }}
            src={`${config.assetsBaseUrl}/emails/android2.png`}
          />
        </Link>
      </div>
      <LandlordsFooterEmailComponent isPreview={isPreview} />
    </>
  );
};

FooterSection.propTypes = {
  isFromRedfin: PropTypes.bool,
  footerToUse: PropTypes.node,
  recipientIsRenter: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default FooterSection;
