import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';

const LateFeeInfoMT = ({ className, fullStateName }) => (
  <InlineAlert className={className} title={fullStateName}>
    <ul>
      <li>
        There is no state law on a mandatory grace period, but 5 days is common.
      </li>
      <li>
        There is no state law on the amount of late fees to be charged, but 5%
        is common.
      </li>
    </ul>
  </InlineAlert>
);

LateFeeInfoMT.propTypes = {
  className: PropTypes.string,
  fullStateName: PropTypes.string,
};

export default LateFeeInfoMT;
