import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Video = (props) => (
  <svg {...props} viewBox="0 0 27 20" fill={props.color}>
    <path
      d="M26.517.667c.45 0 .816.363.816.81v18.379c0 .448-.365.81-.816.81H1.483a.814.814 0 01-.816-.81V1.477c0-.447.365-.81.816-.81zM8.286 17.423H2.299v1.622h5.987v-1.622zm17.415 0h-5.987v1.622H25.7v-1.622zm-7.62 0H9.918v1.622h8.163v-1.622zM25.7 5.532H2.299v10.27h23.402V5.532zM10.463 7.964c0-.59.613-.982 1.154-.738l5.986 2.703a.809.809 0 010 1.476l-5.986 2.703a.816.816 0 01-1.154-.739zm1.632 1.258v2.888l3.198-1.443-3.198-1.445zm-3.81-6.934H2.3V3.91h5.987V2.288zm17.416 0h-5.987V3.91H25.7V2.288zm-7.62 0H9.918V3.91h8.163V2.288z"
      fillRule="evenodd"
    />
  </svg>
);

Video.defaultProps = {
  width: 27,
  height: 20,
  color: colors.text,
};

Video.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Video);
