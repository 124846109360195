import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import FlatButton from '../../../../components/FlatButton';
import { useErrorToast } from '../../../../components/Toast';
import { useUserProfile } from '../../../../core/TTgraphql';
import basicUserProfile from '../../../../graphql/basicUserProfile.graphql';
import getActiveListingForRenter from '../../../../helpers/getActiveListingForRenter';
import { linkTo, routes } from '../../../../helpers/routes';
import postProofOfInsuranceGQL from '../../../../pages/renters/graphql/postProofOfInsurance.graphql';
import UploadPolicy from '../../../../pages/renters/insurance/UploadPolicy';
import SegmentTrackingProvider from '../../../../providers/SegmentTrackingProvider';
import { segmentTracking } from '../../../../services/utilities';
import OnboardingProgress from '../../components/OnboardingProgress';

import styles from './RentersInsuranceUpload.module.scss';

const mapper = {
  upload: {
    event: 'upload_and_save clicked',
    location: 'Tenant Onboarding Insurance Upload Page',
  },
  cancel: {
    event: 'cancel clicked',
    location: 'Tenant Onboarding Insurance Upload Page',
  },
};

const RentersInsuranceUpload = ({ history, match }) => {
  const { user, loading } = useUserProfile();
  const listing = useMemo(() => {
    if (loading) return null;

    return getActiveListingForRenter(user);
  }, [user, loading]);
  const showErrorMessage = useErrorToast();
  const [
    postProofOfInsuranceRequest,
    { loading: loadingPostProofOfInsurance },
  ] = useMutation(postProofOfInsuranceGQL);

  const postProofOfInsurance = useCallback(async (variables = {}) => {
    try {
      await postProofOfInsuranceRequest({
        variables: {
          ...variables,
        },
        refetchQueries: [{ query: basicUserProfile }],
        awaitRefetchQueries: true,
      });
      history.push(linkTo(routes.tenantOnboardingStepRentersInsuranceSuccess));
    } catch {
      showErrorMessage(
        'There was a problem uploading your renters insurance document.',
      );
    }
  }, []);

  return (
    <>
      <OnboardingProgress step="Renters Insurance" progress={60} />
      <SegmentTrackingProvider mapper={mapper}>
        <UploadPolicy
          user={user}
          history={history}
          match={match}
          loading={loading || loadingPostProofOfInsurance}
          postProofOfInsurance={postProofOfInsurance}
          defaultListingId={listing?.id}
          hideBackButton
          hideSuccessStep
          uploadButtonLabel="Upload & Save"
          containerClassName={styles.container}
          contentClassName={styles.content}
          actionsClassName={styles.uploadButtonWrapper}
        />
      </SegmentTrackingProvider>
      <FlatButton
        to={linkTo(routes.tenantOnboardingStepDownloadApp)}
        onClick={() => {
          segmentTracking('renters_insurance submit', {
            location: 'Tenant Onboarding Insurance Upload Page - Skip',
          });
        }}
      >
        Skip for now
      </FlatButton>
    </>
  );
};

RentersInsuranceUpload.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(RentersInsuranceUpload);
