import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  PROMO_CODE,
  PROMO_DISCOUNT,
  PROMO_END_DATE,
} from '../../constants/maintenance_plus/maintenance-promo';
import CloseIcon from '../../icons/Close';
import MaintenanceAltColoredIcon from '../../icons/MaintenanceAltColored';
import { useConfig } from '../../providers/ConfigProvider';
import Button from '../Button';
import Card from '../Card';

import styles from './LulaWaitlistBanner.module.scss';

const LulaWaitlistBanner = ({ className, onBannerDismiss, onLearnMore }) => {
  const { MAINTENANCE_PLUS_PLAN_PRICE } = useConfig();

  const currentDate = new Date();
  const isPromotionActive = currentDate <= PROMO_END_DATE;
  const newPrice = Math.ceil(
    MAINTENANCE_PLUS_PLAN_PRICE * (1 - PROMO_DISCOUNT),
  );
  const savedAmount = MAINTENANCE_PLUS_PLAN_PRICE - newPrice;

  return (
    <Card
      className={cx(styles.container, className, {
        [styles.showOffer]: isPromotionActive,
      })}
    >
      <CloseIcon
        className={styles.close}
        width={14}
        height={14}
        onClick={onBannerDismiss}
      />
      <MaintenanceAltColoredIcon className={styles.maintenanceIcon} />
      <div className={styles.content}>
        <h3 className={styles.title}>
          {isPromotionActive
            ? `Save $${savedAmount} on Stress-Free Maintenance`
            : 'A Maintenance process run for you, not by you.'}
        </h3>
        <p className={styles.description}>
          {isPromotionActive && (
            <>
              Get Maintenance Plus for ${newPrice} per year, our lowest price
              ever. Use <strong>{PROMO_CODE}</strong> at checkout for a limited
              time.{' '}
            </>
          )}
          We provide troubleshooting, scheduling and vetted contractors while
          keeping you informed with real-time job status updates.
        </p>
      </div>
      <Button className={styles.btn} secondary onClick={onLearnMore}>
        Learn More
      </Button>
    </Card>
  );
};

LulaWaitlistBanner.propTypes = {
  className: PropTypes.string,
  onBannerDismiss: PropTypes.func,
  onLearnMore: PropTypes.func,
};

export default LulaWaitlistBanner;
