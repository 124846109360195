import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import { useConfig } from '../../providers/ConfigProvider';
import Button from '../Button';
import SimpleVideoPlayer from '../SimpleVideoPlayer';

import styles from './VideoAndDescriptionHolder.module.scss';

const VideoAndDescriptionHolder = ({ className, openAcademy }) => {
  const { ASSET_BUCKET_URL } = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.description}>
        <span className={styles.subTitle}>FEATURED COURSE</span>
        <h3>Invest in Student Housing</h3>
        <p className={styles.desc}>
          Last fall, there were over 18 million students enrolled in
          degree-granting colleges and universities in America, and they all
          need a place to live. Led by expert landlords from CU and CSU, this
          course gives you the blueprint to create and grow your own lucrative
          student housing business.
        </p>
        <Button onClick={openAcademy} fullWidth>
          Learn More
        </Button>
      </div>
      <div className={styles.videoHolder}>
        <SimpleVideoPlayer
          containerClassName={styles.video}
          videoUrl="https://www.youtube-nocookie.com/embed/MGRJ_JrNJ1c?rel=0"
          playing={false}
          imageOverlay={`${ASSET_BUCKET_URL}uploads/assets/invest-student-housing.png`}
          iconProps={{
            className: styles.icon,
            width: 140,
            height: 140,
            bgColor: colors.white,
            color: colors.primary,
          }}
        />
      </div>
    </div>
  );
};

VideoAndDescriptionHolder.propTypes = {
  className: PropTypes.string,
  openAcademy: PropTypes.func,
};

export default VideoAndDescriptionHolder;
