import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../../../../core/TTgraphql';
import Bank from '../../../../../icons/streamline/Bank';
import Card from '../../../../../icons/streamline/CreditCard';
import { useSegmentTracker } from '../../../../../providers/SegmentTrackingProvider/context';
import PaymentMethod from '../PaymentMethodOption';

import getBankFeeDescription from './getBankFeeDescription';

import styles from './PaymentMethodList.module.scss';

const PaymentMethodList = ({ handleChangePaymentMethod, primaryButton }) => {
  const { user, loading } = useUserProfile();
  const { trackAction } = useSegmentTracker();

  const paymentMethods = useMemo(
    () => [
      {
        icon: Card,
        title: 'Debit Card',
        description: '3.49% convenience fee',
        id: 'debit',
        actionLabel: 'add card',
        processingTime: 'Instant processing',
        onClick: () => trackAction('addDebit'),
        preferred: true,
        primary: primaryButton,
      },
      {
        icon: Card,
        title: 'Credit Card',
        description: '3.49% convenience fee',
        id: 'credit',
        actionLabel: 'add card',
        processingTime: 'Instant processing',
        onClick: () => trackAction('addCredit'),
        className: styles.creditCard,
      },
      {
        icon: Bank,
        title: 'Bank Account',
        description: getBankFeeDescription(user, loading),
        id: 'bank-account',
        actionLabel: 'add bank',
        iconProps: { height: 57, width: 54 },
        processingTime: '3-5 days to process',
        onClick: () => trackAction('addBank'),
      },
    ],
    [user, loading, primaryButton],
  );

  return (
    <>
      {paymentMethods.map(({ id, onClick, ...props }) => (
        <PaymentMethod
          key={id}
          id={id}
          onClickAction={() => {
            onClick();
            handleChangePaymentMethod(id);
          }}
          {...props}
        />
      ))}
    </>
  );
};

PaymentMethodList.propTypes = {
  handleChangePaymentMethod: PropTypes.func.isRequired,
  primaryButton: PropTypes.bool,
};

export default PaymentMethodList;
