import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../Button';
import { H2, Paragraph } from '../../../../../Typography';
import MessagesLoadingAnimation from '../../../../../../pages/owners/messages/MessagesLoadingAnimation';

import TemplateCard from './TemplateCard';

import styles from './QuickReplyTemplatesModal.module.scss';

const TemplateList = ({ data, loading, onCreate, onChoose, onEdit }) => (
  <>
    <H2 className={styles.title}>Quick Reply Templates</H2>
    <Paragraph>
      Use common reply templates to communicate efficiently. You'll be able to
      edit before sending.
    </Paragraph>
    {!loading ? (
      <>
        <Button className={styles.createButton} onClick={onCreate} secondary>
          Create new template
        </Button>
        <div className={styles.cardsWrapper}>
          {data?.messagesTemplates.map((template) => (
            <TemplateCard
              key={template.id}
              template={template}
              onChoose={onChoose}
              onEdit={onEdit}
            />
          ))}
        </div>
      </>
    ) : (
      <div className={styles.animationWrapper}>
        <MessagesLoadingAnimation />
      </div>
    )}
  </>
);

TemplateList.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onChoose: PropTypes.func,
  onEdit: PropTypes.func,
};

export default TemplateList;
