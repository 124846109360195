import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import TenantFeatures from '../../../../components/Animations/TenantFeatures';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import { H2, Paragraph } from '../../../../components/Typography';
import basicUserProfile from '../../../../graphql/basicUserProfile.graphql';
import startOnboardingGQL from '../../../../graphql/mutations/startRenterOnboarding.graphql';
import { linkTo, routes } from '../../../../helpers/routes';
import { useRentersProfile } from '../../../../providers/RentersProfileProvider/context';
import { segmentTracking } from '../../../../services/utilities';
import OnboardingLogo from '../../components/OnboardingLogo';

import styles from './Intro.module.scss';

const Intro = () => {
  const history = useHistory();
  const { currentLease } = useRentersProfile();
  const [startOnboardingMutation, { loading: startingOnboarding }] =
    useMutation(startOnboardingGQL);

  const onStart = useCallback(async () => {
    segmentTracking('lets_get_started clicked', {
      location: 'Tenant Onboarding Intro Page',
    });
    await startOnboardingMutation({
      variables: { leaseId: currentLease.id },
      refetchQueries: [{ query: basicUserProfile }],
      awaitRefetchQueries: true,
    });
    history.push(linkTo(routes.tenantOnboardingStepPaymentMethod));
  }, [currentLease.id]);

  useEffect(() => {
    segmentTracking('page viewed', {
      location: 'Tenant Onboarding Intro Page',
    });
  }, []);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.background}>
        <OnboardingLogo />
        <Card padded className={styles.container}>
          <Card.Content className={styles.content}>
            <div className={styles.animationWrapper}>
              <TenantFeatures loop />
            </div>

            <H2 align="center" className="mb-4">
              Say hello to your new home!
            </H2>
            <Paragraph align="center" size="large" className="mb-24">
              Find everything you need as a renter at{' '}
              {currentLease?.listing?.address}.
            </Paragraph>
          </Card.Content>
          <Card.Footer>
            <Button
              variant="primary"
              className={styles.button}
              loading={startingOnboarding}
              disabled={startingOnboarding}
              onClick={onStart}
            >
              Let’s Get Started
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};
export default Intro;
