import {
  line,
  text,
  greyLight,
  grey1,
} from '../../../../../../constants/colors';

export const newMessageModalStyle = () => {
  return {
    maxWidth: '384px',
    padding: '32px 16px 8px 16px',
    height: '100%',
    ['@media screen and (max-width: 478px)']: {
      maxWidth: 'unset',
    },
  };
};

export const newMessageModalTitleContainer = (isFromUniversalMessaging) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: isFromUniversalMessaging ? '0px' : '16px',
    padding: isFromUniversalMessaging ? '12px 16px' : '0 0px',
    borderBottom: isFromUniversalMessaging ? `1px solid ${line}` : 'none',
    minHeight: isFromUniversalMessaging ? '50px' : 'unset',
    alignItems: isFromUniversalMessaging ? 'center' : 'unset',
  };
};

export const newMessageModalTitle = (isFromUniversalMessaging) => {
  return {
    color: text,
    fontFamily: 'Poppins',
    fontSize: isFromUniversalMessaging ? '20px' : '24px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '34px',
    margin: 0,
    marginBottom: isFromUniversalMessaging ? '4px !important' : 'unset',
  };
};

export const newMessageModalTitleCloseButton = () => {
  return {};
};

export const contactSearchContainerStyle = () => {
  return {
    borderBottom: `1px solid ${line}`,
  };
};

export const contactSearchStyle = () => {
  return {
    margin: '16px',
    position: 'relative',
    borderRadius: '4px',
    boxShadow: `inset 0 0 0 1px ${greyLight}`,
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
};

export const contactSearchButtonStyle = (img) => {
  return {
    width: '32px',
    height: '100%',
    cursor: 'default',
    mask: `url(${img}) 10px center no-repeat`,
    backgroundColor: `${text}!important`,
  };
};

export const contactSearchInputStyle = () => {
  return {
    width: 'calc(100% - 30px)',
    padding: '11px 16px 11px 8px',
    fontSize: '17px',
    letterSpacing: 0,
    fontWeight: '400',
    lineHeight: '26px',
    color: grey1,
    outline: 'none',
    border: 'none',
    color: text,
    backgroundColor: 'transparent',
  };
};

export const contactListStyle = () => {
  return {
    height: 'calc(100% - 125px)',
    overflowY: 'auto',
    margin: '0',
    padding: '0',
  };
};

export const contactAlphabetStyle = () => {
  return {
    padding: '0 16px',
    margin: '5px 0',
    width: '100%',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    color: text,
  };
};

export const contactMsgStyle = () => {
  return {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '24px',
  };
};

export const contactMsgTxtStyle = (theme) => {
  return {
    margin: '0',
    minHeight: '36px',
    color: `${theme.color.secondary}`,
    fontSize: '20px!important',
    fontWeight: '600',
    lineHeight: '30px',
    wordWrap: 'break-word',
    padding: '0 16px',
  };
};

export const fullScreenOverlayStyle = (isVisible) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 1000,
  opacity: isVisible ? 1 : 0,
  visibility: isVisible ? 'visible' : 'hidden',
  transform: isVisible ? 'translateX(0)' : 'translateX(100%)',
  transition: 'opacity 0.4s ease, transform 0.4s ease',
});

export const overlayContentStyle = () => {
  return {
    backgroundColor: 'white',
    width: '100%',
    maxWidth: '400px',
    height: '100%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    color: text,
    paddingBottom: '15px',
  };
};

export const backButtonContainerStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
  };
};

export const backButtonStyle = () => {
  return {
    marginRight: '16px',
    cursor: 'pointer',
  };
};

export const emptyStateContainerStyle = () => {
  return {
    height: '78%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 16px',
  };
};

export const emptyStateTitleStyle = () => {
  return {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '0px',
  };
};

export const emptyStateTextStyle = () => {
  return {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'center',
  };
};
