import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Button.module.scss';

const Button = ({ className, size, position, dark, float }) => (
  <div
    className={cx(
      className,
      styles.skeletonButton,
      styles[size],
      styles[position],
      styles[`float-${float}`],
      {
        [styles.dark]: dark,
      },
    )}
  />
);

Button.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.string,
  float: PropTypes.string,
  dark: PropTypes.bool,
};

export default Button;
