import React from 'react';
import { Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import Card from '../../../../../../components/Card';
import FinalFormError from '../../../../../../components/FinalFormError';
import HelpfulInfoCard from '../../../../../../components/HelpfulInfo';
import PropertyAddressFields from '../../../../../../components/PropertyAddressFields';
import PropertyInformationMultiUnits from '../../../../../../components/PropertySetupForms/PropertyInformationForm/PropertyInformationMultiUnits';
import PropertyInformationSingleUnits from '../../../../../../components/PropertySetupForms/PropertyInformationForm/PropertyInformationSingleUnits';
import PropertyTypeFields from '../../../../../../components/PropertyTypeFields';
import SectionTitleWithIcon from '../../../../../../components/SectionTitleWithIcon';
import LEASE_PROPERTY_TYPE from '../../../../../../constants/lease/LeasePropertyType';
import { useUserProfile } from '../../../../../../core/TTgraphql';
import isSinglePropertyType from '../../../../../../helpers/isSinglePropertyType';
import LocationIcon from '../../../../../../icons/Location';
import TurboTenantSmallLogo from '../../../../../../icons/TurboTenantSmallLogo';
import validShareAbleAddress from '../../../../../../validators/validShareAbleAddress';
import validShareAbleCity from '../../../../../../validators/validShareAbleCity';
import validShareAbleUnit from '../../../../../../validators/validShareAbleUnit';

import styles from './PropertyDetailsNewOnboarding.module.scss';

const PropertyDetailsNewOnboarding = ({
  onSubmit,
  initialValues,
  isOfflineTracking,
}) => {
  const { response = {} } = useUserProfile({}, false);
  const { data = {} } = response;
  const { userData = {} } = data;
  const { basicProfile = {} } = userData;
  const { onboarding_data = {} } = basicProfile.data || {};
  const { properties } = onboarding_data;

  return (
    <Card className={styles.formContainer}>
      {!isOfflineTracking && (
        <span className={styles.subtitle}>
          {properties >= 1 ? 'ADD NEW PROPERTY' : 'ADD YOUR FIRST PROPERTY'}
        </span>
      )}
      <h2 className={styles.title}>
        {isOfflineTracking
          ? 'What Property Do You Want to Set Up Rent Tracking For?'
          : 'What property are you collecting rent for?'}
      </h2>
      <p className={styles.description}>
        Collecting rent for multiple properties? You can add more later!
      </p>
      <Form
        onSubmit={onSubmit}
        subscription={{
          values: true,
          submitting: true,
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <PropertyTypeFields
              location="property_details_new_onboarding"
              className={styles.propertyFields}
              propertiesCantainerClassName={styles.propertiesContainer}
              propertiesRadioProps={{ contentClassName: styles.propertiesItem }}
              defaultValueForPropertyType={LEASE_PROPERTY_TYPE.SINGLE_FAMILY}
              defaultValueForRoomRentals={false}
            />
            {values?.property_type && values?.roomRentals !== undefined && (
              <>
                <div className={styles.addressContainer}>
                  <SectionTitleWithIcon
                    centered={false}
                    title="Property Address"
                    icon={LocationIcon}
                    iconProps={{ height: 27, width: 27 }}
                    iconClassNames={styles.icon}
                  />
                  <PropertyAddressFields
                    className={styles.propertyAddressFields}
                    validators={{
                      city: [validShareAbleCity],
                      unit: [validShareAbleUnit],
                      address: [validShareAbleAddress],
                    }}
                    showUnit={isSinglePropertyType(values.property_type)}
                    onboardingFlow="Rent Collection"
                  />
                </div>
                {isSinglePropertyType(values.property_type) ? (
                  <PropertyInformationSingleUnits
                    hasRoomRentals={values?.roomRentals}
                  />
                ) : (
                  <PropertyInformationMultiUnits
                    className={cx({
                      [styles.multiUnitNoRooms]: values?.roomRentals === false,
                    })}
                    hasRoomRentals={values?.roomRentals}
                  />
                )}
              </>
            )}
            {properties > 1 && (
              <HelpfulInfoCard
                className={styles.helpCard}
                title="What about my other rentals?"
                Icon={TurboTenantSmallLogo}
                iconProps={{
                  className: styles.helpCardIcon,
                }}
              >
                You can add charges for other rentals once the setup is
                complete. We'll focus on one rental for now.
              </HelpfulInfoCard>
            )}
            <div className={styles.action}>
              <Button
                className={styles.submit}
                type="submit"
                loading={submitting}
                disabled={submitting}
                id="add_lease_details_submit"
                data-qa="lease-details-basic-button-continue"
              >
                Next
              </Button>
              <FinalFormError className={styles.error} />
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};

PropertyDetailsNewOnboarding.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isOfflineTracking: PropTypes.bool,
};

PropertyDetailsNewOnboarding.defaultProps = {
  initialValues: {},
  isOfflineTracking: false,
};

export default PropertyDetailsNewOnboarding;
