import React from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { Experiments } from '../../constants/experiments';
import * as authService from '../../core/auth/authService';
import { COOKIE_NAMES, getCookies } from '../../helpers/cookies';
import getDeviceSignature from '../../helpers/get-device-signature';
import { getSignupTrackingCookie } from '../../helpers/signupTrackingData';
import {
  getCurentTest,
  onSignupSucess,
} from '../../pages/auth/signup/ABtestsHelper/FlowPicker';
import signupSocialGQL from '../../pages/auth/signup/graphql/signUpOwnerSocial.graphql';
import signupGaTracking from '../../pages/auth/signup/utils/signupGaTracking';
import signupImpactTracking from '../../pages/auth/signup/utils/signupImpactTracking';
import * as localStorageService from '../../services/localStorageService';
import { segmentTracking } from '../../services/utilities';
import FaceBookLogin from '../FacebookLogin';
import GoogleLogin from '../GoogleLogin';
import { useErrorToast } from '../Toast';

import styles from './SocialSignup.module.scss';

const SocialSignup = ({
  className,
  location,
  history,
  onboarding_personalize_flow,
  ssoService,
  additionalSignupData,
  segmentLocation,
}) => {
  const [signup] = useMutation(signupSocialGQL);
  const showErrorMessage = useErrorToast();
  const currentTest = getCurentTest();

  const login = (token) => {
    authService.login(token);
    const queryParams = queryString.parse(history.location.search);
    queryParams.confirmed = true;
    queryParams.oauth = true;
    onSignupSucess(
      history,
      additionalSignupData?.process,
      `?${queryString.stringify(queryParams)}`,
    );
  };

  const socialSignUp = async (signUpData) => {
    const { gclid, msclkid } = queryString.parse(location.search);
    const refId = localStorageService.getItem('ref') || null;
    const cookies = getCookies();
    const deviceSignature = getDeviceSignature();
    if (gclid) signUpData.gclid = gclid;
    if (msclkid) signUpData.msclkid = msclkid;

    const cookieGclid = cookies.gclid || cookies._gcl_aw;
    const formsExp = get(cookies, COOKIE_NAMES.FORMS_EXP);

    if (cookieGclid && cookieGclid.split('.').length > 2) {
      signUpData.gclid = cookieGclid.split('.')[2];
    }

    const cookieMsclkid = cookies._uetmsclkid;

    if (cookieMsclkid) {
      signUpData.msclkid = cookieMsclkid.slice(4);
    }

    const cookieImpact = get(cookies, COOKIE_NAMES.IMPACT_COOKIE_NAME, '');
    let impactClickId;
    if (cookieImpact) {
      const cookieImpactDecoded = decodeURIComponent(cookieImpact);
      if (cookieImpactDecoded.split('|').length > 4) {
        impactClickId = cookieImpactDecoded.split('|')[3];
        signUpData.impactClickId = impactClickId;
      }
    }

    // share a sale cookie and click id
    const shareASaleCookie = get(
      cookies,
      COOKIE_NAMES.SHARE_A_SALE_COOKIE_NAME,
      '',
    );
    let ssClickId;
    if (shareASaleCookie) {
      const ssCookieObject = JSON.parse(shareASaleCookie);
      ssClickId = ssCookieObject?.clickId;
      signUpData.ssClickId = ssClickId;
    }

    // facebook cookie and click id
    const facebookClickCookie = get(
      cookies,
      COOKIE_NAMES.FACEBOOK_CLICK_COOKIE_NAME,
      '',
    );
    let fbclid;
    if (facebookClickCookie && facebookClickCookie.split('.').length > 3) {
      fbclid = facebookClickCookie.split('.')[3];
      signUpData.fbclid = fbclid;
    }

    if (refId) {
      signUpData.refId = refId;
    }

    signUpData.track_id = cookies.hubspotutk || '';
    signUpData.deviceSignature = deviceSignature;

    if (formsExp) {
      signUpData.formsExp = formsExp;
    }
    const experimentGbbCookie =
      get(cookies, COOKIE_NAMES.EXPERIMENT_GBB, null) || null;

    try {
      const {
        data: { signUpOwnerSocial: ownerResponse },
      } = await signup({
        variables: {
          ...signUpData,
          ...additionalSignupData,
          signup_test: currentTest,
          onboarding_personalize_flow,
          sso_service: ssoService,
          signup_tracking: getSignupTrackingCookie(),
          experiment_signup_cookies: [
            ...(experimentGbbCookie
              ? [
                  {
                    name: Experiments.GoodBetterBest.name,
                    value: experimentGbbCookie,
                  },
                ]
              : []),
          ],
        },
      });

      if (refId) {
        localStorageService.removeItem('ref');
      }

      signupGaTracking(ownerResponse);

      if (impactClickId) {
        signupImpactTracking(ownerResponse);
      }

      segmentTracking('account_created', {
        Klaviyo: 'true',
      });

      login(ownerResponse.token);
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`, {});
      showErrorMessage(parsedError.message);
    }
  };

  return (
    <div className={cx(styles.container, className)}>
      <GoogleLogin
        onSuccess={socialSignUp}
        onFailure={() => {
          showErrorMessage('An error occurred while logging in with Google');
        }}
        onClick={() => {
          segmentTracking('google_signup clicked', {
            location: segmentLocation || 'Sign Up Flow',
          });
        }}
        type="signup"
        className={styles.googleButton}
      />

      <FaceBookLogin
        onSuccess={socialSignUp}
        onClick={() => {
          segmentTracking('facebook_signup clicked', {
            location: segmentLocation || 'Sign Up Flow',
          });
        }}
        onFailure={() => {
          showErrorMessage('An error occurred while signing up with Facebook');
        }}
        type="signup"
      />
    </div>
  );
};

SocialSignup.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  className: PropTypes.string,
  onboarding_personalize_flow: PropTypes.string,
  ssoService: PropTypes.string,
  additionalSignupData: PropTypes.object,
  segmentLocation: PropTypes.string,
};

export default withRouter(SocialSignup);
