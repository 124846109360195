import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';

const divisionTexts = {
  cookCounty: (
    <>
      <li>There is a 5 day grace period before a late fee may be charged.</li>
      <li>
        Late fee can be $20 or 20% of the late amount due, whichever is greater.{' '}
      </li>
    </>
  ),
  chicago: (
    <>
      <li>
        There is no state law on a mandatory grace period, but 5 days is common.
      </li>
      <li>
        The late fee maximum is $10 per month for the first $500 in monthly
        rent, plus 5% per month in excess of $500 rent amount due.
      </li>
    </>
  ),
};

const LateFeeInfo = ({ className, fullStateName, leaseAgreementSelection }) => (
  <InlineAlert className={className} title={fullStateName}>
    <ul>
      {divisionTexts[leaseAgreementSelection] || (
        <>
          <li>
            There is no state law on a mandatory grace period, but 5 days is
            common.
          </li>
          <li>
            Late fees can be $20 or 20% of late amount due, whichever is
            greater.
          </li>
        </>
      )}
    </ul>
  </InlineAlert>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  fullStateName: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default LateFeeInfo;
