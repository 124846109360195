import React from 'react';
import { useLottie } from 'lottie-react';
import PropTypes from 'prop-types';

import isiOSDevice from '../../../helpers/isIosDevice';
import IframeAnimation from '../iOSIframeAnimation';

import animationPathJson from './Fireworks.animation.json';

const FireworksAnimation = ({ style = {}, options: customOptions }) => {
  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/?file=8142f5e7-1f91-4803-b19f-36f0d5ae24f9/aMM7oUeHE8.json"
      />
    );
  }

  const options = {
    path: animationPathJson,
    loop: true,
    autoplay: true,
    ...customOptions,
  };

  const LoaderData = useLottie(options, style);
  return LoaderData.View;
};

FireworksAnimation.propTypes = {
  style: PropTypes.object,
  options: PropTypes.object,
};

export default FireworksAnimation;
