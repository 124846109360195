import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import BackButton from '../../../../components/BackButton';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import DateField from '../../../../components/DateField';
import FileUploadPreview from '../../../../components/FileUploadPreview';
import LoadingScreen from '../../../../components/LoadingScreen';
import { useErrorToast } from '../../../../components/Toast';
import UploadDropZone from '../../../../components/UploadDropZone';
import getValidFromDate from '../../../../helpers/get-valid-from-date';
import PartyIcon from '../../../../icons/PartyPopper';
import { useConfig } from '../../../../providers/ConfigProvider';
import { useSegmentTracker } from '../../../../providers/SegmentTrackingProvider/context';
import {
  getInsuranceProofUrl,
  uploadFileToS3,
} from '../../../../services/cloudFilesService';
import getFixedDate from '../../../../services/utilities/getFixedDate';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validEndDate from '../../../../validators/validLeaseEndDate';
import validStartDate from '../../../../validators/validLeaseStartDate';

import styles from './UploadPolicy.module.scss';

const ALLOWED_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.pdf'];

const UploadPolicy = ({
  insurance,
  history,
  match,
  loading,
  postProofOfInsurance,
  containerClassName,
  contentClassName,
  actionsClassName,
  hideBackButton,
  hideSuccessStep,
  defaultListingId,
  uploadButtonLabel = 'Finish',
}) => {
  const { PRIVATE_BASE_PATH, IS_OWNER } = useConfig();
  const [showSuccess, setShowSuccess] = useState(false);
  const { trackAction } = useSegmentTracker();
  const showErrorMessage = useErrorToast();

  const listingId = defaultListingId || get(match, 'params.listingId', '');
  const renterId = get(match, 'params.renterId', '');
  const queryParameters = queryString.parse(history.location.search);
  const editMode = queryParameters.editMode === 'true';

  const formatBEDate = (date) => {
    const dateObject = date && moment.utc(date, 'YYYY-MM-DD', true);
    return dateObject && dateObject.isValid()
      ? dateObject.format('MM/DD/YYYY')
      : null;
  };

  const uploadFiles = useCallback(
    async (needUpload) => {
      const files = await Promise.all(
        needUpload.map(async (file) => {
          const urlResponse = await getInsuranceProofUrl(
            file.path,
            renterId,
            listingId,
          );
          const s3Params = JSON.parse(urlResponse);
          await uploadFileToS3(s3Params.url, file);
          return {
            name: file.name,
            url: `${s3Params?.url?.endpoint_url}/${s3Params?.url?.params?.key}`,
          };
        }),
      );
      return files;
    },
    [listingId, renterId],
  );

  const getCallbackUrl = () => {
    if (IS_OWNER) {
      if (renterId) {
        return `${PRIVATE_BASE_PATH}tenant-profile/${renterId}`;
      }
      return '/';
    }
    return queryParameters.callbackUrl;
  };

  const getTitle = () => {
    if (editMode) {
      return 'Edit your proof of insurance.';
    }
    return IS_OWNER
      ? 'Upload proof of their renters insurance policy.'
      : 'Please upload proof of renters insurance policy.';
  };

  const getSubTitle = () => {
    if (editMode) {
      return 'Since your landlord requires renters insurance, make sure this is your most up-to-date policy.';
    }
    return IS_OWNER
      ? 'You can upload a PDF, scanned image or simply a photo of their renters insurance policy document.'
      : 'You can upload a PDF, scanned image or simply a photo of your renters insurance policy document in order to prove to the landlord that you have renters insurance.';
  };

  const getSuccessDescription = () => {
    if (IS_OWNER) {
      return "Now you can rest easy knowing you're covered if something happens.";
    }
    return 'We notified your landlord that you have renters insurance.';
  };

  const onSubmit = async (data) => {
    const preparedData = {
      startDate: getFixedDate(data.start_date),
      endDate: getFixedDate(data.end_date),
      listingId: listingId,
      renterId: renterId,
      fileName: get(data, 'file[0].name') || '',
    };

    try {
      trackAction('upload');
      await postProofOfInsurance(preparedData);
      if (editMode) {
        history.push(getCallbackUrl());
      }
      setShowSuccess(true);
    } catch (e) {
      console.error('Bad request', e);
      showErrorMessage(`An error occurred`);
      // return;
    }
  };

  if (!hideSuccessStep && showSuccess) {
    return (
      <Card className={styles.successContainer}>
        <PartyIcon className={styles.icon} />
        <span className={styles.title}>
          Proof of renters insurance uploaded.
        </span>
        <span className={styles.description}>{getSuccessDescription()}</span>
        <Button
          className={styles.button}
          onClick={() => history.push(getCallbackUrl())}
        >
          Sounds Good
        </Button>
      </Card>
    );
  }
  return (
    <LoadingScreen
      loading={loading}
      className={cx(styles.container, containerClassName)}
    >
      {!hideBackButton && (
        <BackButton className={styles.back} to={getCallbackUrl()} />
      )}
      <Card className={cx(styles.card, contentClassName)}>
        <Form
          keepDirtyOnReinitialize
          onSubmit={onSubmit}
          initialValues={{
            start_date: editMode
              ? formatBEDate(insurance?.policy_start_at)
              : undefined,
            end_date: editMode
              ? formatBEDate(insurance?.policy_end_at)
              : undefined,
            file: editMode
              ? [
                  {
                    url: insurance?.proof_url,
                    name: insurance?.proof_url
                      ? insurance?.proof_url.split('/').pop()
                      : 'insurance_policy',
                  },
                ]
              : undefined,
          }}
          subscription={{
            submitting: true,
            values: true,
            pristine: true,
          }}
        >
          {({ handleSubmit, submitting, values, pristine }) => (
            <form onSubmit={handleSubmit} className={styles.content}>
              <span className={styles.title}>{getTitle()}</span>
              <span className={styles.subtitle}>{getSubTitle()}</span>
              <div className={styles.dates}>
                <Field
                  className={styles.date}
                  label="Policy Start Date"
                  component={DateField}
                  name="start_date"
                  id="start_date"
                  validate={composeValidators(fieldRequired, validStartDate)}
                />
                <Field
                  className={styles.date}
                  label="Policy End Date"
                  component={DateField}
                  name="end_date"
                  id="end_date"
                  validate={composeValidators(fieldRequired, validEndDate)}
                  fromDate={getValidFromDate(values.start_date)}
                />
              </div>
              {IS_OWNER && (
                <span className={styles.paragraph}>
                  You'll always know you're covered since we'll remind you when
                  their policy is going to expire.
                </span>
              )}
              {values?.file?.length ? (
                <Field
                  className={styles.file}
                  component={FileUploadPreview}
                  withLinks
                  id="uploaded_file"
                  name="file"
                />
              ) : (
                <Field
                  component={UploadDropZone}
                  id="dropzone_file"
                  name="file"
                  accept={ALLOWED_EXTENSIONS.join(',')}
                  className={styles.dropzone}
                  uploadFiles={uploadFiles}
                />
              )}
              {values?.file?.length ? (
                <div className={cx(styles.buttons, actionsClassName)}>
                  {editMode && (
                    <Button
                      className={cx(styles.button, styles.close)}
                      secondary
                      onClick={() => {
                        trackAction('cancel');
                        history.push(getCallbackUrl());
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    className={styles.button}
                    type="submit"
                    disabled={pristine}
                    loading={submitting}
                    id="save_policy_proof"
                  >
                    {editMode ? 'Save' : uploadButtonLabel}
                  </Button>
                </div>
              ) : null}
            </form>
          )}
        </Form>
      </Card>
    </LoadingScreen>
  );
};

UploadPolicy.propTypes = {
  insurance: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  postProofOfInsurance: PropTypes.func,
  hideBackButton: PropTypes.bool,
  hideSuccessStep: PropTypes.bool,
  defaultListingId: PropTypes.string,
  containerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  actionsClassName: PropTypes.string,
  uploadButtonLabel: PropTypes.string,
};

export default UploadPolicy;
