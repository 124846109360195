import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import createExternalApplicationMutation from '../../../graphql/mutations/createExternalApplication.graphql';
import addProperyMutation from '../../../graphql/mutations/listings/addProperty.graphql';
import useNavigator from '../../../hooks/useNavigator';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import useSignupMutation from '../../auth/signup/useSignupMutation';
import { preparePayloadPropertyDetailsPublicLA } from '../../digital-leases/standalone-wizard/StandaloneLeaseAgreement/preparePayloadPropertyDetailsPublicLA';
import UnsavedChangesPrompt from '../../digital-leases/standalone-wizard/UnsavedChangesPrompt';
import ScreenATenantStep from '../ScreenATenantStep';
import ScreeningReportTypeStep from '../ScreeningReportTypeStep';
import SignUpStep from '../SignUpStep';

const steps = ['screening', 'signup', 'screening-type'];

const getMailingAddress = (data) => {
  const mailingAddress = get(data, 'mailingAddress', '');
  const mailingCity = get(data, 'mailingCity', '');
  const mailingState = get(data, 'mailingState', '');
  const mailingUnit = get(data, 'mailingUnit', '');
  const mailingZip = get(data, 'mailingZip', '');

  return {
    address: mailingAddress,
    city: mailingCity,
    state: mailingState,
    unit: mailingUnit,
    zip: mailingZip,
  };
};

const PublicScreeningReport = ({ location, match }) => {
  const { PRIVATE_BASE_PATH, DEFAULT_APPLICATION_FEE } = useConfig();
  const navigate = useNavigator();
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const signup = useSignupMutation(location);

  const [addProperty] = useMutation(addProperyMutation);
  const [createExternalApplication] = useMutation(
    createExternalApplicationMutation,
  );

  const me = match.path.split('/:')[0];

  useEffect(() => {
    if (match.params.step === steps[0]) {
      setData({});
    } else if (!data.first_name) {
      navigate(`${me}/${steps[0]}`, { preserveParams: true });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.step]);

  const handleSaveData = (values) => {
    setData({
      ...data,
      ...values,
    });
  };

  const handleError = (error) => {
    const parsedError = error?.graphQLErrors?.[0];

    if (parsedError?.code === 471) {
      window.atatus?.notify({
        message: 'Public Screening Report: Email already in use.',
        error,
      });

      return {
        [FORM_ERROR]: parsedError.message,
      };
    }

    if (parsedError?.code === 500) {
      return {
        [FORM_ERROR]: 'Something went wrong, please try again',
      };
    }

    const message = parsedError?.message || 'Something went wrong';
    return {
      [FORM_ERROR]: message,
    };
  };

  const onNext = (values = {}) => {
    handleSaveData(values);

    if (steps.indexOf(match.params.step) === steps.length - 1) {
      return;
    }

    navigate(`${me}/${steps[steps.indexOf(match.params.step) + 1]}`, {
      preserveParams: true,
    });
  };

  const handleSignup = async (userData) => {
    setSubmitted(true);
    try {
      const mailing_address = getMailingAddress(data);

      await signup({
        ...userData,
        mailing_address,
        process: 'PUBLIC_SCREENING',
      });

      segmentTracking('account_creation success', {
        location: 'Screen a Tenant - Public Screening',
      });

      handleSaveData({ userData });
    } catch (err) {
      return handleError(err);
    }

    try {
      const property = await addProperty({
        variables: {
          inputData: preparePayloadPropertyDetailsPublicLA(data),
          autocreateDraftLease: false,
        },
      });
      segmentTracking('property created', {
        location: 'Onboarding Add Rental Property',
        property_type: data.property_type,
        process: 'PUBLIC_SCREENING',
      });
      const listing = get(property, 'data.addProperty.listing') || null;

      onNext({ listing });
    } catch (err) {
      return handleError(err);
    }
  };

  const handleSuccessRedirect = async ({ landlord_pays }) => {
    try {
      const {
        listing,
        owner_telephone,
        email,
        first_name,
        last_name,
        telephone,
        inviteType: invite_type,
      } = data;
      const res = await createExternalApplication({
        variables: {
          listingId:
            listing.children_listings?.[0]?.children_listings?.[0]?.id ||
            listing.children_listings?.[0]?.id ||
            listing.id,
          mailing_address: getMailingAddress(data),
          owner_telephone,
          email,
          first_name,
          last_name,
          telephone,
          invite_type: invite_type.toUpperCase(),
          landlord_pays,
        },
      });
      const rrId = get(
        res,
        'data.createExternalRentalRequest.rentalRequest.id',
      );

      if (landlord_pays) {
        navigate(
          `${PRIVATE_BASE_PATH}externalapplication/payment?rrid=${encodeURIComponent(
            rrId,
          )}`,
          { preserveParams: true },
        );
      } else {
        navigate(
          `${PRIVATE_BASE_PATH}externalapplication/lease?rrid=${encodeURIComponent(
            rrId,
          )}`,
          { preserveParams: true },
        );
      }
    } catch (err) {
      return handleError(err);
    }
  };

  return (
    <>
      <UnsavedChangesPrompt
        when={match.params.step !== steps[0] && !submitted}
      />
      <Switch>
        <Route
          path={`${me}/screening`}
          render={() => (
            <ScreenATenantStep onContinue={onNext} initialValues={data || {}} />
          )}
        />
        <Route
          path={`${me}/signup`}
          render={(routeProps) => (
            <SignUpStep onNext={handleSignup} {...routeProps} />
          )}
        />
        <Route
          path={`${me}/screening-type`}
          render={() => (
            <ScreeningReportTypeStep
              onSubmit={handleSuccessRedirect}
              applicationFee={DEFAULT_APPLICATION_FEE}
            />
          )}
        />
      </Switch>
    </>
  );
};

PublicScreeningReport.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default PublicScreeningReport;
