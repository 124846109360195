import React from 'react';

import Tag from '../../../../../components/Tag';
import { H1, Paragraph } from '../../../../../components/Typography';
import colors from '../../../../../constants/colors';
import Academy from '../../../../../icons/Academy';
import Messages from '../../../../../icons/Messages';
import Video from '../../../../../icons/Video';
import { segmentTracking } from '../../../../../services/utilities';
import LoadSSOServiceModal, {
  useOpenSSOService,
} from '../../../LoadSSOServiceModal';
import academyBuildingImg from '../../assets/new-academy-header.svg';

import styles from './Header.module.scss';

const Header = () => {
  const [isForumLoading, openForum] = useOpenSSOService('forum');

  const [isAcademyLoading, openAcademy] = useOpenSSOService('academy');

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerSection}>
          <div className={styles.graphic}>
            <img src={academyBuildingImg} />
          </div>
          <div className={styles.content}>
            <H1 className={styles.title}>
              A <b>community</b>, built for landlords like <b>you</b>.
            </H1>
            <Paragraph size="large">
              Reach the next stage of your investing journey with a little help
              from your friends, and with the best free educational resources a
              landlord could ask for.
            </Paragraph>
          </div>
        </div>
        <div className={styles.navigationCards}>
          <a
            className={styles.navigationCard}
            onClick={(e) => {
              e.preventDefault();
              openForum();
              segmentTracking({
                event: 'forum clicked',
                properties: { location: 'Community Page' },
              });
            }}
            href="#"
            rel="noreferrer"
          >
            <Tag className={styles.tag}>New</Tag>
            <div className={styles.navigationCardContent}>
              <Messages
                className={styles.icon}
                color={colors.text}
                width={52}
                height={52}
              />
              <span className={styles.label}>Forum</span>
            </div>
          </a>

          <a
            className={styles.navigationCard}
            onClick={(e) => {
              e.preventDefault();
              openAcademy();
              segmentTracking({
                event: 'academy clicked',
                properties: { location: 'Community Page' },
              });
            }}
            href="#"
            rel="noreferrer"
          >
            <div className={styles.navigationCardContent}>
              <Academy
                className={styles.icon}
                color={colors.text}
                width={52}
                height={52}
              />
              <span className={styles.label}>Academy</span>
            </div>
          </a>

          <a
            className={styles.navigationCard}
            href="https://turbotenant.com/education/webinars"
            target="_blank"
            onClick={() =>
              segmentTracking({
                event: 'webinars clicked',
                properties: { location: 'Community Page' },
              })
            }
            rel="noreferrer"
          >
            <div className={styles.navigationCardContent}>
              <Video
                className={styles.icon}
                color={colors.text}
                width={52}
                height={52}
              />
              <span className={styles.label}>Webinars</span>
            </div>
          </a>
        </div>
      </div>
      <LoadSSOServiceModal
        open={isAcademyLoading || isForumLoading}
        ssoService={isForumLoading ? 'forum' : 'academy'}
      />
    </>
  );
};

export default Header;
