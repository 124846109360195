import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import FinalFormError from '../../../components/FinalFormError';
import FlatButton from '../../../components/FlatButton';
import Loader from '../../../components/Loader';
import Scheduler from '../../../components/Scheduler';
import SelectionRadioBoxGroup from '../../../components/SelectionRadioBoxGroup';
import { H2, Paragraph } from '../../../components/Typography';
import { Experiments } from '../../../constants/experiments';
import getExperimentVariant from '../../../helpers/experiments';
import ApplicantIcon from '../../../icons/onboarding/Applicant';
import DollarSlimIcon from '../../../icons/onboarding/DollarCircle';
import LeaseIcon from '../../../icons/onboarding/Leases';
import MarketingSlimIcon from '../../../icons/onboarding/Marketing';
import ScreeningSlimIcon from '../../../icons/onboarding/Screening';
import userPropType from '../../../prop-types/user';
import { segmentTracking } from '../../../services/utilities';

import styles from './ProcessForm.module.scss';

const Process = ({
  handleAllOfThem,
  onSubmit,
  isBroker,
  isHandlingAll,
  initialValues,
  user,
}) => {
  useEffect(() => {
    segmentTracking('onboarding process step reached', {
      location: 'Onboarding',
    });
  }, []);

  const experiment = getExperimentVariant(
    user,
    Experiments.CxScheduleProcess.name,
  );

  const isExperimentActive =
    experiment === Experiments.CxScheduleProcess.variants.test;

  if (!user) return <Loader />;

  return (
    <div className={styles.container}>
      <Paragraph className="mb-4" weight="semi">
        Welcome, {user?.first_name} 👋
      </Paragraph>
      <H2 className="mb-4">Where would you like to start?</H2>
      <Paragraph className="mb-16">
        Get started in only a few minutes!
      </Paragraph>
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...initialValues }}
        subscription={{
          submitting: true,
          values: true,
        }}
      >
        {({ form, handleSubmit, submitting, values }) => {
          const selectedProcess = get(values, 'process', null);
          return (
            <form onSubmit={handleSubmit}>
              <FinalFormError />
              <Field
                component={SelectionRadioBoxGroup}
                vertical
                id="process"
                name="process"
                className={cx(styles.radioGroup, {
                  [styles.brokerRadioGroup]: isBroker,
                })}
                containerClassName={isBroker ? styles.brokersContainer : null}
                options={[
                  ...(!isBroker
                    ? [
                        {
                          icon: MarketingSlimIcon,
                          value: 'MARKETING',
                          label: 'Marketing',
                          labelClassName: styles.optionLabel,
                          className: styles.marketingIcon,
                          id: 'button-id-MARKETING',
                          dataQa: 'onboarding-choice-marketing',
                        },
                      ]
                    : []),
                  {
                    icon: ApplicantIcon,
                    value: 'RECEIVE_APPLICATION',
                    label: 'Application',
                    labelClassName: styles.optionLabel,
                    className: styles.applicantIcon,
                    id: 'button-id-RECEIVE_APPLICATION',
                    dataQa: 'onboarding-choice-receive-application',
                  },
                  {
                    icon: ScreeningSlimIcon,
                    value: 'SCREEN_TENANT',
                    label: 'Screening',
                    labelClassName: styles.optionLabel,
                    className: styles.screeningIcon,
                    id: 'button-id-SCREEN_TENANT',
                    dataQa: 'onboarding-choice-screen-tenant',
                  },
                  {
                    icon: DollarSlimIcon,
                    value: 'RENT_PAYMENTS',
                    label: 'Rent Collection',
                    labelClassName: styles.optionLabel,
                    className: styles.dollarIcon,
                    id: 'button-id-RENT_PAYMENTS',
                    dataQa: 'onboarding-choice-rent-payments',
                  },
                  {
                    icon: LeaseIcon,
                    value: 'LEASE',
                    label: 'Lease',
                    labelClassName: styles.optionLabel,
                    className: styles.leaseIcon,
                    id: 'button-id-LEASE',
                    dataQa: 'onboarding-choice-lease',
                  },
                ]}
                radioProps={{
                  className: !isBroker ? styles.radio : styles.brokerRadio,
                }}
              />
              {!isBroker && (
                <div className={styles.allAction}>
                  <FlatButton
                    className={styles.allButton}
                    loading={isHandlingAll}
                    onClick={() => {
                      form.change('process', 'ALL');
                      handleAllOfThem();
                    }}
                    data-qa="onboarding-choice-all"
                  >
                    ALL OF THE ABOVE!
                  </FlatButton>
                </div>
              )}
              <div className={styles.action}>
                <Button
                  className={styles.submit}
                  type="submit"
                  id="onboarding-continue-button"
                  loading={submitting}
                  disabled={!selectedProcess || isHandlingAll}
                  data-qa="onboarding-continue-button"
                >
                  Continue
                </Button>
              </div>
              {isExperimentActive && <Scheduler />}
            </form>
          );
        }}
      </Form>
    </div>
  );
};

Process.propTypes = {
  isBroker: PropTypes.bool,
  handleAllOfThem: PropTypes.func,
  isHandlingAll: PropTypes.bool,
  onSubmit: PropTypes.func,
  user: userPropType,
  initialValues: PropTypes.object,
};

export default Process;
