import { intersection, uniq } from 'lodash';

import PAYMENT_OPTIONS from '../../../../constants/lease_agreement/payment-options';
import convertDollarsToCents from '../../../../helpers/convert-dollars-to-cents';

export const preparePayloadLeaseAgreementPublicLA = ({
  lease_agreement_details,
  property_details,
  lease_agreement_selection,
  currentState,
}) => {
  // Section lease specifics - payload manipulation
  const leaseSpecificsCustomData = {
    // We inherit the property details from the previous step
    property_address: property_details.address,
    property_city: property_details.city,
    property_state: property_details.state,
    property_zip: property_details.zip,
    ...(property_details.unit
      ? {
          property_unit: property_details.unit,
        }
      : {}),
  };

  // Section rent deposit fee - keys to delete
  delete lease_agreement_details.lease_term;

  // Section rent deposit fee - payload manipulation
  const otherPaymentMethodAdded =
    lease_agreement_details?.accepted_payments_other?.includes('other');

  // One time fees has initial value, need to remove it than process it
  const processedFees = lease_agreement_details.one_time_fees
    .map(({ name, amount }) => ({
      name,
      amount: convertDollarsToCents(amount),
    }))
    .filter(({ name, amount }) => name && amount && !Number.isNaN(amount));
  delete lease_agreement_details.one_time_fees;

  const rentDepositFeeCustomData = {
    ...(lease_agreement_details.base_rent_amount
      ? {
          base_rent_amount: convertDollarsToCents(
            lease_agreement_details.base_rent_amount,
          ),
        }
      : {}),
    ...(lease_agreement_details.monthly_pet_rent_amount
      ? {
          monthly_pet_rent_amount: convertDollarsToCents(
            lease_agreement_details.monthly_pet_rent_amount,
          ),
        }
      : {}),
    ...(lease_agreement_details.prorated_rent_amount
      ? {
          prorated_rent_amount: convertDollarsToCents(
            lease_agreement_details.prorated_rent_amount,
          ),
        }
      : {}),
    ...(lease_agreement_details.security_deposit_amount
      ? {
          security_deposit_amount: convertDollarsToCents(
            lease_agreement_details.security_deposit_amount,
          ),
        }
      : {}),
    ...(lease_agreement_details.other_deposit_amount
      ? {
          other_deposit_amount: convertDollarsToCents(
            lease_agreement_details.other_deposit_amount,
          ),
        }
      : {}),
    ...(lease_agreement_details.pet_deposit_amount
      ? {
          pet_deposit_amount: convertDollarsToCents(
            lease_agreement_details.pet_deposit_amount,
          ),
        }
      : {}),
    deposit_bank_name: lease_agreement_details.deposit_bank_name,
    deposit_bank_address: lease_agreement_details.deposit_bank_address,

    ...(processedFees?.length ? { one_time_fees: processedFees } : {}),

    // This whole code bellow should be done differently in form so this is more simple
    ...(lease_agreement_details.accepted_payments?.length &&
    lease_agreement_details.accepted_payments_other?.length
      ? {
          accepted_payments: uniq([
            ...intersection(
              [
                ...lease_agreement_details.accepted_payments,
                ...lease_agreement_details.accepted_payments_other,
              ],
              PAYMENT_OPTIONS,
            ),
            otherPaymentMethodAdded
              ? lease_agreement_details.other_payments
              : null,
          ]).filter(Boolean),
        }
      : {}),
  };

  // Section people on the lease - keys to delete
  delete lease_agreement_details.pet_rent_choice;
  delete lease_agreement_details.prorated_rent_choice;
  delete lease_agreement_details.pet_deposit_choice;
  delete lease_agreement_details.one_time_fee_choice;
  delete lease_agreement_details.accepted_payments_other;
  delete lease_agreement_details.other_payments;
  delete lease_agreement_details.skip_tenants;

  // Section people on the lease - payload manipulation
  // Additional occupants has initial value, need to remove it than process it
  const processedAdditionalOccupants =
    lease_agreement_details.additional_occupants
      .map(({ name, age, relationship }) => ({
        name,
        age: parseInt(age, 10),
        relationship,
      }))
      .filter(({ name, age, relationship }) => name && age && relationship);
  delete lease_agreement_details.additional_occupants;

  const peopleOnTheLeaseCustomData = {
    ...(lease_agreement_details.tenants?.length
      ? {
          tenants: lease_agreement_details.tenants.map((tenant) => ({
            id: tenant.id,
            first_name: tenant.first_name,
            last_name: tenant.last_name,
            phone: tenant.phone || tenant.telephone,
            email: tenant.email,
            invite_type: tenant.invite_type,
          })),
        }
      : {}),
    ...(processedAdditionalOccupants?.length
      ? { additional_occupants: processedAdditionalOccupants }
      : {}),
  };

  // Section people on the lease - keys to delete
  delete lease_agreement_details.tenants_address_choice;
  delete lease_agreement_details.landlord_choice;
  delete lease_agreement_details.hasAdditionalLandlord;
  delete lease_agreement_details.additional_occupants_choice;
  delete lease_agreement_details.additional_signers_choice;
  delete lease_agreement_details.additional_cosigners_choice;

  // Section pets, smoking & insurance - payload manipulation
  // Pets has initial value, need to remove it than process it
  const processedPets = lease_agreement_details.pets
    .map(({ type, breed, weight, age }) => ({
      type,
      breed,
      weight: parseInt(weight, 10),
      age: parseInt(age, 10),
    }))
    .filter(({ type, breed, weight, age }) => type && breed && weight && age);
  delete lease_agreement_details.pets;

  const petsSmokingOtherCustomData = {
    ...(processedPets?.length ? { pets: processedPets } : {}),
  };

  // Section pets, smoking & insurance - keys to delete
  delete lease_agreement_details.pets_choice;
  delete lease_agreement_details.has_parking_rules;
  delete lease_agreement_details.parking_types;
  delete lease_agreement_details.parking_other;
  delete lease_agreement_details.parking_designated_space;
  delete lease_agreement_details.parking_rules;

  // Section utilities, services & keys - payload manipulation
  const utilitiesCustomData = {
    ...(lease_agreement_details.keys?.length
      ? {
          key_copies: lease_agreement_details.keys.map(
            ({ type, copies, otherType }) => {
              return {
                ...(type ? { type } : { type: 'Property' }),
                ...(type === 'OTHER' && {
                  type: otherType,
                }),
                copies: parseInt(copies, 10),
              };
            },
          ),
        }
      : {}),
  };

  // Section utilities, services & keys - keys to delete
  delete lease_agreement_details.keys;
  delete lease_agreement_details.utilities_devided_as_follows;

  // Section provisions & attachments

  // Section provisions & attachments - keys to delete
  delete lease_agreement_details.has_lead_based_hazards;
  delete lease_agreement_details.has_lead_based_records;
  delete lease_agreement_details.radon_hazard;
  delete lease_agreement_details.radon_hazard_explanation;

  // Tweaks for the custom state data
  const customStateData = {
    ...lease_agreement_details.custom_state_data,
    additional_signers_choice:
      lease_agreement_details?.custom_state_data?.additional_signers_choice,
    additional_signers: lease_agreement_details?.custom_state_data
      ?.additional_signers
      ? lease_agreement_details?.custom_state_data?.additional_signers
          .filter((signer) => signer.email !== '')
          .map(({ editing, oldState, __typename, ...signer }) => signer)
      : [],
    additional_cosigners: lease_agreement_details?.custom_state_data
      ?.additional_cosigners
      ? lease_agreement_details?.custom_state_data?.additional_cosigners
          .filter((signer) => signer.email !== '')
          .map(({ editing, oldState, __typename, ...signer }) => signer)
      : [],
  };

  return {
    ...(lease_agreement_selection !== currentState
      ? {
          lease_agreement_selection: lease_agreement_selection,
        }
      : {}),
    ...lease_agreement_details,
    ...leaseSpecificsCustomData,
    ...rentDepositFeeCustomData,
    ...peopleOnTheLeaseCustomData,
    ...petsSmokingOtherCustomData,
    ...utilitiesCustomData,
    custom_state_data: customStateData,
  };
};
