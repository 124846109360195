import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../../../../constants/lease_agreement/sub-division-names';

const LateFeeInfo = ({ className, leaseAgreementSelection, fullStateName }) => {
  let lateFeeText;
  switch (leaseAgreementSelection) {
    case SUB_DIVISION_NAMES_KEYS.montgomery:
    case SUB_DIVISION_NAMES_KEYS.baltimore:
      lateFeeText = (
        <>
          <li>
            There is a 10 day grace period before a late fee may be charged.
          </li>
          <li>Late fees must not exceed 5% of the late amount due.</li>
        </>
      );
      break;
    default:
      lateFeeText = (
        <>
          <li>
            There is no state law on a mandatory grace period, but 5 days is
            common.{' '}
          </li>
          <li>Late fees must not exceed 5% of the late amount due.</li>
        </>
      );
  }

  return (
    <InlineAlert className={className} title={fullStateName}>
      {lateFeeText}
    </InlineAlert>
  );
};

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
  fullStateName: PropTypes.string,
};

export default LateFeeInfo;
