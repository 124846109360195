import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import get from 'lodash.get';

import RentersInsuranceGraphic from '../../../../assets/renters-insurance.png';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import FlatButton from '../../../../components/FlatButton';
import getRentInsuranceDaysLeft from '../../../../components/RentersDashboard/helpers/getRentersInsuranceDaysLeft';
import { H2, Paragraph } from '../../../../components/Typography';
import { useUserProfile } from '../../../../core/TTgraphql';
import { linkTo, routes } from '../../../../helpers/routes';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities';
import { getActiveLeaseForRenter } from '../../../../services/utilities/getActiveLeaseForRenter';
import OnboardingProgress from '../../components/OnboardingProgress';

import styles from './RentersInsurance.module.scss';

const getDayForm = (days) => (days === 1 ? '1 day' : `${days} days`);

const RentersInsurance = () => {
  const config = useConfig();
  const { user, loading } = useUserProfile();

  const requireInsurance = useMemo(() => {
    if (loading) return false;
    const { listings } = user;

    return get(listings, '[0].owner.require_insurance', false);
  }, [user, loading]);

  const onGetFreeQuote = useCallback(() => {
    segmentTracking('renters_insurance submit', {
      location: 'Tenant Onboarding Renters Insurance Page - Get SURE Quote',
    });
    window.open(
      `${config.RENTERS_URL.slice(0, -1)}${linkTo(routes.tenantInsuranceSetupIntro)}`,
      '_blank',
    );
  }, []);

  const { description, isUrgent } = useMemo(() => {
    if (loading) return { description: null };

    const activeLease = getActiveLeaseForRenter(user);

    const daysLeft = getRentInsuranceDaysLeft(activeLease);

    if (isNaN(daysLeft)) return { description: null };

    if (daysLeft > 0) {
      return {
        description: `due in ${getDayForm(daysLeft)}`,
        isUrgent: false,
      };
    } else if (daysLeft === 0) {
      return {
        description: 'due today',
        isUrgent: true,
      };
    }
    return {
      description: `${getDayForm(Math.abs(daysLeft))} past due`,
      isUrgent: true,
    };
  }, [loading, user]);

  if (loading) return null;

  return (
    <>
      <OnboardingProgress step="Renters Insurance" progress={40} />

      <Card padded className={styles.container}>
        <Card.Content className={styles.content}>
          <img
            src={RentersInsuranceGraphic}
            alt="Renters Insurance Promo Image"
            className={styles.placeholderImage}
          />
          {requireInsurance ? (
            <>
              <H2 align="center" className="mb-4">
                Renters insurance is required
                {description && (
                  <>
                    :{' '}
                    <span
                      className={cx(styles.span, { [styles.urgent]: isUrgent })}
                    >
                      {description}
                    </span>
                  </>
                )}
              </H2>
              <Paragraph align="center" size="large" className="mb-24">
                Upload proof or purchase affordable coverage through TurboTenant
                to avoid lease violations.
              </Paragraph>
            </>
          ) : (
            <>
              <H2 align="center" className="mb-4">
                Protect yourself with Renters Insurance
              </H2>
              <Paragraph align="center" size="large" className="mb-24">
                Get a free quote from our partner, SURE. Upload proof if you
                already have renters insurance.
              </Paragraph>
            </>
          )}
        </Card.Content>
        <Card.Footer>
          <Button
            variant="primary"
            className={styles.button}
            onClick={onGetFreeQuote}
            to={linkTo(routes.tenantOnboardingStepDownloadApp)}
          >
            Get Free Quote
          </Button>
          <Button
            variant="secondary"
            className={styles.button}
            to={linkTo(routes.tenantOnboardingStepRentersInsuranceUpload)}
            onClick={() => {
              segmentTracking('renters_insurance submit', {
                location:
                  'Tenant Onboarding Renters Insurance Page - Upload Proof',
              });
            }}
          >
            Upload Proof
          </Button>
          <FlatButton
            to={linkTo(routes.tenantOnboardingStepDownloadApp)}
            onClick={() => {
              segmentTracking('renters_insurance submit', {
                location: 'Tenant Onboarding Renters Insurance Page - Skip',
              });
            }}
          >
            Skip for now
          </FlatButton>
        </Card.Footer>
      </Card>
    </>
  );
};

export default RentersInsurance;
