import React, { useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SideBarReviews from '../../../../components/SideBarReviews';
import { getItem, setItem } from '../../../../services/localStorageService';
import { segmentTracking } from '../../../../services/utilities';

import HowDidYouHearStandAlone from './FlowOptions/HowDidYouHearStandAlone';
import HowLongStandAlone from './FlowOptions/HowLongStandAlone';
import PersonalizeAsFirstStep from './FlowOptions/PersonalizeAsFirstStep';
import ProcessStandAlone from './FlowOptions/ProcessStandAlone';
import PropertiesStandAlone from './FlowOptions/PropertiesStandAlone';
import SideBarMarketingSignUp from './FlowOptions/SideBarMarketingSignUp';
import SignupDescriptionStandAlone from './FlowOptions/SignupDescriptionStandAlone';
import {
  TestContext,
  useAdditionalSignupData,
  useSetAdditionalSignupData,
  useSetLayoutControll,
} from './TestProvider';
export const SIGNUP_TEST_ONBOARDING = 'signup_test_onboarding';

//possible tests ['control_version' ,'test_version_2' ,'test_version_4'] add them to testsOptions
const testsOptions = ['test_version_3', 'gt_p_signup_test'];

const renderOptions = {
  control_version: {
    steps: [
      {
        path: 'final',
        hideUserOption: false,
        hideSignupForm: false,
      },
    ],
  },
  test_version_2: {
    onboarding_personalize_flow: true,
    steps: [
      {
        path: 'final',
        hideUserOption: false,
        hideSignupForm: false,
      },
    ],
  },
  test_version_3: {
    onboarding_personalize_flow: true,
    steps: [
      {
        path: 'personalize',
        title: 'Let’s personalize your experience',
        hideUserOption: false,
        hideSignupForm: true,
        hideHR: true,
        component: PersonalizeAsFirstStep,
      },
      {
        sideBar: 'SideBarReviews',
        sideBarTop: true,
        path: 'final',
        hideHR: true,
        hideUserOption: true,
        hideSignupForm: false,
      },
    ],
  },
  gt_p_signup_test: {
    onboarding_personalize_flow: true,
    steps: [
      {
        path: 'personalize',
        title: 'Let’s personalize your experience',
        hideUserOption: false,
        hideSignupForm: true,
        hideHR: true,
        component: PersonalizeAsFirstStep,
      },
      {
        sideBar: 'SideBarReviews',
        sideBarTop: true,
        path: 'final',
        hideHR: true,
        hideUserOption: true,
        hideSignupForm: false,
      },
    ],
  },
  test_version_4: {
    onboarding_personalize_flow: true,
    initialState: {
      hideHR: true,
      hideUserOption: false,
      hideSignupForm: true,
      title: 'I am a:',
    },
    steps: [
      {
        path: 'signup-description',
        hideUserOption: true,
        hideHR: true,
        hideSignupForm: true,
        title: '',
        component: SignupDescriptionStandAlone,
      },
      {
        path: 'how-long',
        hideUserOption: true,
        hideHR: true,
        hideSignupForm: true,
        title: '',
        component: HowLongStandAlone,
      },
      {
        path: 'how-many-properties',
        hideUserOption: true,
        hideHR: true,
        hideSignupForm: true,
        title: '',
        component: PropertiesStandAlone,
      },
      {
        path: 'how-did-you-hear-about-us',
        hideUserOption: true,
        hideHR: true,
        hideSignupForm: true,
        title: '',
        component: HowDidYouHearStandAlone,
      },
      {
        sideBar: 'SideBarReviews',
        path: 'final',
        hideUserOption: true,
        hideHR: true,
        hideSignupForm: false,
      },
    ],
  },
  test_version_5: {
    onboarding_personalize_flow: true,
    steps: [
      {
        path: 'personalize',
        title: 'Let’s personalize your experience',
        hideUserOption: false,
        hideSignupForm: true,
        hideHR: true,
        component: PersonalizeAsFirstStep,
      },
      {
        path: 'process',
        sideBar: 'SidebarMarketing',
        hideUserOption: true,
        hideHR: true,
        hideSignupForm: true,
        title: '',
        component: ProcessStandAlone,
        widerContent: true,
        segmentOnSubmit: ({
          selectedOnboarding,
          currentTestSignUpOnboarding,
        }) => {
          segmentTracking('continue clicked', {
            location: 'Onboarding',
            experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
            onboarding_answer: selectedOnboarding,
          });
        },
      },
      {
        sideBar: 'SideBarReviews',
        sideBarTop: true,
        path: 'final',
        hideHR: true,
        hideUserOption: true,
        hideSignupForm: false,
      },
    ],
  },
  test_version_6: {
    onboarding_personalize_flow: true,
    steps: [
      {
        path: 'personalize',
        title: 'Let’s personalize your experience',
        hideUserOption: false,
        hideSignupForm: true,
        hideHR: true,
        component: PersonalizeAsFirstStep,
      },
      {
        path: 'process',
        sideBar: 'SidebarMarketing',
        hideUserOption: true,
        hideHR: true,
        hideSignupForm: true,
        title: '',
        component: ProcessStandAlone,
        widerContent: true,
        segmentOnSubmit: ({
          selectedOnboarding,
          currentTestSignUpOnboarding,
        }) => {
          segmentTracking('continue clicked', {
            location: 'Onboarding',
            experiment: `Onboarding Answer Move Up Test Aug 2023 - ${currentTestSignUpOnboarding}`,
            onboarding_answer: selectedOnboarding,
          });
        },
      },
      {
        sideBar: 'SideBarReviews',
        sideBarTop: true,
        path: 'final',
        hideHR: true,
        hideUserOption: true,
        hideSignupForm: false,
      },
    ],
  },
};

export const getCurentTest = () => {
  let currentTest = getItem(SIGNUP_TEST_ONBOARDING);

  // If there's no current test or it's not in the valid options, pick a valid one.
  // This is to address cases where users are left with an old test in their local storage.
  if (!currentTest || !testsOptions.includes(currentTest)) {
    const randomIndex = Math.floor(Math.random() * testsOptions.length);
    currentTest = testsOptions[randomIndex];
    setItem(SIGNUP_TEST_ONBOARDING, currentTest);
  }

  // If the current path is /auth/signup and the test is gt_p_signup_test, use another test
  // This logic is to be removed once gt_p_signup_test ends
  const cleanedPathname = window.location.pathname.replace(/\/$/, '');

  if (
    cleanedPathname === '/auth/signup' &&
    currentTest === 'gt_p_signup_test'
  ) {
    const validTests = testsOptions.filter(
      (test) => test !== 'gt_p_signup_test',
    );
    const randomIndex = Math.floor(Math.random() * validTests.length);
    currentTest = validTests[randomIndex];
    setItem(SIGNUP_TEST_ONBOARDING, currentTest);
  }

  return currentTest;
};

export const getTestInitalStates = () => {
  const currentTest = getCurentTest();
  const { hideUserOption, hideSignupForm, title, sideBar, sideBarTop } =
    renderOptions[currentTest]?.initialState ||
    renderOptions[currentTest]?.steps[0] ||
    {};
  return { hideUserOption, hideSignupForm, title, sideBar, sideBarTop };
};

const getCurentStepIndex = (path) => {
  const currentTest = getCurentTest();
  const steps = renderOptions?.[currentTest]?.steps || [];
  const index = steps.findIndex((step) => step.path === path);
  return index;
};

const backStep = (path) => {
  const currentTest = getCurentTest();
  const currentIndex = getCurentStepIndex(path);
  const steps = renderOptions?.[currentTest]?.steps;
  const prevStep =
    steps?.[currentIndex - 1] || renderOptions[currentTest]?.initialState;
  return prevStep;
};

export const backStepPath = (path) => {
  const prevStep = backStep(path);
  const prevStepPath = prevStep?.path;

  return prevStepPath;
};

export const isWiderContent = (path) => {
  const currentTest = getCurentTest();
  const currentIndex = getCurentStepIndex(path);
  const widerContent =
    renderOptions?.[currentTest]?.steps?.[currentIndex]?.widerContent;

  return widerContent;
};

export const backStepLayout = (path) => {
  const prevStep = backStep(path);
  const { hideUserOption, hideSignupForm, title, sideBar, hideHR, sideBarTop } =
    prevStep || {};
  return { hideUserOption, hideSignupForm, title, sideBar, hideHR, sideBarTop };
};

export const centerContent = () => {
  return getCurentTest() === 'test_version_4';
};

const TestFlow = ({ match, location: { search }, history }) => {
  const setAdditionalSignupData = useSetAdditionalSignupData();
  const currentTest = getCurentTest();
  const steps = renderOptions?.[currentTest]?.steps;
  const stepPath = match?.params?.step;
  const index = getCurentStepIndex(stepPath);
  const me = match.path.replace('/:step', '');
  const { component, segmentOnSubmit, ...layoutControls } = steps[index] || {};
  const {
    component: _,
    segmentOnSubmit: _s,
    ...nextStepLayout
  } = steps[index + 1] || {};
  const RightStep = component;

  const initialValues = useAdditionalSignupData();

  const setLayoutControll = useSetLayoutControll(layoutControls);

  const onSubmit = async (data) => {
    setAdditionalSignupData((oldState) => ({ ...oldState, ...data }));
    await setLayoutControll(nextStepLayout);
    if (segmentOnSubmit) {
      segmentOnSubmit({
        selectedOnboarding: data.process,
        currentTestSignUpOnboarding: currentTest,
      });
    }

    history.push(`${me}/${steps[index + 1]?.path}${search}`);
  };

  return RightStep ? (
    <RightStep onSubmit={onSubmit} initialValues={initialValues} />
  ) : null;
};

TestFlow.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      step: PropTypes.string,
    }),
    path: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export const FlowOptionsRoute = withRouter(
  ({ match, location: { search } }) => {
    const me = match.url;
    const currentTest = getCurentTest();
    const steps = renderOptions?.[currentTest]?.steps;

    if (!steps) return null;

    let firstStep = `${steps[0].path}${search}`;
    if (me.slice(-1) !== '/') {
      firstStep = `/${firstStep}`;
    }

    return (
      <Switch>
        <Route path={`${me}/:step`} component={TestFlow} />
        <Redirect exact from={`${me}`} to={`${me}${firstStep}`} />
      </Switch>
    );
  },
);

export const personalizeAfterSignup = () => {
  const currentTest = getCurentTest();
  return !!renderOptions[currentTest]?.onboarding_personalize_flow;
};

export const onSignupSucess = async (history, queryParams = '') => {
  history.push(`/onboarding/process${queryParams}`);
};

export const TestSidebar = ({ children }) => {
  const { layoutControl } = useContext(TestContext);
  let Component;
  const props = {};
  if (layoutControl.sideBar === 'SideBarReviews') {
    Component = SideBarReviews;
    props.alignTop = layoutControl.sideBarTop;
  }
  if (layoutControl.sideBar === 'SidebarMarketing') {
    Component = SideBarMarketingSignUp;
  }

  return Component ? <Component {...props} /> : children;
};

TestSidebar.propTypes = {
  children: PropTypes.node,
};

export const TestHR = ({ children }) => {
  const { layoutControl } = useContext(TestContext);
  return layoutControl.hideHR ? null : children;
};

TestHR.propTypes = {
  children: PropTypes.node,
};

export const TestHideForm = ({ children }) => {
  const { layoutControl } = useContext(TestContext);
  return !layoutControl?.hideSignupForm ? children : <FlowOptionsRoute />;
};

TestHideForm.propTypes = {
  children: PropTypes.node,
};

export const TestDependableShow = ({ children, isSelected }) => {
  const { layoutControl } = useContext(TestContext);
  return !layoutControl?.hideUserOption || !isSelected ? children : null;
};

TestDependableShow.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
};

export const TestTitle = ({ children }) => {
  const { layoutControl } = useContext(TestContext);
  return layoutControl?.title !== undefined ? layoutControl.title : children;
};

TestTitle.propTypes = {
  children: PropTypes.node,
};

export const BackButtonWrapper = ({ children, path }) => {
  const { setLayoutControl } = useContext(TestContext);
  const backLayout = backStepLayout(path);

  return children({ setLayout: () => setLayoutControl(backLayout) });
};

BackButtonWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
