import React from 'react';
import { Field, useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../../../../components/HelpfulInfo/HelpfulInfoListItem';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import ResourcesIcon from '../../../../../../../icons/resources';
import { useConfig } from '../../../../../../../providers/ConfigProvider';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../../../../../../validators/fieldRequiredShort';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from '../../../BankInfo.module.scss';

const BankInfo = ({ className, leaseAgreementSelection }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequiredShort = conditionalValidation(
    fieldRequiredShortStandard,
    !standAloneWizard,
  );
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  const {
    input: { value: bankInfo },
  } = useField('custom_state_data.bank_info');

  const { STATE_LEASE_HELP_ARTICLE_URL } = useConfig();

  return (
    <div className={cx(styles.container, className)}>
      <HelpfulInfoCard
        collapsable
        Icon={ResourcesIcon}
        className={styles.helpfulInfoCard}
        title={`New York${
          leaseAgreementSelection === 'city' ? ' City ' : ' '
        }Security Deposit Laws`}
        color="iceCold"
      >
        Though they're included in the lease agreement, we've highlighted some
        rules you need to know:
        <HelpfulInfoList className={styles.helpfulInfoList}>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Security deposit should not exceed 1 month's rent. However,
            additional pet deposits can be charged.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Collection of last month's rent is prohibited.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must provide a receipt to Tenant for any rent and security
            deposit payments showing the exact date of deposit, amount and
            purpose of payment.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            For buildings with 1-5 units, you need to keep security deposits in
            a separate bank account.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            For buildings with 6+ units, security deposits must be kept in a
            separate FDIC interest-bearing escrow account in New York, earning
            interest at the prevailing rate and indicating the name of bank,
            address and amount of security deposit in the lease.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must return the funds with interest earned within 14 days after
            Lease termination along with a written statement itemizing any
            lawful charges.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You can offer Tenant's interest to be returned at end of lease term,
            yearly, or credited for rent. You may deduct 1% of the interest as
            an administrative fee.
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            You must mail or deliver returned security deposit, interest and/or
            notice to Tenant at Tenant's last furnished address.
          </HelpfulInfoListItem>
          <div className={styles.learnMore}>
            <a
              href={STATE_LEASE_HELP_ARTICLE_URL}
              target="_blank noopener noreferrer"
            >
              Learn other security deposit laws
            </a>
          </div>
        </HelpfulInfoList>
      </HelpfulInfoCard>
      <Field
        component={RadioGroup}
        id="custom_state_data.bank_info"
        name="custom_state_data.bank_info"
        label="Does your property have 6 or more units?"
        radioRowClassName={styles.radioRow}
        radiosContainerClassName={styles.radioContainer}
        options={[
          {
            id: 'bank_info_yes',
            value: true,
            label: 'Yes',
          },
          {
            id: 'bank_info_no',
            value: false,
            label: 'No',
          },
        ]}
        validate={fieldRequired}
      />

      {bankInfo && (
        <>
          <p className={styles.paragraph}>
            Landlords with buildings containing 6+ units are required to place
            security deposits in a separate FDIC-insured, interest bearing
            account within New York and display the location and amount of the
            deposit to their tenants.
          </p>
          <Field
            label="Bank / Depository Name"
            className={styles.inputContainer}
            inputProps={{
              className: styles.input,
            }}
            id="deposit_bank_name"
            name="deposit_bank_name"
            component={Input}
            validate={fieldRequiredShort}
          />
          <Field
            label="Bank / Depository Address"
            className={styles.inputContainer}
            inputProps={{
              className: styles.input,
            }}
            id="deposit_bank_address"
            name="deposit_bank_address"
            component={Input}
            validate={fieldRequiredShort}
          />
        </>
      )}
    </div>
  );
};

BankInfo.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default BankInfo;
