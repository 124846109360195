import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import { get } from 'lodash.get';
import PropTypes from 'prop-types';

import AddressAutoSuggestFields from '../../../../components/AddressAutoSuggestFields';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import FinalFormError from '../../../../components/FinalFormError';
import LoadingScreen from '../../../../components/LoadingScreen';
import Modal from '../../../../components/Modal';
import PhoneField from '../../../../components/PhoneField';
import { useErrorToast } from '../../../../components/Toast';
import { Paragraph } from '../../../../components/Typography';
import colors from '../../../../constants/colors';
import ScreeningReportModalCopyEnum from '../../../../constants/enums/ScreeningReportModalCopyEnum';
import { Experiments } from '../../../../constants/experiments';
import { useUserProfile } from '../../../../core/TTgraphql';
import clearMask from '../../../../helpers/clear-mask';
import getExperimentVariant from '../../../../helpers/experiments';
import AccountFindColor from '../../../../icons/AccountFindColor';
import Caution from '../../../../icons/Caution';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validPhone from '../../../../validators/validPhone';
import updateOwnerProfileGQL from '../../../owners/profile/updateProfile.graphql';

import styles from './ScreeningReportModal.module.scss';

const ScreeningReportModal = ({
  open = false,
  loading,
  onClose,
  onRequestReport,
  modalCopy,
  /**
   * @notice
   *
   * This is a quick fix.
   *
   * User profile query is only used to
   * check if the user have a valid mailing address. When rendering this
   * component for the partners (like redfin) the user will always have
   * a valid mailing address. So we can skip the user query.
   *
   * We need to refactor this component so that all data needed is passed
   * by the parent component.
   *
   * I suggest we do that as part of the work to unify the duplicated
   * screening report component.
   */
  skipUserQuery = false,
}) => {
  const [requestAddress, setRequestAddress] = useState(false);

  const { user, loading: userLoading } = useUserProfile({
    polling: false,
    skip: skipUserQuery,
  });

  if (
    !skipUserQuery &&
    (!user?.mailing_address ||
      !user?.mailing_address?.address ||
      !user?.telephone) &&
    !userLoading &&
    !requestAddress
  ) {
    setRequestAddress(true);
  }

  // TODO I DONT LIKE THIS ASSIGMENT
  const forPartners = skipUserQuery;

  const showErrorMessage = useErrorToast();

  const asurintExperiment = getExperimentVariant(
    user,
    Experiments.AsurintReports.name,
    'control',
  );
  const [updateOwnerProfileMutation, { loading: updateOwnerProfileLoading }] =
    useMutation(updateOwnerProfileGQL);

  const updateOwnerProfile = (data) => {
    return updateOwnerProfileMutation({
      variables: data,
    }).then((r) => r && r.data);
  };

  const close = () => {
    onClose();
  };

  const updateMailingAddress = async (data) => {
    try {
      const submitData = { mailing_address: data.mailingAddress };

      if (!user.telephone) {
        submitData.telephone = clearMask(data.telephone);
      }

      await updateOwnerProfile(submitData);

      setRequestAddress(false);
      onRequestReport();
    } catch (error) {
      const message = get(
        error,
        'graphQLErrors[0].message',
        'An error occurred',
      );

      showErrorMessage(message);
    }
  };

  let content;

  if (modalCopy === ScreeningReportModalCopyEnum.GENERAL_ERROR) {
    content = (
      <div className={styles.error}>
        <p className={styles.errorText}>
          There was an error requesting the report. Please try again.
        </p>
        <Button label="Close" onClick={() => close()} className={styles.btn} />
      </div>
    );
  } else if (requestAddress) {
    content = (
      <>
        <h3 className={styles.title}>
          You’ll need to enter your mailing address first.
        </h3>
        <p className={styles.text}>
          TransUnion requires this information to request a screening report.{' '}
          <strong>Please Note</strong>: This is your mailing address, not the
          rental property address.
        </p>
        <Form
          onSubmit={(data) => {
            updateMailingAddress(data);
          }}
          subscription={{
            submitting: true,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <FinalFormError />
              <AddressAutoSuggestFields
                narrow
                className={styles.autoSuggestHolder}
                streetAddressInputProps={{
                  label: 'Address',
                }}
                unitInputProps={{
                  label: 'Unit/Suite',
                  hint: ' ',
                }}
                fieldNames={{
                  zip: 'mailingAddress.zip',
                  unit: 'mailingAddress.unit',
                  city: 'mailingAddress.city',
                  state: 'mailingAddress.state',
                  address: 'mailingAddress.address',
                  zipCodeStyles: 'styles.zipCodeStyles',
                }}
              />
              <Field
                label="Phone number"
                component={PhoneField}
                name="telephone"
                id="telephone"
                validate={composeValidators(fieldRequired, validPhone)}
                className={styles.phone}
              />
              <div className={styles.btnWrap}>
                <Button
                  disabled={userLoading}
                  onClick={() => close()}
                  className={cx(styles.btn, styles.cancelBtn)}
                  secondary
                >
                  Cancel
                </Button>
                <Button
                  disabled={userLoading || submitting}
                  type="submit"
                  loading={submitting}
                  className={styles.btn}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Form>
      </>
    );
  } else if (modalCopy === ScreeningReportModalCopyEnum.REPORT_REQUESTED) {
    content = (
      <Card className={styles.card}>
        <div>
          <AccountFindColor
            color={colors.darkBlueGrey}
            className={styles.criminalHistoryIcon}
          />
          <div className={styles.textStyles}>
            <p className={styles.cardTitle}>Screening Report Requested!</p>
            <div className={styles.cardDescription}>
              The applicant needs to approve the request and answer some
              verification questions before you can see the report.
              {!forPartners &&
                ` We'll email you when the report is ready to view`}
            </div>
          </div>
          <div className={styles.buttonsDivStyles}>
            <Button className={styles.button} onClick={() => close()}>
              Done
            </Button>
          </div>
        </div>
      </Card>
    );
  } else if (modalCopy === ScreeningReportModalCopyEnum.PULLING_REPORT) {
    content = (
      <Card className={styles.card}>
        <div>
          <AccountFindColor
            color={colors.darkBlueGrey}
            className={styles.criminalHistoryIcon}
          />
          <div className={styles.textStyles}>
            <p className={styles.cardTitle}>Pulling screening report...</p>
            <div className={styles.cardDescription}>
              {asurintExperiment === 'test'
                ? 'We’ll email you as soon as TransUnion says it’s ready to view!'
                : 'We are waiting for TransUnion, the credit bureau, to send the report. It may take a couple of minutes.'}

              {forPartners &&
                ` Try refreshing the page if you still don’t see it.`}
            </div>
          </div>
          <div className={styles.buttonsDivStyles}>
            <Button
              className={styles.button}
              onClick={() => {
                close();
                forPartners && window.location.reload();
              }}
            >
              Sounds Good
            </Button>
          </div>
          {asurintExperiment === 'test' && (
            <Paragraph className="mt-10">
              This can take up to 24 hours
            </Paragraph>
          )}
        </div>
      </Card>
    );
  } else if (modalCopy === ScreeningReportModalCopyEnum.TRANSUNION_ERROR) {
    content = (
      <Card className={styles.card}>
        <div>
          <Caution color={colors.darkBlueGrey} className={styles.cautionIcon} />
          <div className={styles.textStyles}>
            <p className={styles.cardTitle}>Error Connecting with TransUnion</p>
            <div className={styles.cardDescription}>
              <span>
                There has been an error connecting with TransUnion to pull the
                applicant’s screening report. Please contact{' '}
                <a href="mailto:support@turbotenant.com">
                  support@turbotenant.com
                </a>{' '}
                to remedy the issue.{' '}
              </span>
            </div>
          </div>
          <div className={styles.buttonsDivStyles}>
            <Button
              className={styles.button}
              onClick={() => {
                onClose();
              }}
            >
              Done
            </Button>
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Modal
      className={cx(styles.modal, styles.container)}
      open={open}
      autoFocus={false}
      mobileFullScreen
    >
      <LoadingScreen
        loading={userLoading || updateOwnerProfileLoading || loading}
      >
        {content}
      </LoadingScreen>
    </Modal>
  );
};

ScreeningReportModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onRequestReport: PropTypes.func,
  skipUserQuery: PropTypes.bool,
  modalCopy: PropTypes.string,
};

export default ScreeningReportModal;
