import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';

const LateFeeInfoCO = ({ className, fullStateName }) => (
  <InlineAlert className={className} title={fullStateName}>
    <ul>
      <li>There is a 7 day grace period before a late fee may be charged.</li>
      <li>
        Late fees can be $50 or 5% of the rent amount due, whichever is greater.
      </li>
    </ul>
  </InlineAlert>
);

LateFeeInfoCO.propTypes = {
  className: PropTypes.string,
  fullStateName: PropTypes.string,
};

export default LateFeeInfoCO;
