import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';

const LateFeeInfo = ({ className, fullStateName }) => (
  <InlineAlert className={className} title={fullStateName}>
    <ul>
      <li>
        For senior citizens and for recipients of social security benefits there
        is a 5 business day grace period before a late fee may be charged.
      </li>
      <li>
        For all others there is no mandatory grace period, but 5 days is common.
      </li>
      <li>
        There is no state law on the amount of late fees to be charged, but 5%
        is common. Jersey City allows a maximum late fee of $35.
      </li>
    </ul>
  </InlineAlert>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  fullStateName: PropTypes.string,
};

export default LateFeeInfo;
