import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import RadioGroup from '../../../../../components/RadioGroup';
import LEASE_END_ACTIONS from '../../../../../constants/lease_agreement/lease-end-actions';
import fieldRequiredStandard from '../../../../../validators/fieldRequired';
import SectionInfoBlock from '../../../common/SectionInfoBlock';
import { useStandaloneWizardState } from '../../../standalone-wizard/StandaloneWizardProvider';
import { LEASE_TERM_CHOICES } from '../LeaseTermChoices';

import styles from './LeaseEndContent.module.scss';

const noop = () => {};

const LeaseEndContent = ({ leaseTerm, currentState }) => {
  const { standAloneWizard } = useStandaloneWizardState();

  const fieldRequired = standAloneWizard ? noop : fieldRequiredStandard;

  if (leaseTerm === LEASE_TERM_CHOICES.fixed) {
    return (
      <Field
        component={RadioGroup}
        id="lease_end_action"
        name="lease_end_action"
        label="When the lease ends on the date above, which of these should occur?"
        labelProps={{ className: styles.label }}
        vertical
        className={styles.radioGroup}
        radioRowClassName={styles.radioItem}
        options={[
          {
            id: 'lease_end_action_continue',
            value: LEASE_END_ACTIONS.CONTINUE_MONTHLY,
            label: 'Renew on a month-to-month basis automatically',
          },
          {
            id: 'lease_end_action_terminate',
            value: LEASE_END_ACTIONS.TERMINATE,
            label: 'Terminate automatically on the end date above',
          },
        ]}
        validate={fieldRequired}
      />
    );
  }

  if (currentState !== 'FL') {
    return (
      <SectionInfoBlock className={styles.endLeaseInfo}>
        To end the lease, the landlord or tenant(s) must give the other party
        30-day written notice. The lease will continue indefinitely until then.
      </SectionInfoBlock>
    );
  }

  return (
    <SectionInfoBlock className={cx(styles.infoBlock, styles.endLeaseInfo)}>
      <strong>Florida Lease Termination Notice Requirements:</strong> To end a
      term lease, you or your tenant must give 30 days advance written notice.
      For month-to-month leases, a 15 day written notice must be given.
    </SectionInfoBlock>
  );
};

LeaseEndContent.propTypes = {
  leaseTerm: PropTypes.string.isRequired,
  currentState: PropTypes.string.isRequired,
};

export default LeaseEndContent;
