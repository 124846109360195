import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useUserProfile } from '../../../../../../core/TTgraphql';
import { getConfig } from '../../../../../../services/configService';

import Button from '../../../../../Button';
import AddRentersModal from '../../../../../messaging/AddRentersModal';

import Illustration from '../../../../../messaging/MessageThreadEmpty/Illustration';
import BinocularsColor from '../../../../../../icons/BinocularsColor';

import styles from './CometChatListEmpty.module.scss';
import { segmentTracking } from '../../../../../../services/utilities';

const CometChatListEmpty = ({
  className,
  hasActiveRenters,
  isFromUniversalMessaging,
  ...rest
}) => {
  const { user } = useUserProfile();
  const [addRentersModalOpen, setAddRentersModalOpen] = useState(false);
  const { IS_OWNER, IS_RENTER } = getConfig();

  const generateMessage = () => {
    if (isFromUniversalMessaging) {
      return 'Send a message to start communicating with renters.';
    }
    return `Click "New Message" to start communicating with ${
      IS_RENTER ? 'landlords' : 'renters'
    }.`;
  };
  return (
    <>
      <div className={cx(styles.container, className)} {...rest}>
        {isFromUniversalMessaging &&
          (!hasActiveRenters ? (
            <BinocularsColor className={styles.illustration} />
          ) : (
            <Illustration className={styles.illustration} />
          ))}
        {hasActiveRenters ? (
          <h2 className={styles.title}>You Don't Have Any Chats</h2>
        ) : (
          <h2 className={styles.title}>You Don't Have Any Renters</h2>
        )}
        <p
          className={cx(styles.description, {
            [styles.leftAlign]: !hasActiveRenters && !isFromUniversalMessaging,
          })}
        >
          {hasActiveRenters
            ? generateMessage()
            : `You don't have ${
                IS_RENTER ? 'landlords' : 'renters'
              } yet. Once you do, you'll be able to start a conversation here.`}
        </p>
        {!hasActiveRenters && IS_OWNER && (
          <Button
            className={styles.btn}
            onClick={() => {
              segmentTracking('add_renters clicked', {
                location: isFromUniversalMessaging
                  ? 'Universal Messaging'
                  : 'Messages',
              });
              setAddRentersModalOpen(true);
            }}
          >
            Add Renters
          </Button>
        )}
      </div>
      <AddRentersModal
        open={addRentersModalOpen}
        onClose={() => setAddRentersModalOpen(false)}
        hasLeases={!!user?.active_leases_count}
      />
    </>
  );
};

CometChatListEmpty.propTypes = {
  className: PropTypes.string,
  hasActiveRenters: PropTypes.bool,
};

export default CometChatListEmpty;
