export const Experiments = {
  GoodBetterBest: {
    name: 'experiment_gbb',
    variants: {
      A: 'A', // Tabs
      B: 'B', // Accordion
      C: 'C', // Toggle
      D: 'D', // Tabs Premium Plus 299
      E: 'E', // Tabs Premium Plus 249
      control: 'Control',
    },
  },
  LandlordPaysSpecificStates: {
    name: 'LL_Pays_NY_WI_MA_VT',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  LandlordPaysOtherStates: {
    name: 'LL_Pays_Other_States',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  MaintenanceRequestLulaProCardAug2024: {
    name: 'maintenance_request_lula_pro_card_aug_2024',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  RentPaymentsFlowOptimization: {
    name: 'rp_flow_optimization',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  ReiHubAddonOffer: {
    name: 'rei_addon_offer',
    variants: {
      quarterly: 'quarterly',
      annually: 'annually',
    },
  },
  ReiHubMonthlyTrialOffer: {
    name: 'rei_monthly_trial_offer',
    variants: {
      test: 'free_trial',
      control: '50_percent_off',
    },
  },
  AsurintReports: {
    name: 'asurint_reports',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  ExpandedLeaseSetupChecklist: {
    name: 'expanded_lease_setup_checklist',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  LeaseAgreementCosigner: {
    name: 'product_lease_agreement_co-signer',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  ComparisonChartIntroPage: {
    name: 'rp_comparison_chart_experiment',
    variants: {
      control: 'control',
      test: 'test',
    },
  },
  PremiumModalGooglePay: {
    name: 'premium_checkout_modal_google_apple_pay_test',
    variants: {
      test: 'test',
      control: 'control',
    },
  },
  GeneralApplicationFlow: {
    name: 'general_application_flow',
    variants: {
      variantA: 'variant_A',
      control: 'control',
    },
  },
  RenterCreditReportingPricing: {
    name: 'renter_credit_reporting_pricing',
    variants: {
      control: 'control',
      test_1: 'test_1',
      test_2: 'test_2',
    },
  },
  GeneralApplicationTest2: {
    name: 'general_application_test_2',
    variants: {
      control: 'Control',
      variant_A: 'variant_A',
      variant_B: 'variant_B',
      variant_C: 'variant_C',
    },
  },
  RenterUpfrontPricingTest: {
    name: 'application_upfront_pricing_test',
    variants: {
      control: 'control',
      test_1: 'variant_A',
    },
  },
  CxScheduleProcess: {
    name: 'gt_cx_schedule_process',
    variants: {
      control: 'control',
      test: 'variant_1',
    },
  },
  AllOnboardingFlow: {
    name: 'all_onboarding_flow',
    variants: {
      control: 'control',
      test: 'straight_to_checklist',
    },
  },
  LLPropertyAdd: {
    name: 'gt_ll_property_add',
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
  },
};
