import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import pathToRegexp from 'path-to-regexp';
import { object, string } from 'prop-types';

import colors from '../../../constants/colors';
import { FEATURE_FLAGS } from '../../../constants/feature_flags';
import SUBSCRIPTION_STATUS from '../../../constants/stripe_subscriptions/subscription_status';
import { useUserProfile } from '../../../core/TTgraphql';
import ownerSettingsQueryGQL from '../../../graphql/queries/ownerSettingsQuery.graphql';
import AlarmFilled from '../../../icons/AlarmFilled';
import { useConfig } from '../../../providers/ConfigProvider';

import styles from './OwnerHeaderAccountSection.module.scss';

const OwnerHeaderAccountSection = ({ pathname, history }) => {
  const { data: ownerSettingsData } = useQuery(ownerSettingsQueryGQL);
  const { PRIVATE_BASE_PATH } = useConfig();

  const { user, features = [] } = useUserProfile();
  const isLulaEnabled = features?.includes(FEATURE_FLAGS.LULA_MAINTENANCE);
  const isLulaEligible = user?.lula_eligible && isLulaEnabled;

  const maintenanceRequestManagementSettings = get(
    user,
    'maintenance_request_management_settings',
  );

  const { premiumPlusSubscriptionInfo, reiHubSubscriptionInfo } =
    ownerSettingsData?.userData?.settingsAndBilling?.data || {};
  const premiumStatus = premiumPlusSubscriptionInfo?.status || null;
  const reiHubStatus = reiHubSubscriptionInfo?.status || null;
  const showSubscriptionAlert =
    premiumStatus === SUBSCRIPTION_STATUS.PAST_DUE ||
    reiHubStatus === SUBSCRIPTION_STATUS.PAST_DUE;

  const getSelectedIndex = () => {
    const paths = [
      'account',
      'rent-payments(.*)',
      'billing',
      'application',
      'advanced(.*)',
      'pre-screener',
      'maintenance',
    ];

    return paths.findIndex((path) =>
      pathToRegexp(`/owners/settings/${path}`).test(pathname),
    );
  };

  const selectedIndex = getSelectedIndex();

  return (
    <div className={styles.barStyles}>
      <div className={styles.mainRowContainer}>
        <div className={styles.itemsContainer}>
          <div className={styles.itemStyles}>
            <span
              id="account_tab"
              className={cx(
                styles.itemLabel,
                selectedIndex === 0 && styles.itemLabelSelected,
              )}
              onClick={() =>
                history.push(`${PRIVATE_BASE_PATH}settings/account`)
              }
            >
              ACCOUNT
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(
                styles.bottomItemLabel,
                selectedIndex === 0 && styles.bottomItemLabelSelected,
              )}
            />
          </div>
          <div className={styles.separatorStyle} />
          <div className={styles.itemStyles}>
            <span
              id="bank_accounts_tab"
              className={cx(
                styles.itemLabel,
                selectedIndex === 1 && styles.itemLabelSelected,
              )}
              onClick={() =>
                history.push(`${PRIVATE_BASE_PATH}settings/rent-payments`)
              }
            >
              RENT PAYMENTS
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(
                styles.bottomItemLabel,
                selectedIndex === 1 && styles.bottomItemLabelSelected,
              )}
            />
          </div>
          <div className={styles.separatorStyle} />
          <div className={styles.itemStyles}>
            <span
              id="billing_tab"
              className={cx(
                styles.itemLabel,
                selectedIndex === 2 && styles.itemLabelSelected,
              )}
              onClick={() =>
                history.push(`${PRIVATE_BASE_PATH}settings/billing`)
              }
            >
              BILLING
              {showSubscriptionAlert && (
                <AlarmFilled
                  className={styles.alarm}
                  color={colors.orangeLight}
                  exclamationColor={colors.white}
                  width={16}
                  height={16}
                />
              )}
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(
                styles.bottomItemLabel,
                selectedIndex === 2 && styles.bottomItemLabelSelected,
              )}
            />
          </div>
          <div className={styles.separatorStyle} />
          <div className={styles.itemStyles}>
            <span
              id="prescreener_tab"
              className={cx(
                styles.itemLabel,
                selectedIndex === 5 && styles.itemLabelSelected,
              )}
              onClick={() =>
                history.push(`${PRIVATE_BASE_PATH}settings/pre-screener`)
              }
            >
              PRE SCREENER
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(
                styles.bottomItemLabel,
                selectedIndex === 5 && styles.bottomItemLabelSelected,
              )}
            />
          </div>
          <div className={styles.separatorStyle} />
          <div className={styles.itemStyles}>
            <span
              id="application_tab"
              className={cx(
                styles.itemLabel,
                selectedIndex === 3 && styles.itemLabelSelected,
              )}
              onClick={() =>
                history.push(`${PRIVATE_BASE_PATH}settings/application`)
              }
            >
              APPLICATION
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(
                styles.bottomItemLabel,
                selectedIndex === 3 && styles.bottomItemLabelSelected,
              )}
            />
          </div>
          <div className={styles.separatorStyle} />
          {isLulaEligible && (
            <>
              <div className={styles.itemStyles}>
                <span
                  id="application_tab"
                  className={cx(
                    styles.itemLabel,
                    selectedIndex === 6 && styles.itemLabelSelected,
                  )}
                  onClick={() =>
                    history.push(
                      maintenanceRequestManagementSettings
                        ? `${PRIVATE_BASE_PATH}settings/maintenance`
                        : `${PRIVATE_BASE_PATH}maintenance/onboarding`,
                    )
                  }
                >
                  MAINTENANCE
                </span>
                <div
                  style={{ position: 'relative', top: 16 }}
                  className={cx(
                    styles.bottomItemLabel,
                    selectedIndex === 6 && styles.bottomItemLabelSelected,
                  )}
                />
              </div>
              <div className={styles.separatorStyle} />
            </>
          )}
          <div className={styles.itemStyles}>
            <span
              id="advanced_tab"
              className={cx(
                styles.itemLabel,
                selectedIndex === 4 && styles.itemLabelSelected,
              )}
              onClick={() =>
                history.push(`${PRIVATE_BASE_PATH}settings/advanced`)
              }
            >
              ADVANCED
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(
                styles.bottomItemLabel,
                selectedIndex === 4 && styles.bottomItemLabelSelected,
              )}
            />
          </div>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomItemLabel} />
      </div>
    </div>
  );
};

OwnerHeaderAccountSection.propTypes = {
  pathname: string,
  history: object,
};

export default withRouter(OwnerHeaderAccountSection);
