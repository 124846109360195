import React from 'react';
import PropTypes from 'prop-types';

import { Experiments } from '../../constants/experiments';
import { useUserProfile } from '../../core/TTgraphql';
import getExperimentVariant from '../../helpers/experiments';
import AccountFind from '../../icons/AccountFind';
import SectionTitleWithIconAndAction from '../SectionTitleWithIconAndAction';

import styles from './PullingReportMessage.module.scss';

const PullingReportMessage = ({ title, titleDescription }) => {
  const { user } = useUserProfile();

  const asurintExperiment = getExperimentVariant(
    user,
    Experiments.AsurintReports.name,
    'control',
  );

  return (
    <div className={styles.container}>
      <SectionTitleWithIconAndAction
        className={styles.title}
        title={title}
        text={titleDescription}
        icon={AccountFind}
      />
      <div className={styles.descriptionContainer}>
        <span className={styles.description}>
          {asurintExperiment === 'test'
            ? 'We are waiting for TransUnion to send the report. This can take up to 24 hours but we’ll email you as soon as it’s ready to view!'
            : 'We are waiting for TransUnion, the credit bureau, to send the report. It may take a couple of minutes. Try refreshing the page if you still don’t see it.'}
        </span>
      </div>
    </div>
  );
};

PullingReportMessage.propTypes = {
  title: PropTypes.string,
  titleDescription: PropTypes.string,
};

export default PullingReportMessage;
