import React from 'react';
import PropTypes from 'prop-types';

const EntitySequence = () => (
  <>&#847; &zwnj; &nbsp; &#8199; &shy; &#847; &zwnj; &nbsp; &#8199; &shy; </>
);

const PreviewText = ({ previewText, previewTextAdditionalEmptySpaces = 0 }) => {
  if (!previewText) {
    return null;
  }

  const contentArray = Array.from(
    { length: 24 + previewTextAdditionalEmptySpaces },
    (_, index) => <EntitySequence key={index} />,
  );

  return (
    <>
      <span
        style={{
          color: 'transparent',
          display: 'none',
          height: 0,
          maxHeight: 0,
          maxWidth: 0,
          opacity: 0,
          overflow: 'hidden',
          msoHide: 'all',
          visibility: 'hidden',
          width: 0,
        }}
      >
        {previewText}
      </span>
      {/* this is a hack to prevent rest of the email body be parsed as a preview text */}
      <div style={{ display: 'none', maxHeight: 0, overflow: 'hidden' }}>
        {contentArray}
      </div>
    </>
  );
};

PreviewText.propTypes = {
  previewText: PropTypes.string,
  previewTextAdditionalEmptySpaces: PropTypes.number,
};

export default PreviewText;
