import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import colors from '../../../../../../constants/colors';
import { segmentTracking } from '../../../../../../services/utilities';
import Exclamation from '../../../../../../icons/streamline/filled/Exclamation';

import styles from './QuickReplyTemplatesModal.module.scss';

const ConfirmDelete = ({ onClose, onDelete, loading }) => (
  <div className={styles.deleteModal}>
    <Exclamation color={colors.statusWarning} width={96} height={96} />
    <p className={styles.deleteModalTitle}>Delete template?</p>
    <Button
      className={styles.deleteModalBtn}
      onClick={onDelete}
      loading={loading}
    >
      Delete
    </Button>
    <Button
      secondary
      onClick={() => {
        segmentTracking('cancel clicked', {
          location: 'Delete Template Modal',
        });
        onClose();
      }}
    >
      Cancel
    </Button>
  </div>
);

ConfirmDelete.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
};

export default ConfirmDelete;
