import moment from 'moment';

const dateFormats = [
  'MM/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'MM/YY',
  'YYYYMMDD',
];

const getFixedDate = (date) => {
  const dateObject = date && moment.utc(date, dateFormats, true);
  return dateObject && dateObject.isValid()
    ? dateObject.format('YYYY-MM-DD')
    : null;
};

export default getFixedDate;
