import React from 'react';

import SuccessAnimation from '../../../../components/Animations/SuccessAnimation';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import { H2, Paragraph } from '../../../../components/Typography';
import { linkTo, routes } from '../../../../helpers/routes';
import { segmentTracking } from '../../../../services/utilities';
import OnboardingProgress from '../../components/OnboardingProgress';

import styles from './PaymentMethodSuccess.module.scss';

const PaymentMethodSuccess = () => (
  <>
    <OnboardingProgress
      step="Rent Payments"
      progress={30}
      isCurrentStepCompleted
    />

    <Card padded className={styles.container}>
      <Card.Content className={styles.content}>
        <SuccessAnimation style={{ width: 96, height: 96 }} />
        <H2 align="center" className="mb-4 mt-24">
          Payment method added!
        </H2>
        <Paragraph align="center" size="large" className="mb-24">
          You are now ready to make payments.
        </Paragraph>
      </Card.Content>
      <Button
        variant="primary"
        className={styles.button}
        to={linkTo(routes.tenantOnboardingStepRentersInsurance)}
        onClick={() => {
          segmentTracking('continue clicked', {
            location: 'Tenant Onboarding Payment Method Success',
          });
        }}
      >
        Continue
      </Button>
    </Card>
  </>
);
export default PaymentMethodSuccess;
