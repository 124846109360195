import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Slim.module.scss';

const SlimProgressBar = ({ progress, animated, rounded, className }) => (
  <div
    className={cx(
      styles.progressWrap,
      {
        [styles.rounded]: rounded,
        [styles.animated]: animated,
      },
      className,
    )}
  >
    <div
      className={cx(styles.progressBar, {
        [styles.rounded]: rounded,
        [styles.animated]: animated,
        [styles.completed]: Math.ceil(progress) === 100,
      })}
      style={
        animated
          ? { width: '0%' }
          : {
              width: `${Math.ceil(progress)}%`,
            }
      }
    />
  </div>
);

SlimProgressBar.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number,
  rounded: PropTypes.bool,
  animated: PropTypes.bool,
};

export default SlimProgressBar;
