import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';

import ArrowLeft from '../../../../../../icons/ArrowLeft';
import colors from '../../../../../../constants/colors';
import { mobileBreakpoint } from '../../../../../../constants/media-breakpoints';
import TrashIcon from '../../../../../../icons/Remove';
import fieldRequired from '../../../../../../validators/fieldRequired';
import Button from '../../../../../Button';
import FlatButton from '../../../../../FlatButton';
import Input from '../../../../../Input';
import TextArea from '../../../../../Textarea';
import { H2 } from '../../../../../Typography';

import styles from './QuickReplyTemplatesModal.module.scss';

const CreateTemplateForm = ({
  onSubmit,
  onClose,
  templateToEdit,
  onEdit,
  onDelete,
  loading,
}) => {
  const {
    id,
    is_predefined: isDefault,
    default_template_id: defaultTemplateId,
  } = templateToEdit || {};
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth < mobileBreakpoint;

  const handleEdit = (form) => {
    onEdit({
      ...templateToEdit,
      ...form,
    });
  };

  return (
    <Form
      onSubmit={templateToEdit ? handleEdit : onSubmit}
      initialValues={{
        template_name: templateToEdit?.template_name,
        message_body: templateToEdit?.message_body,
      }}
      subscription={{
        submitting: true,
        values: true,
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          {isMobile ? (
            <div className={styles.header}>
              <div className={styles.backArrow}>
                <ArrowLeft onClick={onClose} />
              </div>
              <H2 className={cx(styles.title)}>
                {templateToEdit ? 'Edit' : 'Create New'} Template
              </H2>
            </div>
          ) : (
            <H2 className={cx(styles.title)}>
              {templateToEdit ? 'Edit' : 'Create New'} Template
            </H2>
          )}
          <Field
            component={Input}
            id="template_name"
            name="template_name"
            label="Template Name"
            maxLength={50}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 50 characters used`}
          />
          <Field
            as="textarea"
            component={TextArea}
            id="message_body"
            name="message_body"
            label="Message"
            inputProps={{
              className: styles.multilineField,
            }}
            maxLength={2000}
            validate={fieldRequired}
            help={(val = '') => `${val.length} / 2000 characters used`}
          />
          {templateToEdit && !isDefault && !defaultTemplateId ? (
            <FlatButton
              small
              icon={() => <TrashIcon color={colors.statusWarning} />}
              className={styles.deleteBtn}
              onClick={() => onDelete(id)}
            >
              <span className={styles.deleteText}>Delete Template</span>
            </FlatButton>
          ) : null}
          <div className={styles.footer}>
            <Button
              type="button"
              secondary
              disabled={submitting}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={submitting || loading}
              disabled={submitting}
            >
              {templateToEdit ? 'Save' : 'Create Template'}
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

CreateTemplateForm.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  templateToEdit: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
};

export default CreateTemplateForm;
