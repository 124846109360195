import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import FlatButton from '../../../../../components/FlatButton';
import LoadingScreen from '../../../../../components/LoadingScreen';
import { useErrorToast } from '../../../../../components/Toast';
import { CardTypesEnum } from '../../../../../constants';
import { useUserProfile } from '../../../../../core/TTgraphql';
import { getUserActiveCard } from '../../../../../helpers/getUserActiveCard';
import BackArrow from '../../../../../icons/BackArrow';
import { useConfig } from '../../../../../providers/ConfigProvider';
import { useSegmentTracker } from '../../../../../providers/SegmentTrackingProvider/context';
import StripeElementsComponent from '../../../../common/stripe/StripeElementsComponent';
import {
  useSetPaymentMethodForRentPayments,
  useUpdatePaymentMethod,
} from '../../../../payments/usePayments';
import ConfirmManualPlaidMicrodepositFlow from '../../../components/ConfirmManualPlaidMicrodepositFlow';
// import CardTypeWarningModal from '../CardTypeWarningModal/CardTypeWarningModal';
import ExistingPaymentMethod from '../ExistingPaymentMethod';

// import cardTypeValidation from './cardTypeValidation';
import PaymentMethodList from './PaymentMethodList';

import styles from './AddPaymentMethods.module.scss';

export const segmentEventMap = {
  both: 'Card Info',
  credit: 'Credit Card Info',
  debit: 'Debit Card Info',
};

export const allowedCard = {
  both: 'both',
  credit: CardTypesEnum.credit,
  debit: CardTypesEnum.debit,
};

const AddPaymentMethods = ({
  history,
  location,
  plaidConfig,
  selectManualMicrodepositsFlow,
  postSubmitActions,
  hideBackButton,
  wrapPaymentMethodsClassName,
  wrapStripeElementClassName,
  showCancelButton,
  linkForExistingRentPaymentsPaymentMethod,
  redirectToBeforeSuccess,
  loading = false,
  setupPaymentsFlowData = null,
  showSuccessToast = true,
  showExistingRentPaymentsPaymentMethod = false,
}) => {
  const { trackAction } = useSegmentTracker();
  const { user, loading: userLoading } = useUserProfile();
  const { IS_RENTER, PRIVATE_BASE_PATH } = useConfig();
  const [openStripe, setOpenStripe] = useState(false);
  // const [cardWarningModalOpen, setCardWarningModalOpen] = useState(false);
  const [
    confirmManualPlaidMicrodepositFlowOpen,
    setConfirmManualPlaidMicrodepositFlowOpen,
  ] = useState(false);

  const [openPlaidLinkAfterReconfig, setOpenPlaidLinkAfterReconfig] =
    useState(false);

  const queryParameters = queryString.parse(location.search) || {};
  const { redirectTo, goBackTo, makePaymentFlow } = queryParameters;
  const fromEditPaymentMethod = location.state?.fromEditPaymentMethod;
  const fromIdFlow = !!makePaymentFlow;

  const {
    open: openPlaidLink,
    ready: plaidReady,
    error: plaidError,
  } = usePlaidLink(plaidConfig);

  const showErrorToast = useErrorToast();

  const [updatePaymentMethod, { loading: loadingMut, error }] =
    useUpdatePaymentMethod();

  /**
   * A payment method that is not used for rent payments right now.
   * TODO if we ever allow the renter to have more than 2 methods on file
   * we need to revisit this
   */
  const rentPaymentsPaymentMethodData = user?.payment_methods?.find(
    (pm) => pm.used_for_rent_payments,
  );
  const paymentMethodData = user?.payment_methods?.find(
    (pm) => !pm.used_for_rent_payments,
  );

  const renterPaymentMethodAdded = user?.rent_payments_method_added;

  const [setPaymentMethodForRentPayments, { loading: loadingUse }] =
    useSetPaymentMethodForRentPayments();

  const activeCard = getUserActiveCard(user);

  useEffect(() => {
    if (openPlaidLinkAfterReconfig) {
      openPlaidLink();
      setOpenPlaidLinkAfterReconfig(false);
    }
  }, [openPlaidLinkAfterReconfig]);

  useEffect(() => {
    if (plaidError) {
      showErrorToast('An error has occurred');
    }
  }, [plaidError]);

  useEffect(() => {
    trackAction('pageView');
  }, []);

  if (error) {
    showErrorToast(error.message);
  }

  const handleBack = () => {
    let redirectUrl = `${PRIVATE_BASE_PATH}dashboard`;
    if (fromEditPaymentMethod) {
      redirectUrl = `${PRIVATE_BASE_PATH}settings/payment-methods`;
    }
    if (goBackTo) {
      redirectUrl = goBackTo;
    }
    history.push(redirectUrl);
  };

  const getInfoAboutCard = async () => {
    try {
      await setPaymentMethodForRentPayments({
        variables: { id: activeCard.id },
      });
      if (setupPaymentsFlowData?.onSuccess) {
        setupPaymentsFlowData.onSuccess();
        return;
      }

      if (redirectToBeforeSuccess) {
        return history.push(redirectToBeforeSuccess);
      }

      return history.push({
        pathname: `${PRIVATE_BASE_PATH}payments/payment-method-success`,
        state: { redirectTo, ...location.state },
      });
    } catch (e) {
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
      showErrorToast(message);
    }
  };

  const onSubmit = async (token, { zipCode, cardHolder }) => {
    const modalType = setupPaymentsFlowData?.showCreditCardForm ?? openStripe;

    try {
      trackAction('saveCard', {
        location: `${segmentEventMap[modalType]} - Payments Setup`,
      });
      setOpenStripe(null);

      // sometimes this callback is called without token, for example
      // when we update the card info
      // since the mutation will fail, we prevent it to be called
      /**
       * Not sure when this code is reached. It seems that the card is being
       * updated inside the stripe component and this callback is always
       * called with a null token
       */
      let data;

      if (token) {
        const res = await updatePaymentMethod({
          variables: {
            token,
            zipCode,
            cardHolder,
            isRentPaymentsPaymentMethod: true,
          },
        });
        data = res.data;
      }

      postSubmitActions(data);
      if (!data?.updatePaymentMethod?.ok && data !== undefined) {
        showErrorToast('An error occurred');
      }
    } catch {
      showErrorToast('An error occurred');
    }
  };

  const setStripeModalState = (state) => {
    if (typeof setupPaymentsFlowData?.setShowCreditCardForm === 'function') {
      setupPaymentsFlowData.setShowCreditCardForm(state);
    } else {
      setOpenStripe(state);
    }
  };

  const redirectToAddBank = () => {
    history.push({
      pathname: `${PRIVATE_BASE_PATH}payments/add-bank`,
      state: { redirectTo, makePaymentFlow: fromIdFlow, ...location.state },
    });
  };
  const handleCardSetup = (paymentMethod) => {
    setStripeModalState(paymentMethod);
  };

  const handleChangePaymentMethod = (paymentMethod) => {
    if (IS_RENTER && paymentMethod === 'bank-account') {
      selectManualMicrodepositsFlow(false, () => {
        setOpenPlaidLinkAfterReconfig(true);
      });
      return;
    }
    if (paymentMethod === 'bank-account') {
      return redirectToAddBank();
    }
    if ([allowedCard.credit, allowedCard.debit].includes(paymentMethod)) {
      return handleCardSetup(paymentMethod);
    }
  };

  if (setupPaymentsFlowData?.showCreditCardForm ?? openStripe) {
    const modalType = setupPaymentsFlowData?.showCreditCardForm ?? openStripe;
    let cardType =
      modalType === allowedCard.credit ? 'Credit Card' : 'Debit Card';

    // let validateCard;
    // if (
    //   [allowedCard.credit, allowedCard.debit].includes(modalType) &&
    //   isTestVariant
    // ) {
    //   validateCard = (token) => {
    //     const validation = cardTypeValidation(modalType);
    //     const isValid = validation(token);
    //     if (!isValid) {
    //       setCardWarningModalOpen(true);
    //     }
    //     return isValid;
    //   };
    // }

    return (
      <div>
        {!hideBackButton && !setupPaymentsFlowData && (
          <FlatButton
            icon={BackArrow}
            className={styles.backButton}
            onClick={() => {
              trackAction('goBack', {
                location: `${segmentEventMap[modalType]} - Payments Setup`,
              });
              setOpenStripe(false);
            }}
            iconProps={{ width: 18, height: 18 }}
          >
            Back
          </FlatButton>
        )}
        <StripeElementsComponent
          // isTokenValidFn={validateCard}
          onToken={onSubmit}
          title={`Add ${cardType}`}
          buttonLabel="Save Card"
          fromEditPaymentMethod={fromEditPaymentMethod}
          isRentPaymentsPaymentMethod={true}
          showSuccessToast={showSuccessToast && !setupPaymentsFlowData}
          className={wrapStripeElementClassName}
          showCancelButton={showCancelButton}
          onCancel={() => setOpenStripe(false)}
        />

        {/* <CardTypeWarningModal
          open={cardWarningModalOpen}
          onClose={() => setCardWarningModalOpen(false)}
          modalType={modalType}
          onAction={() => {
            setStripeModalState(
              modalType === allowedCard.credit
                ? allowedCard.debit
                : allowedCard.credit,
            );
            setCardWarningModalOpen(false);
          }}
        /> */}
      </div>
    );
  }

  if (userLoading || loadingUse || !plaidReady) {
    return <LoadingScreen loading />;
  }

  let existingPaymentMethod;
  if (showExistingRentPaymentsPaymentMethod) {
    existingPaymentMethod = rentPaymentsPaymentMethodData
      ? rentPaymentsPaymentMethodData
      : paymentMethodData;
  } else if (!rentPaymentsPaymentMethodData) {
    existingPaymentMethod = paymentMethodData;
  }

  return (
    <LoadingScreen loading={loading || loadingMut}>
      {!hideBackButton && !setupPaymentsFlowData && (
        <FlatButton
          onClick={handleBack}
          className={styles.backButton}
          icon={BackArrow}
          iconProps={{ className: styles.icon }}
        >
          Back
        </FlatButton>
      )}

      <div
        className={cx(styles.wrapPaymentMethods, wrapPaymentMethodsClassName)}
      >
        <h1 id="add_payment_method_title" className={styles.title}>
          How Do You Want to Pay for Rent?
        </h1>
        {IS_RENTER && existingPaymentMethod && (
          <>
            <h3 className={styles.subTitle}>Existing Payment Method</h3>
            <ExistingPaymentMethod
              user={user}
              userLoading={userLoading}
              paymentMethod={existingPaymentMethod}
              onClickAction={() => {
                getInfoAboutCard();
                trackAction('useExistingCard', {
                  paymentMethod: existingPaymentMethod.type,
                });
              }}
              to={linkForExistingRentPaymentsPaymentMethod}
            />
            <h3 className={styles.subTitle}>Change to a New Payment Method</h3>
          </>
        )}
        <div className={styles.listPaymentMethods}>
          <PaymentMethodList
            handleChangePaymentMethod={handleChangePaymentMethod}
            primaryButton={!paymentMethodData || renterPaymentMethodAdded}
          />
        </div>
        <div className={styles.manualAddPromptWrapper}>
          <p
            className={styles.manualAddPrompt}
            onClick={() => {
              trackAction('addManual');
              setConfirmManualPlaidMicrodepositFlowOpen(true);
            }}
          >
            Enter bank information manually
          </p>
        </div>
      </div>
      <ConfirmManualPlaidMicrodepositFlow
        open={confirmManualPlaidMicrodepositFlowOpen}
        onCancel={() => setConfirmManualPlaidMicrodepositFlowOpen(false)}
        onConfirm={async () => {
          setConfirmManualPlaidMicrodepositFlowOpen(false);
          selectManualMicrodepositsFlow(true, () => {
            setOpenPlaidLinkAfterReconfig(true);
          });
        }}
      />
    </LoadingScreen>
  );
};

AddPaymentMethods.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
  plaidConfig: PropTypes.object,
  selectManualMicrodepositsFlow: PropTypes.func,
  setupPaymentsFlowData: PropTypes.object,
  postSubmitActions: PropTypes.func,
  hideBackButton: PropTypes.bool,
  showSuccessToast: PropTypes.bool,
  wrapPaymentMethodsClassName: PropTypes.string,
  wrapStripeElementClassName: PropTypes.string,
  showCancelButton: PropTypes.bool,
  linkForExistingRentPaymentsPaymentMethod: PropTypes.string,
  redirectToBeforeSuccess: PropTypes.string,
  showExistingRentPaymentsPaymentMethod: PropTypes.bool,
};

export default withRouter(AddPaymentMethods);
