import React from 'react';
import { FormSpy } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../../../components/FlatButton';
import { Paragraph } from '../../../../../../../components/Typography';
import Edit from '../../../../../../../icons/Edit';
import { LateFeeLimitTypeEnum, LateFeeUnitEnum } from '../constants';

import LateFeeDigestContent from './LateFeeDigestContent';

import styles from '../ConfigureLateFeesModal.module.scss';

const Digest = ({
  className,
  digestClassName,
  data = {},
  insideModal = false,
  onEdit,
  hideEdit = false,
  hideTitle = false,
}) => {
  const {
    oneTimeFeeAmount,
    dailyLateFeeAmount,
    dailyFeesApplied,
    oneTimeFeeApplied,
  } = data;

  const showLateFeeDigestForOneTime = oneTimeFeeAmount && oneTimeFeeApplied;
  const showLateFeeDigestForDaily = dailyLateFeeAmount && dailyFeesApplied;

  if (!showLateFeeDigestForOneTime && !showLateFeeDigestForDaily) {
    return null;
  }

  return (
    <div
      className={cx(styles.lateFeePolicyContainer, className, {
        [styles.outsideModal]: !insideModal,
        [styles.noTopMargin]: hideTitle,
      })}
    >
      {insideModal ? (
        <Paragraph size="small" weight="semi" className="mb-4 mt-0">
          LATE FEE POLICY
        </Paragraph>
      ) : (
        (!hideTitle || !hideEdit) && (
          <div className={styles.editHeader}>
            {!hideTitle && (
              <Paragraph size="large" weight="semi" className="mb-4 mt-0">
                Late Fees
              </Paragraph>
            )}
            {!hideEdit && (
              <FlatButton
                icon={Edit}
                className={styles.editBtn}
                onClick={onEdit}
              >
                Edit
              </FlatButton>
            )}
          </div>
        )
      )}

      <LateFeeDigestContent className={digestClassName} data={data} />
    </div>
  );
};

Digest.propTypes = {
  className: PropTypes.string,
  digestClassName: PropTypes.string,
  insideModal: PropTypes.bool,
  data: PropTypes.object,
  onEdit: PropTypes.func,
  hideEdit: PropTypes.bool,
  hideTitle: PropTypes.bool,
};

const LateFeeDigest = ({
  className,
  digestClassName,
  insideModal = false,
  data = {},
  onEdit,
  ...rest
}) => {
  return insideModal ? (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => (
        <Digest
          className={className}
          digestClassName={digestClassName}
          data={values}
          insideModal
        />
      )}
    </FormSpy>
  ) : (
    <Digest
      className={className}
      digestClassName={digestClassName}
      data={data}
      onEdit={onEdit}
      {...rest}
    />
  );
};

LateFeeDigest.propTypes = {
  className: PropTypes.string,
  digestClassName: PropTypes.string,
  insideModal: PropTypes.bool,
  data: PropTypes.shape({
    oneTimeFeeAmount: PropTypes.number,
    oneTimeFeeGracePeriod: PropTypes.number,
    oneTimeLateFeeUnit: PropTypes.oneOf(Object.values(LateFeeUnitEnum)),
    dailyLateFeeAmount: PropTypes.number,
    dailyLateFeeGracePeriod: PropTypes.number,
    lateFeeLimitUnit: PropTypes.oneOf(Object.values(LateFeeUnitEnum)),
    lateFeeLimitAmount: PropTypes.number,
    lateFeeLimitType: PropTypes.oneOf(Object.values(LateFeeLimitTypeEnum)),
  }),
  onEdit: PropTypes.func,
  hideTitle: PropTypes.bool,
  hideEdit: PropTypes.bool,
};

export default LateFeeDigest;
