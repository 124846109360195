import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { segmentTracking } from '../../services/utilities';

import { SegmentTrackingContext } from './context';

const SegmentTrackingProvider = ({ mapper, children }) => {
  const trackAction = useCallback((action, extra = {}) => {
    const map = mapper[action];
    if (!map) return;

    const { event, location, payload } = map;

    segmentTracking(event, {
      location,
      ...payload,
      ...extra,
    });
  });

  const providerValue = useMemo(() => ({ trackAction }), [trackAction]);

  return (
    <SegmentTrackingContext.Provider value={providerValue}>
      {children}
    </SegmentTrackingContext.Provider>
  );
};

SegmentTrackingProvider.propTypes = {
  mapper: PropTypes.shape({
    action: PropTypes.shape({
      event: PropTypes.string.isRequired,
      location: PropTypes.string,
      payload: PropTypes.object,
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default SegmentTrackingProvider;
