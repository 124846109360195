import React from 'react';
import ReactPlayer from 'react-player';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PlayIcon from '../../icons/PlayIcon';

import styles from './SimpleVideoPlayer.module.scss';

const SimpleVideoPlayer = ({
  containerClassName,
  iframeClassName,
  videoUrl,
  imageOverlay,
  controls = true,
  playing = true, // Will autoplay video in light mode
  height = '100%',
  width = '100%',
  icon: Icon,
  iconProps: { className: iconClassName, ...otherIconProps } = {},
}) => {
  return (
    <div className={cx(styles.container, containerClassName)}>
      <ReactPlayer
        className={cx(styles.iframe, iframeClassName)}
        controls={controls}
        url={videoUrl}
        light={imageOverlay}
        playing={playing}
        height={height} // We set width and height per their docs so video always fills container
        width={width} // We set width and height per their docs so video always fills container
        playIcon={
          Icon ? (
            <Icon className={iconClassName} {...otherIconProps} />
          ) : (
            <PlayIcon className={iconClassName} {...otherIconProps} />
          )
        }
      />
    </div>
  );
};

SimpleVideoPlayer.propTypes = {
  containerClassName: PropTypes.string,
  iframeClassName: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  imageOverlay: PropTypes.string,
  controls: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
};

export default SimpleVideoPlayer;
