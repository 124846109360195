import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import ArrowRight from '../../icons/ArrowRight';
import PlayIcon from '../../icons/PlayIcon';
import Button from '../Button';
import { H3, Paragraph } from '../Typography';
import VideoPlayerModal from '../VideoPlayerModal';

import styles from './AcademyCourseDescription.module.scss';

const AcademyCourseDescription = ({
  className,
  openAcademy,
  title,
  desc,
  videoUrl,
  videoHolderStyles,
  actionLabel,
}) => {
  const [video, setVideo] = useState(false);
  return (
    <div className={cx(styles.container, className)}>
      <div
        className={styles.videoHolder}
        style={videoHolderStyles}
        onClick={() => setVideo(true)}
      >
        <PlayIcon
          className={styles.playIcon}
          bgColor={colors.white}
          color={colors.sureBlue}
          width={70}
          height={70}
        />
      </div>
      <div className={styles.description}>
        <div>
          <H3 className={styles.title}>{title}</H3>
          <Paragraph size="large">{desc}</Paragraph>
        </div>
        <Button
          variant="ghost"
          onClick={openAcademy}
          className={styles.button}
          icon={ArrowRight}
          iconAfter
        >
          {actionLabel}
        </Button>
      </div>

      <VideoPlayerModal
        mobileFullScreen
        className={styles.videoPlayerModal}
        open={video}
        url={videoUrl}
        onClose={() => setVideo(false)}
      />
    </div>
  );
};

AcademyCourseDescription.propTypes = {
  className: PropTypes.string,
  openAcademy: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  videoHolderStyles: PropTypes.object,
  actionLabel: PropTypes.string,
};

export default AcademyCourseDescription;
