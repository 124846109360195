import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import { useErrorToast, useInfoToast } from '../../../../../components/Toast';
import { Tooltip } from '../../../../../components/Tooltip';
import createLulaWorkOrderMutation from '../../../../../graphql/mutations/maintenance_requests/createLulaWorkOrder.graphql';
import Lula from '../../../../../icons/Lula';
import { useConfig } from '../../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../../services/utilities';

import styles from './SendToLulaCard.module.scss';

const SendToLulaCard = ({
  className,
  isAddressEligible,
  maintenanceRequestId,
  disabled,
  maintenancePlusSettings,
}) => {
  const showInfoMessage = useInfoToast();
  const showErrorMessage = useErrorToast();
  const [createLulaWorkOrder, { loading: sendingWorkOrder }] = useMutation(
    createLulaWorkOrderMutation,
  );
  const history = useHistory();
  const { PRIVATE_BASE_PATH } = useConfig();

  async function handleSendToLula() {
    if (!maintenancePlusSettings) {
      return history.push(`${PRIVATE_BASE_PATH}maintenance/onboarding`);
    }

    try {
      segmentTracking('send_to_lula', {
        location: 'Maintenance Request Page',
      });

      await createLulaWorkOrder({
        variables: {
          maintenanceRequestId,
        },
      });
      showInfoMessage('Maintenance request sent to Lula.');
    } catch (e) {
      showErrorMessage('Something went wrong. Please try again later.', e);
    }
  }

  return (
    <>
      <div
        className={cx(
          styles.container,
          className,
          isAddressEligible && !disabled ? styles.enabled : styles.disabled,
        )}
        data-tooltip-id="send-to-lula"
      >
        <div className={styles.texts}>
          <div className={styles.title}>
            Send to <Lula />
          </div>
          <div className={styles.description}>
            Send to Lula for troubleshooting, and if needed, coordinating with a
            vetted Pro.
          </div>
        </div>
        <Button
          className={styles.cta}
          disabled={(!isAddressEligible && !sendingWorkOrder) || disabled}
          onClick={handleSendToLula}
          loading={sendingWorkOrder}
        >
          Send To Lula
        </Button>
      </div>
      {(!isAddressEligible || disabled) && (
        <Tooltip id="send-to-lula">
          {disabled
            ? 'Sending to Lula has been deactivated until your Maintenance invoices have been paid in full. Please contact support@turbotenant.com if you have further questions'
            : 'This address is ineligible to send maintenance requests to Lula at this time.'}
        </Tooltip>
      )}
    </>
  );
};

SendToLulaCard.propTypes = {
  className: PropTypes.string,
  isAddressEligible: PropTypes.bool,
  maintenanceRequestId: PropTypes.string,
  disabled: PropTypes.bool,
  maintenancePlusSettings: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
};

export default SendToLulaCard;
