import { CometChat } from '@cometchat-pro/chat';
import jwtDecode from 'jwt-decode';
import get from 'lodash.get';
import queryString from 'query-string';

import browserHistory from '../../browserHistory';
import basicUserProfile from '../../graphql/basicUserProfile.graphql';
import { getConfig } from '../../services/configService';
import * as localStorageService from '../../services/localStorageService';
import { setCurrentUrlToRedirectAfterLogin } from '../../services/utilities';
import { TTfetch } from '../TTfetch';

let client;

export function setApolloClient(_client) {
  client = _client;
}

export function getApolloClient() {
  return client;
}

export function getCurrentToken() {
  const token = localStorageService.getItem('auth_token');
  try {
    if (token && window?.atatus?.setUser) {
      const decoded = jwtDecode(token) || {};
      const id = decoded.currentId || decoded.id;
      const email = decoded.currentEmail || decoded.email;
      const role = decoded.currentRole || decoded.role;
      window.atatus.setUser(`${role}:${id}`, email, email);
    }
  } catch {
    console.warn('Atatus failed to parse');
  }
  return token;
}

export function getDecodedCurrentToken() {
  try {
    const token = localStorageService.getItem('auth_token');
    return jwtDecode(token) || {};
  } catch {
    console.warn('Failed to decode token');
  }
}

export function isLoggedIn() {
  return !!getCurrentToken();
}

export function login(token) {
  token && localStorageService.setItem('auth_token', token);
}

export function tryLoginFromLocalStorage() {
  if (isLoggedIn()) {
    login(getCurrentToken());
  }
}

export function getAlternateToken() {
  const qs = queryString.parse(browserHistory.location.search);
  return (
    qs.token ||
    qs.lead_token ||
    qs.reference_token ||
    qs.upload_documents_token ||
    localStorageService.getItem('alternate_token')
  );
}

export function adminLogin(token) {
  localStorageService.setItem('adminLogin', 'true');
  token && localStorageService.setItem('auth_token', token);
}

export function isAdminLogin() {
  return localStorageService.isAdminLogin();
}

export function silentLogin(token) {
  token && localStorageService.setItem('auth_token', token);
}

export function silentLogout() {
  localStorageService.setItem('auth_token', '');
}

export function alternateLogin(token) {
  token && localStorageService.setItem('alternate_token', token);
}

function getUserInfo() {
  return client
    .query({ query: basicUserProfile, fetchPolicy: 'network-only' })
    .then((response) => {
      const user = get(response, 'data.userData.basicProfile.data', null);
      if (!user) {
        window.atatus &&
          window.atatus.notify({
            message: 'Empty response from getBasicUserInfo',
            token: getCurrentToken(),
          });
      }
      return { user };
    });
}

export function lightLogout(redirectBackAfterLogin) {
  const loginRoute = '/auth/login';
  if (browserHistory.location.pathname !== loginRoute) {
    if (
      redirectBackAfterLogin &&
      browserHistory.location.pathname !== loginRoute
    ) {
      setCurrentUrlToRedirectAfterLogin();
    }
    browserHistory.replace(loginRoute);
  }
}

export function logout(redirectBackAfterLogin) {
  lightLogout(redirectBackAfterLogin);
  localStorageService.setItem('auth_token', '');
  localStorageService.setItem('active_experiments', '');
  localStorageService.setItem('owner_active_experiments', '');
  sessionStorage.removeItem('alreadyRedirected'); // redirected once to onboarding flow
  setTimeout(() => {
    client.clearStore(); // We use this instead of client.resetStore() because we don't want to refetch queries (as we might end up in loops when those queries error out w/ Unauthorized)
  }, 300);
}

export function updateUserData() {
  return getUserInfo()
    .then((info) => {
      if (!info || !info.user || Object.keys(info.user).length === 0) {
        CometChat.logout().then(
          () => {
            console.log('Messaging Logout completed successfully');
          },
          (error) => {
            console.log('Messaging Logout failed with exception:', { error });
          },
        );
        return logout();
      }
      return info;
    })
    .catch((error) => {
      console.log(error);
      if (error.message === 'Network error: Failed to fetch') {
        return;
      }
      CometChat.logout().then(
        () => {
          console.log('Messaging Logout completed successfully');
        },
        (error) => {
          console.log('Messaging Logout failed with exception:', { error });
        },
      );
      return logout();
    });
}

export function requestLogin({
  email,
  password,
  accountType = 0,
  service = '',
  token = '',
  deviceSignature = '',
  deviceLoginToken = '',
  captchaToken = '',
  ssoService,
  redirectPath,
}) {
  const { API_URL } = getConfig();
  // @TODO extract if we start making more requests
  return TTfetch(`${API_URL}/public/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      accountType,
      service,
      token,
      deviceSignature,
      deviceLoginToken,
      captchaToken,
      ssoService,
      redirectPath,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        localStorageService.setItem('login_failed', null);

        if (res.token) {
          // Token could be absent if we're talking about an ssoService
          login(res.token);
        }
        return res;
      }
      localStorageService.setItem('login_failed', Date.now());
      return Promise.reject(res);
    })
    .catch((error) => {
      if (error.code || error.error) throw error;
      // this must be the server not responding
      throw new Error('Incorrect Login');
    });
}

export function decodeToken(token) {
  if (!token) return {};
  try {
    return jwtDecode(token);
  } catch (e) {
    console.log('Error while decoding token', e);
    return {};
  }
}

export default function isOwnerLoggedIn() {
  const decodedToken = decodeToken(getCurrentToken());
  return (
    decodedToken.role === 'OWNER' ||
    decodedToken.currentRole === 'OWNER' ||
    decodedToken.role === 'USER'
  );
}

export function isUserProfileToken(token) {
  // TODO, having a USER token doesn't guarantee it's a "userprofileToken" token,
  // but not having a landlord account is considered an edge case for now
  return ['OWNER', 'RENTER', 'ADMIN', 'USER'].includes(decodeToken(token).role);
}
