import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import Card from '../../../../../components/Card';
import Tag from '../../../../../components/Tag';

import styles from './PaymentMethodOption.module.scss';

const PaymentMethodOption = ({
  title,
  description,
  actionLabel,
  icon: Icon,
  iconProps,
  id,
  onClickAction,
  processingTime,
  preferred,
  primary,
  className,
}) => {
  return (
    <Card className={cx(styles.container, className)}>
      {preferred && (
        <Tag small className={styles.tag}>
          Preferred
        </Tag>
      )}
      <div className={styles.iconWrap}>
        <Icon className={styles.icon} {...iconProps} />
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.processingTime}>{processingTime}</p>
      <div className={styles.description}>{description}</div>
      <Button
        small
        secondary={!primary}
        onClick={onClickAction}
        className={styles.button}
        id={id}
      >
        {actionLabel}
      </Button>
    </Card>
  );
};

PaymentMethodOption.propTypes = {
  title: PropTypes.string.isRequired,
  processingTime: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClickAction: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconProps: PropTypes.object,
  preferred: PropTypes.bool,
  primary: PropTypes.bool,
};

export default PaymentMethodOption;
