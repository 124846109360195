export const quote =
  'The purchase of a subscription was the best thing I could ever have done. Don’t waste another minute – purchase it and you will be so glad you did.';

export const PLANS = {
  PRO: 'pro',
  PREMIUM: 'premium',
};

export const signupTestValue = 'gt_p_signup_test';

export const PlansEnum = {
  PRO: 'pro',
  PREMIUM: 'premium',
  PREMIUM_PLUS: 'premium-plus',
};

export const segmentLocation = 'Purchase Onboarding Flow';

export const parsePlan = (plan) => {
  if (!plan) return '';
  return plan.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};
