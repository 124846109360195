import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Bank = ({ color, ...rest }) => (
  <svg viewBox="0 0 48 48" {...rest}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M8.5 24h5v12h-5V24Zm13 0h5v12h-5V24Zm13 0h5v12h-5V24Z"
        fill="#fff"
      />
      <path
        d="M15.292 22.333c.438 0 .792.373.792.834 0 .46-.354.833-.792.833H14.5v11.666h.792c.404 0 .737.319.786.73l.006.104c0 .46-.354.833-.792.833H7.376c-.437 0-.791-.373-.791-.833 0-.46.354-.833.791-.833l.791-.001V24h-.79c-.404 0-.737-.318-.786-.729l-.006-.104c0-.46.354-.834.791-.834h7.916Zm-2.375 13.333V24H9.751v11.666h3.166ZM5.793 44h36.414c.437 0 .791-.373.791-.833 0-.46-.354-.834-.791-.834H5.793c-.437 0-.791.373-.791.834 0 .46.354.833.791.833Zm0-3.333h36.414c.437 0 .791-.373.791-.834 0-.46-.354-.833-.791-.833H5.793c-.437 0-.791.373-.791.833 0 .46.354.834.791.834Zm22.165-18.334c.437 0 .792.373.792.834 0 .46-.355.833-.792.833h-.792v11.666h.792c.404 0 .737.319.785.73l.007.104c0 .46-.355.833-.792.833h-7.916c-.437 0-.792-.373-.792-.833 0-.46.355-.833.792-.833l.791-.001V24h-.791c-.404 0-.737-.318-.785-.729l-.007-.104c0-.46.355-.834.792-.834h7.916ZM25.583 24h-3.167v11.666h3.167V24Zm15.04-1.667c.438 0 .792.373.792.834 0 .46-.354.833-.791.833h-.792v11.666h.792c.403 0 .736.319.785.73l.006.104c0 .46-.354.833-.791.833h-7.916c-.438 0-.792-.373-.792-.833 0-.46.354-.833.792-.833l.791-.001V24h-.791c-.404 0-.737-.318-.786-.729l-.006-.104c0-.46.354-.834.792-.834h7.916ZM38.249 24h-3.166v11.666h3.166V24ZM5.793 20.667c-.753 0-1.08-1.003-.487-1.49l18.256-15a.76.76 0 0 1 .976 0l18.158 15c.591.49.263 1.49-.49 1.49H5.794ZM8.093 19h31.823L24.048 5.892 8.094 19Z"
        fill={color}
      />
    </g>
  </svg>
);

Bank.defaultProps = {
  width: 48,
  height: 48,
  color: colors.text,
};

Bank.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Bank);
