import React from 'react';
import PropTypes from 'prop-types';

import InlineAlert from '../../../../../../../components/InlineAlert';

const LateFeeInfo = ({ className, fullStateName }) => (
  <InlineAlert className={className} title={fullStateName}>
    <ul>
      <li>There is a 2 day grace period before late fee may be charged.</li>
      <li>
        For buildings with 4 or less units the late fees must not exceed 12% of
        the late amount due.
      </li>
      <li>
        For buildings with 5 or more units the late fees must not exceed 10% of
        the late amount due.
      </li>
    </ul>
  </InlineAlert>
);

LateFeeInfo.propTypes = {
  className: PropTypes.string,
  fullStateName: PropTypes.string,
};

export default LateFeeInfo;
