import React, { memo } from 'react';
import PropTypes from 'prop-types';

const QuickReplyIcon = ({ width, height, color, ...props }) => (
  <svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon / Streamline / Quick Message">
      <g id="Shape">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.2168 7.11694C2.2168 6.73034 2.5302 6.41694 2.9168 6.41694H6.30024C6.68684 6.41694 7.00024 6.73034 7.00024 7.11694C7.00024 7.50354 6.68684 7.81694 6.30024 7.81694H2.9168C2.5302 7.81694 2.2168 7.50354 2.2168 7.11694Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.2168 15.2836C2.2168 14.897 2.5302 14.5836 2.9168 14.5836H3.96691C4.35351 14.5836 4.66691 14.897 4.66691 15.2836C4.66691 15.6702 4.35351 15.9836 3.96691 15.9836H2.9168C2.5302 15.9836 2.2168 15.6702 2.2168 15.2836Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.2168 11.3169C2.2168 10.9303 2.5302 10.6169 2.9168 10.6169H5.13358C5.52018 10.6169 5.83358 10.9303 5.83358 11.3169C5.83358 11.7035 5.52018 12.0169 5.13358 12.0169H2.9168C2.5302 12.0169 2.2168 11.7035 2.2168 11.3169Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.6127 6.54232C25.4798 6.38851 25.2865 6.30011 25.0832 6.30011H9.396C9.0485 6.30011 8.75361 6.55503 8.70336 6.89887L6.65749 17.5157C6.62809 17.7169 6.68761 17.9209 6.82057 18.0747C6.95354 18.2285 7.14681 18.3169 7.35013 18.3169H10.1025L9.75901 21.029C9.72402 21.3052 9.85607 21.5759 10.0953 21.7184C10.3345 21.8608 10.6354 21.848 10.8616 21.6856L15.5553 18.3169H23.0373C23.3848 18.3169 23.6797 18.062 23.7299 17.7182L25.7758 7.10134C25.8052 6.90016 25.7457 6.69613 25.6127 6.54232ZM24.2734 7.70011L22.4322 16.9169H15.3301C15.1837 16.9169 15.0409 16.9629 14.922 17.0483L11.3497 19.6121L11.5912 17.7049C11.6165 17.5055 11.5548 17.3049 11.422 17.1541C11.2891 17.0033 11.0978 16.9169 10.8968 16.9169H8.15988L10.0011 7.70011H24.2734Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

QuickReplyIcon.defaultProps = {
  width: 28,
  height: 28,
  color: '#687A88',
};

QuickReplyIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(QuickReplyIcon);
