import React from 'react';
import PropTypes from 'prop-types';

const HourGlass = ({ width, height, ...rest }) => (
  <svg viewBox="0 0 65 64" fill="none" width={width} height={height} {...rest}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6109 27.5557H41.3887C38.4258 30.5186 35.4628 32.0001 32.4998 32.0001C29.5369 32.0001 26.5739 30.5186 23.6109 27.5557ZM19.1665 49.7779H25.8332L32.4998 43.1112L39.1665 49.7779H45.8332V56.4445H19.1665V49.7779Z"
        fill="#7FE3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.3889 5.33325C52.0025 5.33325 52.5 5.83071 52.5 6.44436C52.5 6.98983 52.1069 7.44349 51.5886 7.53757L51.3889 7.55547L48.0547 7.55459L48.0556 17.5555C48.0556 24.1026 44.0081 29.7062 38.2803 32.0011C44.0077 34.2942 48.0556 39.8983 48.0556 46.4444L48.0547 56.4439L51.3889 56.4444C52.0025 56.4444 52.5 56.9418 52.5 57.5555C52.5 58.1009 52.1069 58.5546 51.5886 58.6487L51.3889 58.6666H13.6111C12.9975 58.6666 12.5 58.1691 12.5 57.5555C12.5 57.01 12.8931 56.5563 13.4114 56.4623L13.6111 56.4444L16.944 56.4439L16.9444 46.4444C16.9444 39.8966 20.9882 34.294 26.7162 31.9991C20.9878 29.7064 16.9444 24.1044 16.9444 17.5555L16.944 7.55459L13.6111 7.55547C12.9975 7.55547 12.5 7.05801 12.5 6.44436C12.5 5.8989 12.8931 5.44523 13.4114 5.35115L13.6111 5.33325H51.3889ZM32.5 43.571L26.619 49.4523C26.4523 49.619 26.2381 49.7272 26.0082 49.7639L25.8333 49.7777L19.1667 49.7773V56.4444H45.8333V49.7773L39.1667 49.7777C38.9309 49.7777 38.7029 49.7028 38.5144 49.5661L38.381 49.4523L32.5 43.571ZM32.9996 33.1202L32.5 33.111C25.1338 33.111 19.1667 39.0801 19.1667 46.4444V47.5546L25.3756 47.5555L31.7143 41.2142C32.1 40.8285 32.6988 40.7857 33.1318 41.0857L33.2857 41.2142L39.6267 47.5555L45.8333 47.5546V46.4444C45.8333 39.2505 40.1289 33.3832 32.9996 33.1202ZM41.3185 27.555L23.6783 27.5553C25.9123 29.5274 28.8128 30.7622 31.9999 30.8796L32.5 30.8888C35.8804 30.8888 38.9678 29.6297 41.3185 27.555ZM45.8333 7.55547H19.1667V17.5555C19.1667 20.459 20.0938 23.1453 21.6684 25.3345L43.3292 25.3343C44.905 23.1448 45.8333 20.4583 45.8333 17.5555V7.55547Z"
        fill="#042238"
      />
    </g>
  </svg>
);

HourGlass.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default HourGlass;
