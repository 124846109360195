import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useStripe } from '@stripe/react-stripe-js';
import cx from 'classnames';
import get from 'lodash.get';
import isNull from 'lodash.isnull';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';

import AddBankManuallyForm from '../../../../../components/AddBankManuallyForm';
import BackButton from '../../../../../components/BackButton';
import BankAccountAlreadyAddedModal from '../../../../../components/BankAccountAlreadyAddedModal/BankAccountAlreadyAddedModal';
import DoneCard from '../../../../../components/DoneCard';
import LoadingScreen from '../../../../../components/LoadingScreen';
import { useErrorToast, useInfoToast } from '../../../../../components/Toast';
import { useUserProfile } from '../../../../../core/TTgraphql';
import getEntities from '../../../../../graphql/queries/entities/entities.graphql';
import handleGraphQLErrors from '../../../../../helpers/handleGraphQLErrors';
import { useRenderOutside } from '../../../../../helpers/render-outside';
import ThumbsUp from '../../../../../icons/ThumbsUp';
import { useConfig } from '../../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../../services/utilities/segment';
import StripeElementsWrapper from '../../../../common/stripe/StripeElementsWrapper';
import { useAddBankAccount, useReplaceBankAccount } from '../../../usePayments';

import styles from './AddBankInfoManually.module.scss';

const AddBankInfoManually = ({
  className,
  onCancelClick,
  history,
  location,
  hideBack,
  redirectTo,
  skipSuccessMessage,
  onSuccess,
  hideCancel,
  match,
}) => {
  const { bankAccountIDToReplace } = match?.params || {};

  const goToLink = get(location, 'state.redirectTo', '') || redirectTo;
  const entityId = get(location, 'state.entityId', null);

  const [getEntitiesQuery] = useLazyQuery(getEntities);

  const { PRIVATE_BASE_PATH } = useConfig();

  const stripe = useStripe();
  const showErrorMessage = useErrorToast();
  const showInfoToast = useInfoToast();
  const renderOutside = useRenderOutside();

  const [addBankAccount, { loading: loadingAddBankAccount }] =
    useAddBankAccount();
  const [replaceBankAccount, { loading: loadingReplaceBankAccount }] =
    useReplaceBankAccount();

  const [showSuccess, setShowSuccess] = useState(false);
  const [changeBankAccount, setChangeBankAccount] = useState(null);

  const { user, loading: loadingUser } = useUserProfile({ polling: false });

  const isPremiumUser = user?.premium_subscription_subscribed;

  useEffect(() => {
    const bankLast4 = get(user, 'payments_data.bank_account_last4', null);
    // premium users won't never change the bankaccount, they add a new one
    if (user && isNull(changeBankAccount) && !isPremiumUser) {
      setChangeBankAccount(!!bankLast4);
    }
  }, [user]);

  const handleSubmit = async (form) => {
    segmentTracking('add_bank_account clicked', {
      location: 'Add Bank Manually',
    });
    if (!stripe) return;
    try {
      const { token, error } = await stripe.createToken('bank_account', {
        country: 'US', // default
        currency: 'usd', // default
        ...omit(form, 'account_number_confirm'),
      });

      if (error) {
        if (error.param === 'bank_account[routing_number]') {
          return { routing_number: 'Invalid routing number' };
        }
        if (error.code === 'account_number_invalid') {
          return { account_number: 'Invalid account number' };
        }
        throw new Error('Something went wrong');
      }

      if (bankAccountIDToReplace) {
        await replaceBankAccount({
          variables: {
            bankToken: token.id,
            nickname: form?.nickname,
            entityId,
            existingBankAccountId: decodeURIComponent(bankAccountIDToReplace),
          },
        });
      } else {
        await addBankAccount({
          variables: {
            bankToken: token.id,
            nickname: form?.nickname,
            entityId,
          },
        });
      }

      if (changeBankAccount || isPremiumUser) {
        showInfoToast('Bank account added!');

        if (onSuccess) {
          return onSuccess();
        }

        return history.push(goToLink || `${PRIVATE_BASE_PATH}settings/account`);
      }

      if (skipSuccessMessage) {
        if (onSuccess) {
          return onSuccess();
        }

        return history.push(goToLink || `${PRIVATE_BASE_PATH}settings/account`);
      }
      setShowSuccess(true);
    } catch (e) {
      handleGraphQLErrors(e, {
        804: async () => {
          getEntitiesQuery();
          renderOutside((done) => (
            <BankAccountAlreadyAddedModal onClose={done} />
          ));
        },
      }) || showErrorMessage('An error has occurred');
    }
  };

  const handleCancel = () => {
    if (onCancelClick) {
      return onCancelClick();
    }

    return history.push(goToLink);
  };

  if (showSuccess) {
    return (
      <DoneCard
        title="All Submitted!"
        description="You will be notified when our team has finished verifying your identity. It typically takes less than 24 hours. Once that is done you’ll be all set to start collecting rent online."
        icon={ThumbsUp}
        to={goToLink || `${PRIVATE_BASE_PATH}payments`}
      />
    );
  }

  return (
    <LoadingScreen
      loading={
        loadingUser || loadingAddBankAccount || loadingReplaceBankAccount
      }
      className={cx(styles.page, className)}
    >
      {!hideBack && <BackButton className={styles.back} />}
      <div className={styles.content}>
        <AddBankManuallyForm
          onSubmit={handleSubmit}
          onCancelClick={handleCancel}
          isPremiumUser={isPremiumUser}
          user={user}
          showCancelButton={!hideCancel}
        />
      </div>
    </LoadingScreen>
  );
};

AddBankInfoManually.propTypes = {
  onCancelClick: PropTypes.func,
  redirectTo: PropTypes.string,
  className: PropTypes.string,
  hideBack: PropTypes.bool,
  skipSuccessMessage: PropTypes.bool,
  onSuccess: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  hideCancel: PropTypes.bool,
  match: PropTypes.object,
};

const AddBankInfoManuallyComponent = (props) => (
  <StripeElementsWrapper>
    <AddBankInfoManually {...props} />
  </StripeElementsWrapper>
);

export default withRouter(AddBankInfoManuallyComponent);
