import React from 'react';

import FlatButton from '../../../../components/FlatButton';
import { linkTo, routes } from '../../../../helpers/routes';
import AddPaymentMethodsWrapper from '../../../../pages/renters/payments/payment-methods/AddPaymentMethods/AddPaymentMethodsWrapper';
import SegmentTrackingProvider from '../../../../providers/SegmentTrackingProvider';
import { segmentTracking } from '../../../../services/utilities';
import OnboardingProgress from '../../components/OnboardingProgress';

import styles from './PaymentMethod.module.scss';

const mapper = {
  addDebit: {
    event: 'payment_method submit',
    location: 'Tenant Onboarding Payment Method Page - Add Debit Card',
  },
  addCredit: {
    event: 'payment_method submit',
    location: 'Tenant Onboarding Payment Method Page - Add Credit Card',
  },
  addBank: {
    event: 'payment_method submit',
    location: 'Tenant Onboarding Payment Method Page - Add Bank',
  },
  addManual: {
    event: 'payment_method submit',
    location: 'Tenant Onboarding Payment Method Page - Add Bank Manually',
  },
  goBack: {
    event: 'back clicked',
  },
  saveCard: {
    event: 'save_card clicked',
  },
  pageView: {
    event: 'page viewed',
    location: 'Tenant Onboarding Payment Method Page',
  },
  useExistingCard: {
    event: 'payment_method submit',
    location: 'Tenant Onboarding Payment Method Page - Use Existing PM',
  },
};

const PaymentMethod = () => (
  <>
    <OnboardingProgress step="Rent Payments" progress={10} />

    <SegmentTrackingProvider mapper={mapper}>
      <AddPaymentMethodsWrapper
        hideBackButton
        redirectToBeforeSuccess={linkTo(
          routes.tenantOnboardingStepPaymentMethodSuccess,
        )}
        redirectToBeforeVerify={linkTo(
          routes.tenantOnboardingStepPaymentMethodVerify,
        )}
        wrapPaymentMethodsClassName={styles.wrapPaymentMethodsClassName}
        wrapStripeElementClassName={styles.wrapStripeElementClassName}
        loadingWrapperClassName={styles.loadingWrapperClassName}
        showSuccessToast={false}
        showCancelButton
        skipIdentityVerification
        showExistingRentPaymentsPaymentMethod
        linkForExistingRentPaymentsPaymentMethod={linkTo(
          routes.tenantOnboardingStepPaymentMethodSuccess,
        )}
      />
    </SegmentTrackingProvider>
    <FlatButton
      className={styles.skipButton}
      to={linkTo(routes.tenantOnboardingStepRentersInsurance)}
      onClick={() => {
        segmentTracking('payment_method submit', {
          location: 'Tenant Onboarding Payment Method Page - Skip',
        });
      }}
    >
      Skip for now
    </FlatButton>
  </>
);
export default PaymentMethod;
